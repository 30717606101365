import React, {useEffect, useState} from "react";
import {Button, Modal, Spinner} from "react-bootstrap-v5";
import * as API from "../../api/API";
import {mapUserPayment} from "../../api/ApiRequestMappers";
import {globalStore} from "../../utils/redux/ReduxUtils";
import {useToasts} from "react-toast-notifications";
import {ApiPaymentsResponseRow, ApiPaymentSuggestionResponse} from "../../api/ResponseModels";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import PaymentTooltip from "../inner/payments/PaymentTooltip";
import Icon from "@iconify/react";
import trash24 from "@iconify/icons-octicon/trash-24";

interface Props {
    userId: number
    visible: boolean
    toggleVisibility: () => any
    onPaymentCreated: () => any
    initialPayment?: ApiPaymentsResponseRow
}

export default function AddPaymentModal(props: Props) {
    const {addToast} = useToasts()

    const [paymentSum, setPaymentSum] = useState(props.initialPayment?.value || 0)
    const [comment, setComment] = useState(props.initialPayment?.comment)

    const [apiSuggestedPayment, setApiSuggestedPayment] = useState(undefined as ApiPaymentSuggestionResponse | undefined)
    useEffect(() => {
        const getSuggAndUpdateStateAsync = async () => {
            const sugg = await API.getPaymentSuggestionForUser(props.userId, globalStore.getState().authToken)
            setApiSuggestedPayment(sugg)
            if (sugg.suggestedPaymentSum > 0) setPaymentSum(sugg.suggestedPaymentSum)
        }
        if (!props.initialPayment) getSuggAndUpdateStateAsync()
    }, [props.userId])

    const makePayment = () => {
        const apiCreatePayment = async () => {
            const authToken = globalStore.getState().authToken
            const serialized = mapUserPayment(props.initialPayment?.id, props.userId, paymentSum, Boolean(comment) ? comment : undefined)
            await API.createPayment(serialized, authToken)
            addToast('Выплата осуществлена!', {appearance: "success"})
            props.onPaymentCreated()
            props.toggleVisibility()
        }
        if (paymentSum <= 0) {
            props.onPaymentCreated()
            props.toggleVisibility()
        } else {
            apiCreatePayment().catch(err => {
                console.log(err)
                addToast('Не удалось осуществить выплату!', {appearance: 'error'})
            })
        }

    }

    const onPaymentDeletePressed = async () => {
        if (props.initialPayment?.id) {
            try {
                if (window.confirm("Вы уверены, удалить выплату?")) {
                    await API.deletePayment(props.initialPayment?.id, globalStore.getState().authToken)
                    addToast('Выплата успешно удалена', {appearance: "success"})
                    props.onPaymentCreated()
                    props.toggleVisibility()
                }
            } catch (e) {
                console.log(e)
                addToast('Не удалось удалить выплату!', {appearance: "error"})
            }
        }
    }

    return (
        <Modal show={props.visible} onHide={props.toggleVisibility} centered>
            <Modal.Header>
                <Modal.Title>
                    <h5 className="modal-title text-green">{
                        props.initialPayment?.id ? "Обновить выплату?" : 'Добавить выплату?'
                    }</h5>
                </Modal.Title>
                <div className='d-flex flex-row align-items-center'>
                    <button type="button" className="btn-close" onClick={props.toggleVisibility}/>
                </div>
            </Modal.Header>
            <Modal.Body className='small'>
                {
                    Boolean(props.initialPayment) &&
                    <div style={{fontSize: '0.85rem'}}
                         onClick={onPaymentDeletePressed}
                         className='text-danger cursor-pointer col-12 d-flex flex-row align-items-center justify-content-end'>
                        <Icon icon={trash24} color='red' className='me-2'/>
                        Удалить выплату
                    </div>
                }
                {
                    !props.initialPayment &&
                    <div className='mb-1'>
                        Предполагаемая выплата (с момента прошлой):
                        <span className='ms-1'>
                            {
                                apiSuggestedPayment &&
                                <>
                                    <span className='ms-1 fw-bold'>{apiSuggestedPayment.suggestedPaymentSum}</span>
                                    <FontAwesomeIcon
                                        style={{cursor: 'pointer'}}
                                        data-tip='payment-tooltip'
                                        className='ms-1' color='#206bc4' icon={faQuestionCircle}/>
                                </>
                            }
                            {
                                !apiSuggestedPayment &&
                                <Spinner className='ms-1' size='sm' animation="border" variant='primary' role="status"/>
                            }

                        </span>
                    </div>
                }

                Сумма выплаты
                <div className="input-group my-1">
                    <span className="input-group-text">₽</span>
                    <input type="num" className="form-control"
                           value={paymentSum}
                           onChange={e => setPaymentSum(Number.parseInt(e.target.value, 10))}/>
                </div>
                Комментарий (необязательно)
                <textarea className="form-control my-1"
                          style={{minHeight: '3em', maxHeight: '30em'}} placeholder="—"
                          value={comment || ''}
                          onChange={e => setComment(e.currentTarget.value)}
                          maxLength={1000} defaultValue={""}/>
            </Modal.Body>
            <Modal.Footer>
                <div className="col-12 col-sm">
                    <button onClick={props.toggleVisibility} type="button" className="w-100 btn btn-secondary"
                            data-bs-dismiss="modal">Закрыть
                    </button>
                </div>
                <div className="col-12 col-sm">
                    <button onClick={makePayment} type="button" className="w-100 mx-0 btn btn-green">
                        {
                            props.initialPayment?.id ? 'Обновить' : 'Создать выплату'
                        }
                    </button>
                </div>
            </Modal.Footer>
            <ReactTooltip delayShow={100} delayUpdate={1000} className='payment-tooltip-opaque'
                          getContent={e => <PaymentTooltip userId={props.userId}/>}/>
        </Modal>
    );

}
