import {UserRole} from "../models/enums";


export function hasPhoneCallManagementPermission(r: UserRole) {
    return [UserRole.CHIEF, UserRole.CALLS_CHIEF].includes(r)
}
export function hasPaymentsEditPermission(r: UserRole) {
    return r === UserRole.CHIEF
}
export function hasCampaignSettingsEditPermission(r: UserRole) {
    return (r === UserRole.CHIEF)
}

export function hasMakeCallPermission(r: UserRole) {
    return [UserRole.CHIEF, UserRole.CALLS_CHIEF, UserRole.CALLS_EMPLOYEE].includes(r)
}

export function hasFieldAccessPermission(r: UserRole){
    return [UserRole.CHIEF, UserRole.FIELD].includes(r)
}
export function hasUsersAccessPermission(r: UserRole){
    return [UserRole.CHIEF, UserRole.FIELD, UserRole.CALLS_CHIEF, UserRole.HR].includes(r)
}

export function hasAnalyticsPermission(r: UserRole){
    return [UserRole.CHIEF, UserRole.FIELD, UserRole.ANALYST, UserRole.CALLS_CHIEF].includes(r)
}

export function hasContactEditPermission(r: UserRole){
    return [UserRole.CHIEF, UserRole.FIELD, UserRole.CALLS_CHIEF].includes(r)
}
