import React from "react";
import {Button, Modal, Spinner} from "react-bootstrap-v5";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import NumericInput from "react-numeric-input";
import Icon from "@iconify/react";
import archive24 from "@iconify/icons-octicon/archive-24";
import {DropzoneArea} from "material-ui-dropzone";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {ApiHqContactsImportResponse, ApiHqPhoneCallsImportResponse} from "../../../api/ResponseModels";
import {COLORS} from "../../../utils/Consts";

interface State {
    file?: File
    isLoading: boolean
    error: string
}

interface Props {
    onCancel: () => void
    onSuccess: (r: ApiHqPhoneCallsImportResponse, notFullyImported: boolean) => void
    toastManager: ToastConsumerContext
}

class ContactCSVImportModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
            error: ''
        }
    }

    onUploadPressed = () => {
        if (!this.state.isLoading) {
            this.setState({isLoading: true, error: ''}, () => {
                const r = new FileReader();
                r.onload = async () => {
                    const fileReadResult = r.result
                    if (fileReadResult && typeof fileReadResult === 'string') {
                        const uploadFileF = async () => {
                            const response = await API.importPhoneCallsCSV(
                                globalStore.getState().authToken,
                                fileReadResult,
                            )
                            if (response.error) {
                                throw new Error(response.error)
                            } else {
                                if (response.skippedRowNumbers.length > 0) {
                                    const warnMessage = `Импорт осуществлен не полностью.\nПропущены строки (контакт с этим ID не найден): ${response.skippedRowNumbers.join(',')}`
                                    this.setState({error: warnMessage})
                                    this.props.onSuccess(response, true)
                                } else {
                                    this.props.onSuccess(response, false)
                                }
                            }
                        }
                        uploadFileF().catch(err => {
                            console.log(err)
                            this.props.toastManager.add(`Импорт завершён с ошибкой. ${err}`, {
                                appearance: 'error',
                                autoDismiss: false
                            });
                        }).finally(() => this.setState({isLoading: false}))

                    } else this.props.toastManager.add('Не удалось прочитать файл', {
                        appearance: 'error',
                    });
                };
                if (this.state.file) {
                    r.readAsText(this.state.file, "CP1251");
                }
            })
        }
    }

    render() {
        return (
            <Modal show={true} onHide={this.props.onCancel} centered>
                <Modal.Body>
                    <main id="call-script-editor" className="container-fluid">
                        <div className="max-w-md shadow-lg mb-4">
                            <div
                                className="bg-dark text-white text-center fw-500 py-3 d-flex flex-row justify-content-between align-items-center">
                                <span className='ms-5'>Импорт данных</span>
                            </div>
                            <div className="bg-white pt-2 pb-4 px-4" style={{wordWrap: 'break-word'}}>
                                {
                                    Boolean(this.state.error) && <span
                                        className='col-12 border-top font-monospace mt-1 fw-bold text-danger text-center'>{this.state.error}</span>
                                }
                                <div className='row'>
                                    <label htmlFor="materialTitle"
                                           className="col-form-label col-12 form-label-required">Выберите файл для
                                        импортирования</label>
                                    <div className="col-12">
                                        <DropzoneArea
                                            dropzoneText={'Перенесите файл в это окно или нажмите, чтобы выбрать'}
                                            filesLimit={1}
                                            getFileAddedMessage={fileName => `Файл ${fileName} выбран`}
                                            getFileRemovedMessage={fileName => `Файл ${fileName} удалён`}
                                            acceptedFiles={[".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}
                                            onChange={files => {
                                                if (files.length > 0) this.setState({file: files[0]})
                                                else this.setState({file: undefined})
                                            }}
                                        />
                                    </div>
                                    {
                                        !this.state.isLoading &&
                                        <div className='row mt-1 text-grey'>
                                            <div className='col-12' style={{fontSize: 12, color: COLORS.GREY600}}>
                                                Для корректного импорта файл должен иметь формат CSV c разделителем ';' и кодировкой Windows-1251
                                            </div>
                                        </div>
                                    }

                                </div>

                                <div className='row mt-2'>
                                    {
                                        Boolean(this.state.file) &&
                                        <Button variant='primary' onClick={this.onUploadPressed}>
                                            {!this.state.isLoading &&
                                            `Загрузить ${this.state.file?.name || ''}`
                                            }
                                            {
                                                this.state.isLoading &&
                                                <span>
                                                        <Spinner className='mx-1' size='sm' animation="border"
                                                                 variant='white' role="status"/>
                                                    {`Идёт загрузка...`}
                                                    </span>
                                            }
                                        </Button>
                                    }
                                    {
                                        this.state.isLoading &&
                                        <div className='mt-2 text-danger text-center fw-bolder'>
                                            Процесс импорта может занять несколько минут, пожалуйста, не закрывайте это
                                            окно до завершения.
                                        </div>
                                    }
                                </div>

                                <div className="bg-white text-center sticky-bottom border-top p-4">
                                    <div className="row gx-4">
                                        <div className="col-12">
                                            <button id="cancel"
                                                    onClick={this.props.onCancel}
                                                    className="btn btn-danger w-100 fw-500">Отмена
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Modal.Body>
            </Modal>
        )
    }

}

export default ContactCSVImportModal
