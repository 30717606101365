import {Spinner} from "react-bootstrap-v5";
import React from "react";

interface Props {
    loadingText?: string
    size?: number
}
const AppSpinner = (props: Props) => {
    const spinnerSize = props.size || 100
    return (
        <div className='w-100 mt-5 container d-flex flex-column align-items-center justify-content-center m-auto'>
            <Spinner style={{height: spinnerSize, width: spinnerSize}} animation="border" variant='primary' role="status">
            </Spinner>
            <span className="mt-2">{props.loadingText || 'Загрузка...'}</span>
        </div>
    )
}

export default AppSpinner
