import React from "react";
import {Modal} from "react-bootstrap-v5";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import NumericInput from "react-numeric-input";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {
    ApiAgitationDistributionsResponseRow,
} from "../../../api/ResponseModels";
import {commonDateTimeFormat} from "../../../utils/Formatters";
import moment from "moment";
import * as TypedUtils from "../../../utils/TypedUtils";
import Icon from "@iconify/react";
import trash24 from "@iconify/icons-octicon/trash-24";
import {MaterialDistributionStatus} from "../../../models/enums";


interface Props {
    onCancel: () => void
    onDeleted: () => void
    toastManager: ToastConsumerContext
    distributionInfo: ApiAgitationDistributionsResponseRow
}

class MaterialDistributionViewModal extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    onDeletePressed = async () => {
            try {
                if (window.confirm("Вы уверены, удалить разноску? Это действие невозможно отменить")) {
                    await API.deleteAgitationDistribution(this.props.distributionInfo.id, globalStore.getState().authToken)
                    this.props.toastManager.add(`Разноска успешно удалена`, {
                        appearance: 'success',
                        autoDismiss: false
                    });
                    this.props.onDeleted()
                }
            } catch (e) {
                console.log(e)
                this.props.toastManager.add(`Не удалось удалить разноску!`, {
                    appearance: 'error',
                    autoDismiss: false
                });
            }
    }

    render() {
        return (
            <Modal show={true} onHide={this.props.onCancel} centered>
                <Modal.Body>
                    <main id="call-script-editor" className="container-fluid">
                        <div className="max-w-md shadow-lg mb-4">
                            <div
                                className="bg-dark text-white text-center fw-500 py-3 d-flex flex-row justify-content-between align-items-center">
                            <span className='ms-5'>
                                Карточка разноски
                                {
                                    this.props.distributionInfo.id &&
                                    <span
                                        className="font-monospace fw-bold text-red ms-1">{this.props.distributionInfo.id}</span>
                                }
                            </span>
                            </div>
                            <div className="bg-white pt-2 pb-4 px-4">
                                <div className="row small pb-2 border-bottom mb-2">
                                    <div className="col-3 fw-500 mb-1">Создана</div>
                                    <div
                                        className="col-auto fw-500 font-monospace">{commonDateTimeFormat(moment(this.props.distributionInfo.createdAt))}</div>
                                    {
                                        <div className="col text-truncate">
                                            {TypedUtils.renderUserLink(this.props.distributionInfo.createdBy.fullName, this.props.distributionInfo.createdBy.id)}
                                        </div>
                                    }
                                </div>
                                {
                                    this.props.distributionInfo.fullAddress &&
                                    <div className="row small pb-1">
                                        <span className='col-6'>
                                            {TypedUtils.makeFullAddressWithGeo(this.props.distributionInfo.fullAddress)}
                                        </span>
                                        <div style={{fontSize: '0.85rem'}}
                                             onClick={this.onDeletePressed}
                                             className='col-6 text-danger cursor-pointer d-flex align-items-center justify-content-end'>
                                            <Icon icon={trash24} color='red' className='me-2'/>
                                            Удалить разноску
                                        </div>
                                    </div>
                                }
                                {
                                    this.props.distributionInfo.entranceNo &&
                                    <div
                                        className="row small pb-1 px-2">
                                        <span className='col-12'>{`Подьезд ${this.props.distributionInfo.entranceNo}`}</span>
                                    </div>
                                }
                                <label className="col-form-label col-3">
                                    Количество
                                    {
                                        this.props.distributionInfo.status === MaterialDistributionStatus.FAILURE &&
                                            <span className='font-monospace fw-bold text-red ms-1'>(Неудачно)</span>
                                    }
                                </label>
                                <div className="col-9 mb-3">
                                    <input
                                        value={this.props.distributionInfo.distributionCount}
                                        disabled
                                        className="form-control" placeholder="-----" type="text"/>
                                </div>
                                {
                                    this.props.distributionInfo.comment &&
                                    <div className="col-12">
                                        <label className="form-label mb-3">Комментарий</label>
                                        <label className="float-end text-muted">
                                            <small>
                                            <span>{this.props.distributionInfo.comment?.length || 0}
                                            </span> /
                                                1000
                                            </small>
                                        </label>
                                        <textarea id="сomment" className="form-control mb-"
                                                  disabled
                                                  style={{minHeight: '10em', maxHeight: '30em'}} placeholder="—"
                                                  value={this.props.distributionInfo.comment || ''}
                                                  maxLength={1000} defaultValue={""}/>
                                    </div>
                                }

                                <div className="bg-white text-center sticky-bottom p-4">
                                    <div className="row gx-4">
                                        <div className="col-12">
                                            <button id="cancel"
                                                    onClick={this.props.onCancel}
                                                    className="btn btn-danger w-100 fw-500">Назад
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Modal.Body>
            </Modal>
        )
    }

}

export default MaterialDistributionViewModal
