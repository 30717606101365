import React from "react";
import TableSearchFilterView, {FilterOption, TableSearchFilterPropType} from "./TableSearchFilterView";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import * as API from "../../../../api/API";
import {globalStore} from "../../../../utils/redux/ReduxUtils";


interface Props {
    toastManager: ToastConsumerContext
    placeholder?: string
    value?: FilterOption<string, string>[]
    setValue: (v: FilterOption<string, string>[] | undefined) => void
}

class ContactThematicsMultiResultSearchTableFilter extends React.Component<Props, any> {
    searchRows = async (searchQueryOpt?: string) => {
        const response = await API.getAllPossibleContactThematics(globalStore.getState().authToken).catch(err => {
            this.props.toastManager.add('Не удалось загрузить список возможных проблематик!', {appearance: 'error'})
            throw err
        })
        return response.rows
            .filter(r => r.toString().includes(searchQueryOpt?.trim() || ''))
            .map(row => {
                return {
                    key: row,
                    value: row,
                    label: row,
                }
            })
    }

    render() {
        return (
            <TableSearchFilterView
                type={TableSearchFilterPropType.MULTIPLE}
                title='Проблематика'
                value={this.props.value}
                setValue={this.props.setValue}
                searchRows={this.searchRows}
            />
        )
    }
}

export default withToastManager(ContactThematicsMultiResultSearchTableFilter)
