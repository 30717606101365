import React from "react";
import Icon from "@iconify/react";
import arrowLeft16 from "@iconify/icons-octicon/arrow-left-16";
import {COLORS} from "../../../utils/Consts";
import classnames from "classnames";

interface Props {
    houseFullInfo: any
    onBackPressed: () => void
}

export const MapHouseFullInfoPanel = (props: Props) => {
    const renderRow = (title: string, value?: string, additionalStyleClasses?: string) =>
        !value
            ? undefined
            : <div className={classnames('d-flex flex-column col-12 mb-1 border-bottom px-1', additionalStyleClasses)}>
                <div className='col-auto fw-300'>{title}:</div>
                <div className='col-auto fw-bold'>{value}</div>
            </div>

    return (
        <div className='d-flex flex-column align-items-center justify-content-center pb-2 pt-1'>
            <div className='col-12 pb-1'>
                <div className='d-flex flex-column justify-content-between map-top-info-panel'>
                    <span className='d-flex mb-2 px-2 align-items-center'>
                          <div onClick={props.onBackPressed}
                               className='col-1 me-2'>
                                            <Icon className='cursor-pointer' icon={arrowLeft16} width={20}
                                                  color={COLORS.GREY600} style={{}}/>
                          </div>
                        <div style={{fontSize: 13}} className='fw-bolder col-11'>Информация по дому</div>
                    </span>
                    {renderRow('Полный адрес', props.houseFullInfo.fullAddress)}
                    {renderRow('Год постройки', props.houseFullInfo.builtYear)}
                    {renderRow('Год начала эксплуатации', props.houseFullInfo.exploitationStartYear)}
                    {renderRow('Тип дома', props.houseFullInfo.houseType)}
                    {renderRow('Серия дома', props.houseFullInfo.buildingSeriesAndType)}
                    {renderRow('Кол-во этажей', props.houseFullInfo.floorCountMax)}
                    {renderRow('Кол-во подъездов', props.houseFullInfo.entranceCount)}
                    {renderRow('Кол-во лифтов', props.houseFullInfo.elevatorsCount)}
                    {renderRow('Аварийный', props.houseFullInfo.isAlarm)}
                    {renderRow('Кол-во жилых квартир', props.houseFullInfo.livingQuartersCount, 'border-bottom-0')}
                </div>
            </div>
        </div>
    )
}
