import React from "react";
import {Modal} from "react-bootstrap-v5";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import {APICallScriptResponse, ApiContactInfoResponseRow, ApiPhoneCallByIdResponse} from "../../../api/ResponseModels";
import AppSpinner from "../../components/AppSpinner";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import ContactInput from "../ContactInput";
import {APIScenarioPossibleResult} from "../../../api/Models";
import {PossibleResultsPanel} from "../../components/PossibleResultsPanel";
import VoxImplantButton from "../../components/calls/voximplant/VoxImplantButtonsPanel";
import * as ApiResponseMappers from "../../../api/ApiResponseMappers";
import {ApiUpsertPhoneCallRequest} from "../../../api/RequestModels";
import * as RoleUtils from "../../../utils/RoleUtils";
import {commonDateTimeFormat} from "../../../utils/Formatters";
import moment from "moment";
import * as TypedUtils from "../../../utils/TypedUtils";

interface State {
    comment: string,
    isLoading: boolean
    showContact: boolean
    selectedResult?: APIScenarioPossibleResult
    callDurationSeconds: number
    isReadOnly: boolean
    error: string
}

interface Props {
    onCancel?: () => void
    onSaved: (m: ApiUpsertPhoneCallRequest) => void
    toastManager: ToastConsumerContext
    shiftId: number
    script: APICallScriptResponse
    possibleResults: APIScenarioPossibleResult[]
    call?: ApiPhoneCallByIdResponse
    contact: ApiContactInfoResponseRow
}

class PhoneCallInput extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            comment: props.call?.comment || '',
            isLoading: false,
            showContact: !Boolean(props.call),
            selectedResult: this.props.possibleResults.find(r => r.title === props.call?.result),
            callDurationSeconds: props.call?.durationSeconds || 0,
            error: '',
            isReadOnly: Boolean(props.call)
        }
    }

    savePhoneCall = () => {
        const model: ApiUpsertPhoneCallRequest = {
            id: this.props.call?.id || 0,
            contactId: this.props.contact.id,
            shiftId: this.props.shiftId,
            result: this.state.selectedResult?.title || '',
            durationSeconds: Math.trunc(this.state.callDurationSeconds),
            comment: this.state.comment.trim() ? this.state.comment.trim() : undefined,
            isArchived: false
        }
        if (!this.state.selectedResult) {
            this.setState({
                error: 'Необходимо указать результат звонка!'
            })
        } else {
            this.props.onSaved(model)
        }

    }

    onPossibleResultClicked = async (r: APIScenarioPossibleResult) => {
        if (this.state.selectedResult === r) this.setState({selectedResult: undefined})
        else this.setState({selectedResult: r})
    }

    render() {
        const contactMappedFromApi = this.props.contact ? ApiResponseMappers.mapApiContactToContact(this.props.contact) : undefined
        const hadCallEditPermission = RoleUtils.hasPhoneCallManagementPermission(globalStore.getState().externalAdditionalInfo.user.role) ||
            RoleUtils.hasMakeCallPermission(globalStore.getState().externalAdditionalInfo.user.role)
        const hasAnalyticsPermission = RoleUtils.hasAnalyticsPermission(globalStore.getState().externalAdditionalInfo.user.role)
        return (
            <main id="call-script-editor" className="container-fluid px-0">
                <div className="col-12 shadow-lg mb-4">
                    <div
                        className="bg-dark text-white text-center fw-500 py-3 d-flex flex-row justify-content-between align-items-center">
                        <span className='ms-5'>
                            Звонок
                            {
                                this.props.call?.id !== 0 &&
                                <span className="font-monospace fw-bold text-red ms-1">{this.props.call?.id}</span>
                            }
                        </span>
                    </div>
                    <div className="bg-white pt-2 pb-4 px-4">
                        {
                            this.state.isLoading &&
                            <AppSpinner/>
                        }
                        {
                            !this.state.isLoading &&
                            <>
                                {
                                    this.props.call &&
                                    <div
                                        className="d-flex flex-row justify-content-end align-items-start small mb-2">
                                        <div className="col-auto fw-500 mb-1 me-4">Создан</div>
                                        <div
                                            className="col-auto fw-500 font-monospace">{commonDateTimeFormat(moment(this.props.call?.createdAt))}</div>
                                        {
                                            this.props.call.createdBy && hasAnalyticsPermission &&
                                            <div className="ms-3 text-truncate">
                                                {TypedUtils.renderUserLink(this.props.call.createdBy?.fullName, this.props.call.createdBy?.id)}
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    !this.state.isReadOnly &&
                                    <div
                                        className='row d-flex flex-row justify-content-between'>
                                        {
                                            this.props.contact &&
                                            <VoxImplantButton
                                                onCallEnded={(callDurationSeconds: number) => this.setState({callDurationSeconds: callDurationSeconds})}
                                                phoneNumber={this.props.contact.phoneNumber}
                                            />
                                        }
                                    </div>
                                }
                                <div style={{marginTop: this.state.isReadOnly ? 0 : -20}}
                                     className='d-flex flex-row justify-content-end align-items-start mb-1 border-bottom pb-2'>
                                    <a
                                        onClick={() => this.setState({showContact: !this.state.showContact})}
                                        className='cursor-pointer'>{this.state.showContact ? 'Скрыть контакт' : 'Показать контакт'}</a>
                                </div>
                                {
                                    contactMappedFromApi && this.state.showContact &&
                                    <ContactInput
                                        rawApiContact={this.props.contact}
                                        contact={contactMappedFromApi}
                                        contactsCount={1}
                                        contactNo={0}
                                    />
                                }
                                <fieldset id="contact-fieldset" disabled={this.state.isReadOnly}>
                                    <div className='row'>
                                        <PossibleResultsPanel
                                            onClick={this.onPossibleResultClicked}
                                            selected={this.state.selectedResult}
                                            results={this.props.possibleResults}/>
                                    </div>
                                    <div className='row'>
                                        <label htmlFor="commentContent"
                                               className="col-form-label col-12">Скрипт звонка</label>
                                        <div className="col-12 mb-3">
                                        <textarea id="commentContent" className="form-control"
                                                  readOnly={true}
                                                  value={this.props.script.content || ''}
                                                  style={{minHeight: '10em', height: '20em'}}/>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <label htmlFor="commentContent"
                                               className="col-form-label col-12">Комментарий (Необязательно)</label>
                                        <div className="col-12 mb-3">
                                        <textarea id="commentContent" className="form-control"
                                                  value={this.state.comment || ''}
                                                  placeholder="..."
                                                  style={{minHeight: '20em'}}
                                                  maxLength={10000}
                                                  onChange={e => this.setState({comment: e.target.value})}/>
                                        </div>
                                    </div>
                                </fieldset>
                            </>
                        }

                        <div className="bg-white text-center sticky-bottom border-top p-4">
                            {
                                this.state.error && <div
                                    className='col-12 border-top font-monospace mt-1 fw-bold text-red text-center'>{this.state.error}</div>
                            }
                            <div className="row gx-4">
                                {
                                    this.props.onCancel &&
                                    <div className="col-6">
                                        <button id="cancel"
                                                onClick={this.props.onCancel}
                                                className="btn btn-danger w-100 fw-500">Отмена
                                        </button>
                                    </div>
                                }

                                {
                                    !this.state.isReadOnly &&
                                    <div className={!Boolean(this.props.onCancel) ? "col-12" : 'col-6'}>
                                        <button id="save" onClick={this.savePhoneCall}
                                                className="btn btn-success w-100 fw-500">Сохранить
                                        </button>
                                    </div>
                                }
                                {
                                    this.state.isReadOnly && hadCallEditPermission &&
                                    <div className={this.props.onCancel? 'col-6': "col-12"}>
                                        <button onClick={() => this.setState({isReadOnly: false})}
                                                className="btn btn-primary w-100 fw-500" type="button">Редактировать
                                        </button>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }

}

export default PhoneCallInput
