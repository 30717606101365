import * as React from 'react'
import {GlobalState} from "../../utils/redux/ReduxUtils";
import {connect} from "react-redux";
import './ProfileScreen.css'
import ContentScreen from "../ContentScreen";
import {useHistory} from "react-router-dom";
import AuditSearchTable from "./AuditSearchTable";

const AuditScreen = (props: any) => {
    const history = useHistory();

    return (
        <ContentScreen>
            <div className='mx-auto w-100 mt-2 mb-2 d-flex gx-2 flex-column align-items-center'>
                <div className='row col-12'>
                    <div className="col-auto h4 fw-normal mb-4">
                        <svg className="icon icon-24 text-primary align-text-bottom me-2">
                            <use href="#search-24"/>
                        </svg>
                        Аудит последних событий
                    </div>
                </div>
                <div className='col-10'>
                    <AuditSearchTable/>
                </div>
            </div>
        </ContentScreen>

    )
}

function mapStateToProps(state: GlobalState): any {
    return {}
}


export default connect(
    mapStateToProps
)(AuditScreen);
