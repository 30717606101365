import React from "react";
import ContentScreen from "../ContentScreen";
import {Button, Spinner} from "react-bootstrap-v5";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {APICampaignSettings} from '../../api/Models'
import AppSpinner from "../components/AppSpinner";
import * as API from "../../api/API";
import {globalStore} from "../../utils/redux/ReduxUtils";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import Switch from "react-switch";
import './CampaignSettingsScreen.scss'
import NumericInput from "react-numeric-input";
import PaymentTooltip from "./payments/PaymentTooltip";
import ReactTooltip from "react-tooltip";
import {CancellableButton} from "../components/CancellableButton";
import Icon from "@iconify/react";
import plus24 from "@iconify/icons-octicon/plus-24";
import {updateLoadedDataAction} from "../../utils/redux/Actions";
import download24 from '@iconify/icons-octicon/download-24';
import * as TypedUtils from "../../utils/TypedUtils";

interface Props extends RouteComponentProps {
    toastManager: ToastConsumerContext
}

class CampaignSettingsScreen extends React.Component<Props, any> {
    state = {
        error: '',
        isLoading: true,
        hasShiftBonusSection: false,
        hasDaysStreakBonusSection: false,
        thematicsInputValue: '',
        settings: undefined as APICampaignSettings | undefined,
    }
    _loadSettings = async () => {
        const response = await API.getCampaignSettings(globalStore.getState().authToken)
        this.setState({
            isLoading: false,
            settings: response,
            hasShiftBonusSection: Boolean(response.paymentSettings?.shiftContactsStandard),
        })
    }

    constructor(props: Props) {
        super(props);
        this._loadSettings().catch(
            err => {
                this.props.toastManager.add('Не удалось загрузить настройки кампании!', {
                    appearance: 'error',
                });
                console.log(err)
            }
        )
    }

    _setPaymentPerContact = (v: number) => this.changePaymentSettingProp('paymentPerContact', v)
    _setPaymentPerAgitation = (v: number | undefined) => this.changePaymentSettingProp('paymentPerAgitationDistribution', v)
    _setShiftContactsStandard = (v: number) => this.changePaymentSettingProp('shiftContactsStandard', v)
    _setShiftContactsStandardExceedBonus = (v: number) => this.changePaymentSettingProp('shiftContactsStandardExceedBonus', v)
    _setSuccessDaysStreakCountForBonus = (v: number) => this.changePaymentSettingProp('successDaysStreakCountForBonus', v)
    _setSuccessDaysStreakCountFixedBonus = (v: number) => this.changePaymentSettingProp('successDaysStreakCountFixedBonus', v)

    changePaymentSettingProp<T>(propName: string, value: T) {
        if (this.state.settings) {
            const currentSettingsCopy = {...this.state.settings}
            // @ts-ignore
            currentSettingsCopy.paymentSettings[propName] = value
            this.setState({settings: currentSettingsCopy})
        }

    }

    _toggleShiftBonusSection = () => {
        let csCopy = {...this.state.settings}
        const nextHasShiftBonusSection = !this.state.hasShiftBonusSection
        if (!nextHasShiftBonusSection) {
            csCopy.paymentSettings.shiftContactsStandard = undefined
            csCopy.paymentSettings.shiftContactsStandardExceedBonus = undefined
            csCopy.paymentSettings.successDaysStreakCountFixedBonus = undefined
            csCopy.paymentSettings.successDaysStreakCountForBonus = undefined
        }
        this.setState({
            hasShiftBonusSection: nextHasShiftBonusSection,
            settings: csCopy
        })
    }
    _toggleUseVerificationForPayments = () => {
        let csCopy = {...this.state.settings}
        csCopy.paymentSettings.useVerificationForPayments = !this.state.settings?.paymentSettings.useVerificationForPayments
        this.setState({
            settings: csCopy
        })
    }
    _toggleDisableContactDuplicatesByPhone = () => {
        let csCopy = {...this.state.settings}
        csCopy.disableDuplicateContacts = !this.state.settings?.disableDuplicateContacts
        this.setState({
            settings: csCopy
        })
    }
    _toggleDaysStreakBonusSection = () => {
        let csCopy = {...this.state.settings}
        const nextHasDaysStreakBonusSection = !this.state.hasDaysStreakBonusSection
        if (!nextHasDaysStreakBonusSection) {
            csCopy.paymentSettings.successDaysStreakCountFixedBonus = undefined
            csCopy.paymentSettings.successDaysStreakCountForBonus = undefined
        }
        this.setState({
            hasDaysStreakBonusSection: !this.state.hasDaysStreakBonusSection,
            settings: csCopy
        })
    }
    _togglePaymentPerAgitationSection = () => {
        if (Boolean(this.state.settings?.paymentSettings.paymentPerAgitationDistribution)) {
            this._setPaymentPerAgitation(undefined)
        } else this._setPaymentPerAgitation(10)
    }
    _getIsAllRequiredFieldsFilledAndMaybeSetError = () => {
        let everythingFilled = true
        if (this.state.hasShiftBonusSection) {
            everythingFilled = everythingFilled
                && Boolean(this.state.settings?.paymentSettings?.shiftContactsStandard)
                && Boolean(this.state.settings?.paymentSettings?.shiftContactsStandardExceedBonus)
        }
        if (this.state.hasDaysStreakBonusSection) {
            everythingFilled = everythingFilled
                && Boolean(this.state.settings?.paymentSettings?.successDaysStreakCountFixedBonus)
                && Boolean(this.state.settings?.paymentSettings?.successDaysStreakCountForBonus)
        }
        if (!everythingFilled) {
            this.setState({error: "Пожалуйста, заполните все помеченные звёздочкой поля"})
        }
        return everythingFilled
    }

    _onSuggestPaymentPressed = () => {
        if (this._getIsAllRequiredFieldsFilledAndMaybeSetError() && this.state.settings) {
            this.setState({showPaymentTooltip: true})
        }
    }

    onSavePressed = () => {
        if (this._getIsAllRequiredFieldsFilledAndMaybeSetError()) {
            const updateSettingsAndSetStateAsync = async () => {
                if (this.state.settings) {
                    const newSettings = await API.updateCampaignSettings(this.state.settings, globalStore.getState().authToken)
                    this.setState({settings: newSettings})
                    this.props.toastManager.add('Настройки обновлены', {
                        appearance: 'success',
                    });
                    const globalInfo = await API.loadAdditionalInfo(globalStore.getState().authToken)
                    globalStore.dispatch(updateLoadedDataAction(globalInfo))
                }
            }
            updateSettingsAndSetStateAsync().catch(err => {
                this.props.toastManager.add('Не удалось обновить настройки кампании!', {
                    appearance: 'error',
                });
                console.log(err)
            })
        }
    }
    deleteThematics = (t: string) => {
        const settingsCopy = {...this.state.settings}
        settingsCopy.thematics = settingsCopy.thematics.filter(th => th !== t)
        this.setState({settings: settingsCopy})
    }

    onAddThematicsPressed = () => {
        const trimmedInput = this.state.thematicsInputValue.trim()
        if (trimmedInput) {
            if (this.state.settings?.thematics.includes(trimmedInput)) this.setState({thematicsInputValue: ''})
            else {
                const settingsCopy = {...this.state.settings}
                settingsCopy.thematics = settingsCopy.thematics.concat([trimmedInput])
                this.setState({settings: settingsCopy, thematicsInputValue: ''})
            }
        }
    }

    render() {
        const {error, settings} = this.state
        const {history} = this.props

        const hasShiftBonusSection = this.state.hasShiftBonusSection
        const hasDaysStreakBonusSection = this.state.hasDaysStreakBonusSection
        return (
            <ContentScreen>
                {
                    settings &&
                    <main id="contact" className="container-fluid">
                        <div className="max-w-md shadow-lg mx-auto mb-4">
                            <div
                                className="bg-dark text-white text-center fw-500 py-3 d-flex flex-row justify-content-between align-items-center">
                                <span className='ms-5'>Настройки кампании</span>
                            </div>
                            <div id="contact-form">
                                <fieldset id="contact-fieldset">
                                    <div className="bg-white p-4 pt-3">
                                        {
                                            error &&
                                            <div
                                                className='mb-2 font-monospace fw-bold text-red text-center'>{error}</div>
                                        }
                                        <div className="row">
                                            <div className='mb-3 d-flex flex-row justify-content-between'>
                                                <h5>Оплата скаутам</h5>
                                                <a onClick={this._onSuggestPaymentPressed}
                                                   className='show-payment-sample-button' data-tip='payment-tooltip'>пример
                                                    расчёта
                                                </a>
                                            </div>
                                            <div className='d-flex'>
                                                <label htmlFor="paymentPerContact"
                                                       className="col-form-label col-8 form-label-required">Плата
                                                    за 1 контакт, ₽</label>
                                                <div className="col-4 d-flex align-items-center">
                                                    <NumericInput
                                                        id="paymentPerContact"
                                                        className="form-control"
                                                        min={0}
                                                        placeholder="₽"
                                                        onChange={n => this._setPaymentPerContact(n || 0)}
                                                        value={settings.paymentSettings.paymentPerContact}/>
                                                </div>
                                            </div>
                                            <div>
                                                <hr className="mx-n4"/>
                                            </div>
                                            <div className='d-flex justify-content-between mb-2 align-items-center'>
                                                <span>Выплаты только верифицированным контактам</span>
                                                <Switch height={18} width={42}
                                                        checked={this.state.settings?.paymentSettings.useVerificationForPayments || false}
                                                        onChange={this._toggleUseVerificationForPayments}
                                                        onColor={'#206bc4'}/>
                                            </div>
                                            <div className='d-flex justify-content-between mb-2 align-items-center'>
                                                <span>Бонус за выполнение плана смены</span>
                                                <Switch height={18} width={42} checked={hasShiftBonusSection}
                                                        onChange={this._toggleShiftBonusSection} onColor={'#206bc4'}/>
                                            </div>
                                            {
                                                hasShiftBonusSection &&
                                                <>
                                                    <div className='d-flex ps-4 mb-1 align-items-center'>
                                                        <label htmlFor="shiftContactsStandard"
                                                               className="col-form-label col-8 form-label-required">
                                                            План контактов в смену на человека
                                                        </label>
                                                        <div className="col-4">
                                                            <NumericInput
                                                                id="shiftContactsStandard"
                                                                className="form-control pr-0"
                                                                min={0}
                                                                placeholder="--"
                                                                onChange={n => this._setShiftContactsStandard(n || 0)}
                                                                value={settings.paymentSettings.shiftContactsStandard}/>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex ps-4 align-items-center'>
                                                        <label htmlFor="shiftContactsStandardExceedBonus"
                                                               className="col-form-label col-8 form-label-required">
                                                            Фиксированный бонус, ₽
                                                        </label>
                                                        <div className="col-4">
                                                            <NumericInput
                                                                id="shiftContactsStandardExceedBonus"
                                                                className="form-control pr-0"
                                                                min={0}
                                                                placeholder="₽"
                                                                onChange={n => this._setShiftContactsStandardExceedBonus(n || 0)}
                                                                value={settings.paymentSettings.shiftContactsStandardExceedBonus}/>
                                                        </div>
                                                    </div>
                                                    {/*<div*/}
                                                    {/*    className='d-flex mt-3 justify-content-between mb-2 align-items-center'>*/}
                                                    {/*    <span>Бонус за успешный выход несколько дней подряд</span>*/}
                                                    {/*    <Switch height={18} width={42}*/}
                                                    {/*            checked={hasDaysStreakBonusSection}*/}
                                                    {/*            onChange={this._toggleDaysStreakBonusSection}*/}
                                                    {/*            onColor={'#206bc4'}/>*/}
                                                    {/*</div>*/}
                                                    {
                                                        hasDaysStreakBonusSection &&
                                                        <>
                                                            <div className='d-flex ps-4 mb-1 align-items-center'>
                                                                <label htmlFor="successDaysStreakCountForBonus"
                                                                       className="col-form-label col-8 form-label-required">
                                                                    Количество дней подряд
                                                                </label>
                                                                <div className="col-4">
                                                                    <NumericInput
                                                                        id="successDaysStreakCountForBonus"
                                                                        className="form-control pr-0"
                                                                        min={0}
                                                                        placeholder="--"
                                                                        onChange={n => this._setSuccessDaysStreakCountForBonus(n || 0)}
                                                                        value={settings.paymentSettings.successDaysStreakCountForBonus}/>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex ps-4 mb-1 align-items-center'>
                                                                <label htmlFor="successDaysStreakCountFixedBonus"
                                                                       className="col-form-label col-8 form-label-required">
                                                                    Фиксированный бонус, ₽
                                                                </label>
                                                                <div className="col-4">
                                                                    <NumericInput
                                                                        id="successDaysStreakCountFixedBonus"
                                                                        className="form-control pr-0"
                                                                        min={0}
                                                                        placeholder="₽"
                                                                        onChange={n => this._setSuccessDaysStreakCountFixedBonus(n || 0)}
                                                                        value={settings.paymentSettings.successDaysStreakCountFixedBonus}/>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                            }
                                            <div className='d-flex justify-content-between mb-2 align-items-center'>
                                                <span>Плата за разноску агитации</span>
                                                <Switch height={18} width={42}
                                                        checked={Boolean(this.state.settings?.paymentSettings.paymentPerAgitationDistribution)}
                                                        onChange={this._togglePaymentPerAgitationSection}
                                                        onColor={'#206bc4'}/>
                                            </div>
                                            {
                                                Boolean(settings.paymentSettings.paymentPerAgitationDistribution) &&
                                                <div className='d-flex'>
                                                    <label htmlFor="paymentPerAgitation"
                                                           className="col-form-label col-8 form-label-required">Плата
                                                        за 1 разноску, ₽</label>
                                                    <div className="col-4 d-flex align-items-center">
                                                        <NumericInput
                                                            id="paymentPerAgitation"
                                                            className="form-control"
                                                            min={1}
                                                            placeholder="₽"
                                                            onChange={n => this._setPaymentPerAgitation(n || 0)}
                                                            value={settings.paymentSettings.paymentPerAgitationDistribution}/>
                                                    </div>
                                                </div>
                                            }
                                            <div className='d-flex justify-content-between mb-2 align-items-center'>
                                                <span>Запретить дубли контактов по номеру телефона</span>
                                                <Switch height={18} width={42}
                                                        checked={Boolean(this.state.settings?.disableDuplicateContacts)}
                                                        onChange={this._toggleDisableContactDuplicatesByPhone}
                                                        onColor={'#206bc4'}/>
                                            </div>
                                            {/* -------------------------------------------------- */}
                                            <div>
                                                <hr className="mx-n4"/>
                                            </div>
                                            <h5 className='mb-3'>Возможные проблематики обходов:</h5>
                                            <div className="col-6 mb-3">
                                                <div className="btn-group-vertical w-100">
                                                    {
                                                        settings.thematics.map((t: string, idx: number) =>
                                                            <CancellableButton
                                                                index={idx}
                                                                title={t}
                                                                row={t}
                                                                variant={'outline-indigo'}
                                                                onCancelClicked={this.deleteThematics}/>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div
                                                className="d-flex align-items-center me-2 mb-3"
                                                style={{cursor: 'pointer'}}>
                                                <div className='col-5 me-2'>
                                                    <input id="thematicsInputValue" className="form-control"
                                                           value={this.state.thematicsInputValue}
                                                           onKeyDown={e => e.key === 'Enter' ? this.onAddThematicsPressed() : {}}
                                                           placeholder="..."
                                                           type="text"
                                                           onChange={e => this.setState({thematicsInputValue: e.target.value})}/>
                                                </div>
                                                <div className='d-flex flex-row align-items-center'
                                                     onClick={this.onAddThematicsPressed}
                                                >
                                                    <button className="btn border-0 p-2">
                                                        <Icon width={22} icon={plus24}/>
                                                    </button>
                                                    <div className="text-muted me-2">Новая проблематика</div>
                                                </div>
                                            </div>
                                            <div style={{fontSize: 10}}
                                                 className="d-flex align-items-center justify-content-end me-2 mb-3 cursor-pointer text-blue"
                                                 onClick={() => history.push("/geo-settings")}>
                                                <div className='border-bottom'>
                                                    К настройкам геоданных
                                                </div>
                                            </div>
                                            {/* -------------------------------------------------- */}
                                            <div>
                                                <hr className="mx-n4 d-none"/>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="bg-light text-center sticky-bottom border-top p-4">
                                    <div className="row gx-4">
                                        <div className="col-6">
                                            <button id="cancel"
                                                    onClick={() => history.goBack()}
                                                    className="btn btn-danger w-100 fw-500">Отмена
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button id="save" onClick={this.onSavePressed}
                                                    className="btn btn-success w-100 fw-500">Сохранить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ReactTooltip delayShow={100} delayUpdate={1000} className='payment-tooltip-opaque'
                                      getContent={e => <PaymentTooltip settings={this.state.settings}/>}/>
                    </main>
                }

                {
                    this.state.isLoading &&
                    <AppSpinner loadingText={'Загружаем настройки...'}/>

                }

            </ContentScreen>
        )
    }

}

export default withToastManager(withRouter(CampaignSettingsScreen))
