import React from "react";
import {Modal} from "react-bootstrap-v5";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import {
    APICallScriptResponse,
    ApiContactInfoResponseRow,
    ApiPhoneCallByIdResponse,
    ApiPhoneCallsResponseRow
} from "../../../api/ResponseModels";
import AppSpinner from "../../components/AppSpinner";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import ContactInput from "../ContactInput";
import {APIScenarioPossibleResult} from "../../../api/Models";
import {PossibleResultsPanel} from "../../components/PossibleResultsPanel";
import VoxImplantButton from "../../components/calls/voximplant/VoxImplantButtonsPanel";
import * as ApiResponseMappers from "../../../api/ApiResponseMappers";
import PhoneCallInput from "./PhoneCallInput";
import {ApiUpsertPhoneCallRequest} from "../../../api/RequestModels";

interface State {
    isLoading: boolean
    contact?: ApiContactInfoResponseRow
    phoneCall?: ApiPhoneCallByIdResponse
}

interface Props {
    onCancel: () => void
    onSaved: () => void
    phoneCallId?: number
    toastManager: ToastConsumerContext
    shiftId: number
    script: APICallScriptResponse
    possibleResults: APIScenarioPossibleResult[]
    contactId: number
}

class PhoneCallModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
            contact: undefined,
        }
    }

    savePhoneCall = (m: ApiUpsertPhoneCallRequest) => {
        const saveF = async () => {
            await API.savePhoneCallForEmployee(m, globalStore.getState().authToken)
            this.props.onSaved()
        }
        saveF().catch(err => {
            console.log(err)
            this.props.toastManager.add('Не удалось сохранить звонок!', {
                appearance: 'error',
            });
        })

    }

    componentDidMount() {
        const loadInfoF = async () => {
            const contactResponse = await API.getPhoneCallContactById(this.props.contactId, globalStore.getState().authToken)
            let callInfo: ApiPhoneCallByIdResponse | undefined = undefined
            if (this.props.phoneCallId) {
                callInfo = await API.getPhoneCallByIdForEmployee(this.props.phoneCallId, globalStore.getState().authToken)
            }
            this.setState({contact: contactResponse, phoneCall: callInfo})
        }
        loadInfoF().finally(() => {
            this.setState({isLoading: false})
        }).catch(err => {
            console.log(err)
            this.props.toastManager.add('Не удалось загрузить контакт и существующий звонок', {
                appearance: 'error',
            });
        })
    }


    render() {
        return (
            <Modal show={true} onHide={this.props.onCancel} centered className={'modal-desktop-wide'}>
                <Modal.Body>
                    {
                        this.state.isLoading || !this.state.contact &&
                            <AppSpinner/>
                    }
                    {
                        this.state.contact &&
                        <PhoneCallInput
                            onCancel={this.props.onCancel}
                            onSaved={this.savePhoneCall}
                            toastManager={this.props.toastManager}
                            shiftId={this.props.shiftId}
                            script={this.props.script}
                            possibleResults={this.props.possibleResults}
                            call={this.state.phoneCall}
                            contact={this.state.contact}
                        />
                    }

                </Modal.Body>
            </Modal>
        )
    }

}

export default PhoneCallModal
