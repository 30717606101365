import React from "react";
import TableSearchFilterView, {FilterOption, TableSearchFilterPropType} from "./TableSearchFilterView";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import * as API from "../../../../api/API";
import {globalStore} from "../../../../utils/redux/ReduxUtils";

export interface ScenarioIdAndTitleAndResult {
    scenarioId: number
    title: string
    result: string
}

interface Props {
    toastManager: ToastConsumerContext
    placeholder?: string
    hideNoCallOption?: boolean
    value?: FilterOption<string, ScenarioIdAndTitleAndResult>
    setValue: (v: FilterOption<string, ScenarioIdAndTitleAndResult> | undefined) => void
}

const NO_CALL_VALUE: FilterOption<string, ScenarioIdAndTitleAndResult> = {
    key: 'Нет звонков (-1)',
    value: {
        scenarioId: -1,
        title: 'Нет звонков',
        result: 'Нет звонков'
    },
    label: 'Нет звонков',
}

class ScenarioResultSearchTableFilter extends React.Component<Props, any> {
    searchRows = async (searchQueryOpt?: string) => {
        const response = await API.getAllActiveScenariosWithResults(globalStore.getState().authToken).catch(err => {
            this.props.toastManager.add('Не удалось загрузить список результатов сценариев!', {appearance: 'error'})
            throw err
        })
        const possibleResults = response.rows.flatMap(row => {
            const innerResultsList = row.value.possibleResults.map(r => r.title)
            return innerResultsList.map(irl =>
                ({
                    key: `${irl} (${row.id})`, label: irl, value: {
                        scenarioId: row.id,
                        title: row.value.title,
                        result: irl
                    }
                })
            )
        })
        return Boolean(this.props.hideNoCallOption) ? possibleResults: [NO_CALL_VALUE].concat(possibleResults)
    }

    render() {
        return (
            <TableSearchFilterView
                type={TableSearchFilterPropType.SINGLE}
                title={this.props.placeholder || 'Есть звонок в статусе'}
                groupBy={v => `${v.value.title} (${v.value.scenarioId})`}
                value={this.props.value}
                setValue={this.props.setValue}
                searchRows={this.searchRows}
            />
        )
    }
}

export default withToastManager(ScenarioResultSearchTableFilter)
