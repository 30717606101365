import React from "react";
import {IconButton, Menu, MenuItem, Tooltip} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";

export interface TableSettingsToolbarTitleAndHandler {
    title: string
    onClick: () => void
}
interface ContactTableSettingsToolbarProps {
    items: TableSettingsToolbarTitleAndHandler[]
}

const RemoteSearchTableSettingsToolbarButton = (props: ContactTableSettingsToolbarProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Tooltip title="Дополнительно" aria-controls="settings-menu" aria-haspopup="true"
                     onClick={e => handleClick(e)}>
                <IconButton aria-label="Дополнительно">
                    <SettingsIcon/>
                </IconButton>
            </Tooltip>
            <Menu
                id="settings-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    props.items.map((item, idx) =>
                        <MenuItem key={idx} onClick={() => {
                            item.onClick();
                            handleClose();
                        }}>{item.title}</MenuItem>
                    )
                }
            </Menu>
        </>
    )
}
export default RemoteSearchTableSettingsToolbarButton
