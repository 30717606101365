import {RemoteSearchTableFilterModel} from "../../../../models/RemoteSearchTableFilterModel";
import {
    ApiHasEmailFilterValues,
    ApiVerificationResultFilterValues,
    SearchTableFilterType
} from "../../../../models/ApiFilterEnums";
import IsArchivedTableFilter from "./IsArchivedTableFilter";
import React from "react";
import AddressSearchTableFilter from "./AddressSearchTableFilter";
import {FilterOption} from "./TableSearchFilterView";
import DateFromToSearchTableFilter from "./DateFromToSearchTableFilter";
import ContactCreatedBySearchTableFilter from "./ContactCreatedBySearchTableFilter";
import {UserSummaryInfoResponse} from "../../../../api/ResponseModels";
import PhoneCallCreatedBySearchTableFilter from "./PhoneCallCreatedBySearchTableFilter";
import VerificationScenarioResultTableFilter from "./VerificationScenarioResultTableFilter";
import HasEmailTableFilter from "./HasEmailTableFilter";
import ContactReactionSearchTableFilter from "./ContactReactionSearchTableFilter";
import {Reaction} from "../../../../models/enums";
import ContactCallsCountSearchTableFilter from "./ContactCallsCountSearchTableFilter";
import ScenarioResultSearchTableFilter from "./ScenarioResultSearchTableFilter";
import ScenarioMultiResultSearchTableFilter from "./ScenarioMultiResultSearchTableFilter";
import AddressMultiResultSearchTableFilter from "./AddressMultiResultSearchTableFilter";
import LayerIntersectionSearchTableFilter, {LayerIdAndPropertyName} from "./LayerIntersectionSearchTableFilter";
import ContactThematicsMultiResultSearchTableFilter from "./ContactThematicsMultiResultSearchTableFilter";


export const renderFilter = (
    filter: RemoteSearchTableFilterModel,
    key: number,
    onFilterValueChanged: (v: any) => void,
    omitContainer: boolean = false,
    additionalParams: any = {}
) => {
    if (filter.type === SearchTableFilterType.IS_ARCHIVED) {
        return (
            <div key={key} className='col-3 col-xs-6'>
                <IsArchivedTableFilter
                    value={filter.value}
                    defaultValue={filter.defaultValue}
                    setValue={(v) => onFilterValueChanged(v)}/>
            </div>
        )
    } else if (filter.type === SearchTableFilterType.HOUSE_ID) {
        const renderedFilter =
            <AddressSearchTableFilter
                value={filter.value}
                setValue={(v: FilterOption<number, string> | undefined) => onFilterValueChanged(v)}/>
        return (
            omitContainer ? renderedFilter : <div key={key} className='col-3 col-xs-6 '>
                {renderedFilter}
            </div>
        )
    } else if (filter.type === SearchTableFilterType.HOUSE_IDS) {
        const renderedFilter =
            <AddressMultiResultSearchTableFilter
                value={filter.value}
                setValue={(v: FilterOption<number, string>[] | undefined) => onFilterValueChanged(v)}/>
        return (
            omitContainer ? renderedFilter : <div key={key} className='col-3 col-xs-6 '>
                {renderedFilter}
            </div>
        )
    } else if (filter.type === SearchTableFilterType.DATE_FROM_TO) {
        return (
            <div key={key} className='col-auto'>
                <DateFromToSearchTableFilter
                    setValue={(v) => onFilterValueChanged(v)}
                    value={filter.value}/>
            </div>
        )
    } else if (filter.type === SearchTableFilterType.CREATED_BY) {
        const renderedFilter = <ContactCreatedBySearchTableFilter
            title={filter.customTitle}
            value={filter.value}
            singleOption={additionalParams?.singleOption}
            setValue={(v: FilterOption<number, UserSummaryInfoResponse>[] | undefined) => onFilterValueChanged(v)}/>
        return (
            omitContainer ? renderedFilter : <div key={key} className='col-3 col-xs-6 '>{renderedFilter}</div>
        )
    } else if (filter.type === SearchTableFilterType.CALL_CREATED_BY) {
        return (
            <div key={key} className='col-3 col-xs-6 '>
                <PhoneCallCreatedBySearchTableFilter
                    value={filter.value}
                    setValue={(v: FilterOption<number, UserSummaryInfoResponse> | undefined) => onFilterValueChanged(v)}/>
            </div>
        )
    } else if (filter.type === SearchTableFilterType.VERIFICATION_RESULT) {
        return (
            <div key={key} className='col-3 col-xs-6 '>
                <VerificationScenarioResultTableFilter
                    value={filter.value}
                    showNoCallsOption={filter.showNoCallsOption}
                    setValue={(v: ApiVerificationResultFilterValues | undefined) => onFilterValueChanged(v)}/>
            </div>
        )
    } else if (filter.type === SearchTableFilterType.CONTACT_REACTION) {
        return (
            <div key={key} className='col-3 col-xs-6 '>
                <ContactReactionSearchTableFilter
                    value={filter.value}
                    setValue={(v: Reaction | undefined) => onFilterValueChanged(v)}/>
            </div>
        )
    } else if (filter.type === SearchTableFilterType.HAS_EMAIL) {
        return (
            <div key={key} className='col-3 col-xs-6 '>
                <HasEmailTableFilter
                    value={filter.value}
                    setValue={(v: ApiHasEmailFilterValues | undefined) => onFilterValueChanged(v)}/>
            </div>
        )
    } else if (filter.type === SearchTableFilterType.CONTACT_CALLS_COUNT) {
        return (
            <div key={key} className='col-3 col-xs-6 '>
                <ContactCallsCountSearchTableFilter
                    value={filter.value}
                    setValue={(v: number | undefined) => onFilterValueChanged(v)}/>
            </div>
        )
    } else if (filter.type === SearchTableFilterType.CALL_SCENARIO_RESULT) {
        return (
            <div key={key} className='col-3 col-xs-6 '>
                <ScenarioResultSearchTableFilter
                    value={filter.value}
                    setValue={(v: FilterOption<number, string> | undefined) => onFilterValueChanged(v)}/>
            </div>
        )
    } else if (filter.type === SearchTableFilterType.CALL_SCENARIO_LATEST_RESULT) {
        return (
            <div key={key} className='col-3 col-xs-6 '>
                <ScenarioResultSearchTableFilter
                    placeholder={'Посл. звонок'}
                    value={filter.value}
                    setValue={(v: FilterOption<number, string> | undefined) => onFilterValueChanged(v)}/>
            </div>
        )
    } else if (filter.type === SearchTableFilterType.CALL_SCENARIO_HAS_NO_RESULT_EXCEPT) {
        return (
            <div key={key} className='col-3 col-xs-6 '>
                <ScenarioMultiResultSearchTableFilter
                    hideNoCallOption
                    placeholder={'Нет звонков кроме'}
                    value={filter.value}
                    setValue={(v: FilterOption<number, string>[] | undefined) => onFilterValueChanged(v)}/>
            </div>
        )
    } else if (filter.type === SearchTableFilterType.LAYER_ID_NAME) {
        return (
            <div key={key} className='col-3 col-xs-6 '>
                <LayerIntersectionSearchTableFilter
                    value={filter.value}
                    setValue={(v: FilterOption<string, LayerIdAndPropertyName>[] | undefined) => onFilterValueChanged(v)}/>
            </div>
        )
    } else if (filter.type === SearchTableFilterType.THEMATICS) {
        return (
            <div key={key} className='col-3 col-xs-6 '>
                <ContactThematicsMultiResultSearchTableFilter
                    value={filter.value}
                    setValue={(v: FilterOption<string, string>[] | undefined) => onFilterValueChanged(v)}/>
            </div>
        )
    }

    return null
}
