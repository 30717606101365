import React, {RefObject} from "react";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {ToastConsumerContext} from "react-toast-notifications";
import RemoteSearchTable, {SearchTableHeaderCell} from "../../components/search/RemoteSearchTable";
import {ApiElectorCommonTableParameters} from "../../../api/RequestModels";
import {ApiSortCriteria} from "../../../models/enums";
import {Badge, Size, TableCell} from "@material-ui/core";
import CommentIcon from '@material-ui/icons/Comment';
import * as TypedUtils from "../../../utils/TypedUtils";
import {commonDateTimeFormat} from "../../../utils/Formatters";
import moment from "moment";
import {ApiContactBriefInfoResponse, ApiHqContactsImportResponse} from "../../../api/ResponseModels";
import {RemoteSearchTableFilterModel} from "../../../models/RemoteSearchTableFilterModel";
import {ApiIsArchivedFilterValues, SearchTableFilterType} from "../../../models/ApiFilterEnums";
import {EMPTY_FILTER_INFO} from "../../components/search/filters/DateFromToSearchTableFilter";
import * as RoleUtils from "../../../utils/RoleUtils";
import ContactCSVImportModal from "./ContactCSVImportModal";
import RemoteSearchTableSettingsToolbarButton from "../../components/search/RemoteSearchTableSettingsToolbarButton";

interface Props {
    onRowClicked?: (row: ApiContactBriefInfoResponse) => void
    onMultipleRowsSelected?: (rows: ApiContactBriefInfoResponse[]) => void
    filterRows?: (row: ApiContactBriefInfoResponse) => boolean
    size?: Size
    toastManager: ToastConsumerContext
    isForCalls: boolean
}

interface State {
    showImportModal: boolean
}

const VERIFICATION_FILTER_DEFAULT: RemoteSearchTableFilterModel =  {
    type: SearchTableFilterType.VERIFICATION_RESULT,
    value: undefined,
    defaultValue: undefined,
    showNoCallsOption: true
}
const LATEST_VERIFICATION_RESULT_HEADER: SearchTableHeaderCell = {id: 'latestVerificationResult', disablePadding: false, label: 'Посл. звонок'}
class ContactSearchTable extends React.Component<Props, State> {
    tableRef: RefObject<any> | undefined = undefined

    constructor(props: Props) {
        super(props);
        this.state = ({
            showImportModal: false
        })
        this.tableRef = React.createRef()
    }


    HEADER_CELLS: SearchTableHeaderCell[] = [
        ...(this.props.isForCalls? [LATEST_VERIFICATION_RESULT_HEADER] : []),
        {id: 'fullName', disablePadding: false, label: 'ФИО'},
        {
            id: ApiSortCriteria.PHONE_NUMBER,
            disablePadding: false,
            label: 'Телефон',
            sortCriteria: ApiSortCriteria.PHONE_NUMBER
        },
        {
            id: ApiSortCriteria.THEMATICS,
            disablePadding: false,
            label: 'Проблематика',
            sortCriteria: ApiSortCriteria.THEMATICS
        },
        {
            id: ApiSortCriteria.FULL_ADDRESS,
            disablePadding: false,
            label: 'Адрес',
            sortCriteria: ApiSortCriteria.FULL_ADDRESS
        },
        {
            id: ApiSortCriteria.FLAT_NO,
            disablePadding: false,
            label: 'Квартира',
            sortCriteria: ApiSortCriteria.FLAT_NO
        },
        {
            id: ApiSortCriteria.ENTRANCE_NO,
            disablePadding: false,
            label: 'Подъезд',
            sortCriteria: ApiSortCriteria.ENTRANCE_NO
        },
        {id: ApiSortCriteria.USER_FULL_NAME, disablePadding: false, label: 'Создал', sortCriteria: ApiSortCriteria.USER_FULL_NAME},
        {id: ApiSortCriteria.CREATED_AT, disablePadding: false, label: 'Создан', sortCriteria: ApiSortCriteria.CREATED_AT},
        {
            id: 'reaction',
            disablePadding: false,
            label: 'Реакция',
        },
        ...(this.props.isForCalls? [] : [LATEST_VERIFICATION_RESULT_HEADER])
    ];

    ALL_FILTERS: RemoteSearchTableFilterModel[] = [
        {
            type: SearchTableFilterType.IS_ARCHIVED,
            value: ApiIsArchivedFilterValues.NOT_ARCHIVED,
            defaultValue: ApiIsArchivedFilterValues.NOT_ARCHIVED
        },
        {
            type: SearchTableFilterType.THEMATICS,
            value: undefined,
            defaultValue: undefined
        },
        {
            type: SearchTableFilterType.HOUSE_IDS,
            value: undefined,
            defaultValue: undefined
        },
        {
            type: SearchTableFilterType.LAYER_ID_NAME,
            value: undefined,
            defaultValue: undefined
        },
        {
            type: SearchTableFilterType.DATE_FROM_TO,
            value: EMPTY_FILTER_INFO,
            defaultValue: EMPTY_FILTER_INFO
        },
        {
            type: SearchTableFilterType.CREATED_BY,
            value: undefined,
            defaultValue: undefined
        },
        VERIFICATION_FILTER_DEFAULT,
        {
            type: SearchTableFilterType.CONTACT_REACTION,
            value: undefined,
            defaultValue: undefined
        }, {
            type: SearchTableFilterType.HAS_EMAIL,
            value: undefined,
            defaultValue: undefined
        }, {
            type: SearchTableFilterType.CONTACT_CALLS_COUNT,
            value: undefined,
            defaultValue: undefined
        }, {
            type: SearchTableFilterType.CALL_SCENARIO_RESULT,
            value: undefined,
            defaultValue: undefined
        }, {
            type: SearchTableFilterType.CALL_SCENARIO_LATEST_RESULT,
            value: undefined,
            defaultValue: undefined
        }, {
            type: SearchTableFilterType.CALL_SCENARIO_HAS_NO_RESULT_EXCEPT,
            value: undefined,
            defaultValue: undefined
        }
    ]

    loadRows = async (params: ApiElectorCommonTableParameters) => {
        const response = await API.getContacts(params, globalStore.getState().authToken)
        return response.rows
    }


    _renderCells = (row: ApiContactBriefInfoResponse) => {
        const renderedLatestCallResultCell = <TableCell align="left">{row.latestCallResultOpt}</TableCell>
        return (
            [
                ...(this.props.isForCalls? [renderedLatestCallResultCell] : []),
                <TableCell align='left'>{`${row.fullName}`}</TableCell>,
                <TableCell
                    align="left">{row.phoneNumber ? TypedUtils.renderWhatsAppLink(row.phoneNumber) : ''}</TableCell>,
                <TableCell align="left">{row.thematics.join(', ')}</TableCell>,
                <TableCell align="left">{row.fullAddress}</TableCell>,
                <TableCell align="left">{row.geo?.flatNo}</TableCell>,
                <TableCell align="left">{row.geo?.entranceNo}</TableCell>,
                <TableCell
                    align="left">{row.createdBy ? TypedUtils.renderUserLink(row.createdBy.fullName, row.createdBy.id) : ''}</TableCell>,
                <TableCell align="left">{commonDateTimeFormat(moment(row.createdAt))}</TableCell>,
                <TableCell align="left">
                    <Badge
                        className="table-row__comment-badge"
                        badgeContent={
                            row.comment ? <CommentIcon style={{width: 10, height: 10}}/> : 0
                        }
                        color="secondary"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                        {TypedUtils.getReactionDisplayName(row.reaction)}
                    </Badge>
                </TableCell>,
                ...(this.props.isForCalls? [] : [renderedLatestCallResultCell]),
            ]
        )
    }
    getCurrentFilterValues: () => RemoteSearchTableFilterModel[] = () => {
        return this.tableRef?.current?.getCurrentlyAppliedFilters() || []
    }
    getCurrentSearchQuery: () => string | undefined = () => {
        return this.tableRef?.current?.getCurrentSearchQuery()
    }
    onExportClicked = () => {
        const currentFilterValues = this.getCurrentFilterValues()
        API.exportContactsCSV(globalStore.getState().authToken, {
            limit: 25,
            offset: 0,
            filters: currentFilterValues,
            search: this.getCurrentSearchQuery()
        }).then(blob =>
            TypedUtils.saveContentAsCSVFile(blob, 'contacts.csv')
        ).catch(e => {
            console.log(e)
            this.props.toastManager.add('Не удалось осуществить экспорт!', {
                appearance: 'error',
            });
        })
    }
    onImportClicked = () => {
        this.setState({showImportModal: true})
    }
    onImportSuccessful = (r: ApiHqContactsImportResponse) => {
        this.setState({showImportModal: false})
        let message = `Импорт успешно осуществлён. Создано ${r.createdContactsCount} контактов`
        if (r.createdUsersCount > 0) message += `, ${r.createdUsersCount} аккаунтов скаутов`
        if (r.createdCallsCount > 0) message += `, ${r.createdCallsCount} звонков верификации`
        this.props.toastManager.add(message, {
            appearance: 'success',
            autoDismiss: false
        });
        this.tableRef?.current?.loadRowsAsync()
    }


    render() {
        return (
            <>
                <RemoteSearchTable
                    ref={this.tableRef}
                    toastManager={this.props.toastManager}
                    title={'Контакты'}
                    size={this.props.size}
                    searchPlaceholder={'ФИО, телефон, проблематика или адрес'}
                    rowKeyExtractor={(u: ApiContactBriefInfoResponse) => u.id.toString()}
                    applyClassToRow={(u: ApiContactBriefInfoResponse) => u.isArchived ? 'table-row__archived' : ''}
                    renderTableCells={this._renderCells}
                    filterRows={this.props.filterRows ? this.props.filterRows : undefined}
                    headerCells={this.HEADER_CELLS}
                    loadRows={this.loadRows}
                    onMultipleRowsSelected={this.props.onMultipleRowsSelected}
                    allFilters={this.ALL_FILTERS}
                    additionalToolbarElements={
                        RoleUtils.hasContactEditPermission(globalStore.getState().externalAdditionalInfo.user.role)
                            ? <RemoteSearchTableSettingsToolbarButton
                                items={[
                                    {title: 'Экспортировать таблицу', onClick: this.onExportClicked},
                                    {title: 'Импортировать', onClick: this.onImportClicked}
                                ]}
                            />
                            : undefined
                    }
                    onRowSelected={this.props.onRowClicked}/>
                {
                    this.state.showImportModal &&
                    <ContactCSVImportModal
                        toastManager={this.props.toastManager}
                        onCancel={() => this.setState({showImportModal: false})}
                        onSuccess={this.onImportSuccessful}/>
                }
            </>
        );
    }
}

export default ContactSearchTable
