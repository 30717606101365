import React from "react";
import TableSearchFilterView, {FilterOption, TableSearchFilterPropType} from "./TableSearchFilterView";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import * as API from "../../../../api/API";
import {globalStore} from "../../../../utils/redux/ReduxUtils";
import {UserSummaryInfoResponse} from "../../../../api/ResponseModels";

interface Props {
    toastManager: ToastConsumerContext
    value?: FilterOption<number, UserSummaryInfoResponse>
    setValue: (v: FilterOption<number, UserSummaryInfoResponse> | undefined) => void
}

class PhoneCallCreatedBySearchTableFilter extends React.Component<Props, any> {
    searchRows = async (searchQueryOpt?: string) => {
        const response = await API.searchCallCreatedBy(searchQueryOpt, globalStore.getState().authToken).catch(err => {
            this.props.toastManager.add('Не удалось загрузить список пользователей!', {appearance: 'error'})
            throw err
        })
        return response.rows.map(r => ({
            key: r.id,
            value: r.value,
            label: `${r.value.fullName} (${r.id})`
        }))
    }

    render() {
        return (
            <TableSearchFilterView
                title={'Автор звонка'}
                value={this.props.value}
                setValue={this.props.setValue}
                searchRows={this.searchRows}
                type={TableSearchFilterPropType.SINGLE}/>
        )
    }
}

export default withToastManager(PhoneCallCreatedBySearchTableFilter)
