import React from "react";
import ContentScreen from "../../ContentScreen";
import {Button, Dropdown, Nav} from "react-bootstrap-v5";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons";
import {RouteComponentProps} from "react-router";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import {withRouter} from "react-router-dom";
import AppSpinner from "../../components/AppSpinner";
import {APICallShiftInfo} from "../../../api/Models";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import ShiftEditModal from "./ShiftEditModal";
import moment from "moment";
import {commonDateTimeFormat} from "../../../utils/Formatters";
import Icon from "@iconify/react";
import comment16 from "@iconify/icons-octicon/comment-16";
import ContactSearchTableNew from "../contacts/ContactSearchTable";
import PhoneCallsSearchTable from "./PhoneCallsSearchTable";
import {ApiPhoneCallsResponseRow} from "../../../api/ResponseModels";

interface Props extends RouteComponentProps {
    toastManager: ToastConsumerContext
}

interface State {
    shifts: APICallShiftInfo[]
    isLoading: boolean
    showShiftEditModal: boolean
}

class CallsScreen extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = ({
            isLoading: true,
            shifts: [],
            showShiftEditModal: false
        })

    }

    componentDidMount() {
        this._loadActiveShiftsAsync()
    }

    onStartShiftPressed = () => {

    }
    _loadActiveShiftsAsync = () => {
        const requestActiveShiftsF = async () => {
            const response = await API.getShifts(globalStore.getState().authToken)
            this.setState({shifts: response.rows})
        }

        requestActiveShiftsF()
            .catch(err => {
                this.props.toastManager.add('Не удалось загрузить активные смены!', {
                    appearance: 'error',
                });
                console.log(err)
            })
            .finally(() => this.setState({isLoading: false}))
    }
    onFinishShiftPressed = (shift: APICallShiftInfo) => {
        if (window.confirm("Вы уверены, завершить обзвон?")) {
            API.toggleShift({
                id: shift.id,
                scenarioId: shift.scenarioId
            }, globalStore.getState().authToken).then(() => {
                this._loadActiveShiftsAsync()
                this.props.toastManager.add('Обзвон успешно завершен', {
                    appearance: 'success',
                });
            }).catch(err => {
                this.props.toastManager.add('Не удалось завершить обзвон', {
                    appearance: 'error',
                });
                console.log(err)
            })

        }
    }

    render() {
        const currentlyActiveShifts = this.state.shifts.filter(s => !s.finishedAt)
        return (
            <ContentScreen>
                <div className='mx-auto w-100 mt-2 mb-2 d-flex gx-2 flex-column align-items-center'>
                    <div className='row col-12 justify-content-between'>
                        <div className="col-auto h4 fw-normal">
                            <Icon icon={comment16} className='icon icon-24 text-primary align-text-bottom me-2'/>
                            Звонки
                        </div>
                        <div className='col-2 flex-row'>
                            <Dropdown>
                                <Dropdown.Toggle>
                                    <FontAwesomeIcon className='icon icon-16 d-md-none d-lg-inline me-3 me-lg-2'
                                                     color='white' icon={faCog}/>
                                    Настройки
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => this.props.history.push('/call-scripts')}>
                                        Скрипты звонков
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.props.history.push('/call-scenarios')}>
                                        Сценарии звонков
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.props.history.push('/my-calls')}>Мои
                                        звонки</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className='row col-6 my-2 justify-content-center'>
                        <Button
                            onClick={() => this.setState({showShiftEditModal: true})}
                            className='col-4 col-sm-12'>Начать обзвон</Button>
                    </div>
                    {
                        this.state.isLoading &&
                        <AppSpinner/>
                    }
                    {
                        this.state.showShiftEditModal &&
                        <ShiftEditModal
                            currentlyActiveShifts={currentlyActiveShifts}
                            onSaved={() => {
                                this.setState({showShiftEditModal: false}, () => {
                                    this._loadActiveShiftsAsync()
                                })
                            }}
                            onCancel={() => this.setState({showShiftEditModal: false})}/>
                    }
                    {
                        currentlyActiveShifts.length > 0 &&
                        <div className='row col-8'>
                            <h3>Активные обзвоны:</h3>
                            {
                                this.state.shifts.map((shift, idx) =>
                                    <div
                                        key={idx}
                                        className='col-10 mb-1 d-flex flex-row justify-content-between align-items-center'>
                                        <h5 className='mb-0'>
                                            Обзвон от {commonDateTimeFormat(moment(shift.createdAt))}.
                                            Сценарий: {shift.scenarioTitle}
                                        </h5>
                                        <Button
                                            onClick={() => this.onFinishShiftPressed(shift)}
                                            variant='danger'>Завершить</Button>
                                    </div>
                                )
                            }
                        </div>
                    }
                    <div className='col-12 mt-2'>
                        <PhoneCallsSearchTable
                            toastManager={this.props.toastManager}
                            onRowClicked={(r: ApiPhoneCallsResponseRow) => window.open(`/call/${r.id}`, '_blank')?.focus()}/>
                    </div>
                </div>
            </ContentScreen>
        )
    }
}

export default withToastManager(withRouter(CallsScreen))
