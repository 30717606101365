import React from "react";
import TableSearchFilterView, {FilterOption, TableSearchFilterPropType} from "./TableSearchFilterView";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import * as API from "../../../../api/API";
import {globalStore} from "../../../../utils/redux/ReduxUtils";

interface Props {
    toastManager: ToastConsumerContext
    value?: FilterOption<number, string>[]
    setValue: (v: FilterOption<number, string>[] | undefined) => void
}
const NO_ADDRESS_VALUE: FilterOption<number, string> = {
    key: -1,
    value: 'Без адреса',
    label: 'Без адреса',
}
class AddressMultiResultSearchTableFilter extends React.Component<Props, any> {
    searchRows = async (searchQueryOpt?: string) => {
        const response = await API.searchAddresses(searchQueryOpt, globalStore.getState().authToken).catch(err => {
            this.props.toastManager.add('Не удалось загрузить список адресов!', {appearance: 'error'})
            throw err
        })
        return [NO_ADDRESS_VALUE].concat(response.rows.map(r => ({
            key: r.id,
            value: r.value,
            label: r.value
        })))
    }

    render() {
        return (
            <TableSearchFilterView
                type={TableSearchFilterPropType.MULTIPLE}
                title={'Адрес'}
                value={this.props.value}
                setValue={this.props.setValue}
                searchRows={this.searchRows}
            />
        )
    }
}

export default withToastManager(AddressMultiResultSearchTableFilter)
