import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {ApiVerificationResultFilterValues} from "../../../../models/ApiFilterEnums";
import {VerificationScenarioResultName} from "../../../../models/enums";

interface Props {
    value?: ApiVerificationResultFilterValues
    showNoCallsOption: boolean
    setValue: (v: ApiVerificationResultFilterValues | undefined) => void
}

type POSSIBLE_OPTIONS_TYPE = VerificationScenarioResultName | "Без звонков"

const optTypeToValue = (s: POSSIBLE_OPTIONS_TYPE) => {
    if (s === VerificationScenarioResultName.VERIFIED) return ApiVerificationResultFilterValues.VERIFIED
    else if (s === VerificationScenarioResultName.NOT_VERIFIED) return ApiVerificationResultFilterValues.NOT_VERIFIED
    else if (s === VerificationScenarioResultName.ABSENT) return ApiVerificationResultFilterValues.ABSENT
    else if (s === "Без звонков") return ApiVerificationResultFilterValues.NOTHING
}

function apiTypeToOpt(t: ApiVerificationResultFilterValues): POSSIBLE_OPTIONS_TYPE {
    if (t === ApiVerificationResultFilterValues.VERIFIED) return VerificationScenarioResultName.VERIFIED
    else if (t === ApiVerificationResultFilterValues.NOT_VERIFIED) return VerificationScenarioResultName.NOT_VERIFIED
    else if (t === ApiVerificationResultFilterValues.ABSENT) return VerificationScenarioResultName.ABSENT
    else return "Без звонков"
}

export default function VerificationScenarioResultTableFilter(props: Props) {
    const [inputValue, setInputValue] = React.useState('');
    const availableOptions: POSSIBLE_OPTIONS_TYPE[] = [
        VerificationScenarioResultName.VERIFIED,
        VerificationScenarioResultName.NOT_VERIFIED,
        VerificationScenarioResultName.ABSENT,
        ...(props.showNoCallsOption? ["Без звонков" as VerificationScenarioResultName]: [])
    ]

    return (
        <Autocomplete
            size={'small'}
            value={props.value ? apiTypeToOpt(props.value) : undefined}
            onChange={(e, value) => {
                return props.setValue(value ? optTypeToValue(value): undefined)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            options={availableOptions}
            renderInput={(params) =>
                <TextField {...params} label="Верификация" variant="outlined"/>
            }
        />
    );
}
