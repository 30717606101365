import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {ApiHasEmailFilterValues, ApiIsArchivedFilterValues} from "../../../../models/ApiFilterEnums";

interface Props {
    value?: ApiHasEmailFilterValues
    setValue: (v: ApiHasEmailFilterValues | undefined) => void
    defaultValue?: ApiHasEmailFilterValues
}

type POSSIBLE_OPTIONS_TYPE = 'Указан' | 'Не указан'

const optTypeToValue = (s: POSSIBLE_OPTIONS_TYPE) => {
    if (s === 'Указан') return ApiHasEmailFilterValues.HAS
    else if (s === 'Не указан') return ApiHasEmailFilterValues.HAS_NOT
    else return undefined
}

function apiTypeToOpt(t: ApiHasEmailFilterValues): POSSIBLE_OPTIONS_TYPE {
    if (t === ApiHasEmailFilterValues.HAS) return 'Указан'
    else if (t === ApiHasEmailFilterValues.HAS_NOT) return 'Не указан'
    else return 'Не указан'
}

export default function HasEmailTableFilter(props: Props) {
    const [inputValue, setInputValue] = React.useState('');

    return (
        <Autocomplete
            size={'small'}
            value={props.value ? apiTypeToOpt(props.value) : undefined}
            onChange={(e, value) => {
                props.setValue(value? optTypeToValue(value): undefined)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            options={['Указан', 'Не указан'] as POSSIBLE_OPTIONS_TYPE[]}
            renderInput={(params) =>
                <TextField {...params} label="Email" variant="outlined"/>
            }
        />
    );
}
