import React, {RefObject} from "react";
import moment from "moment";
import RemoteSearchTable, {SearchTableHeaderCell} from "../../components/search/RemoteSearchTable";
import {ApiSortCriteria} from "../../../models/enums";
import {ApiElectorCommonTableParameters} from "../../../api/RequestModels";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import * as API from "../../../api/API";
import {ApiPaymentsResponseRow} from "../../../api/ResponseModels";
import {TableCell} from "@material-ui/core";
import * as TypedUtils from "../../../utils/TypedUtils";
import {commonDateTimeFormat} from "../../../utils/Formatters";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";


const HEADER_CELLS: SearchTableHeaderCell[] = [
    {
        id: 'author',
        disablePadding: false,
        label: 'Автор',
    },
    {
        id: 'createdByRole',
        disablePadding: false,
        label: 'Должность',
    },
    {
        id: ApiSortCriteria.PAYMENT_SUM,
        disablePadding: false,
        label: 'Сумма',
        sortCriteria: ApiSortCriteria.PAYMENT_SUM
    },
    {
        id: 'targetUser',
        disablePadding: false,
        label: 'Кому выплачено',
    },
    {
        id: 'targetUserRole',
        disablePadding: false,
        label: 'Должность',
    },
    {
        id: 'comment',
        disablePadding: false,
        label: 'Комментарий',
    },
    {id: ApiSortCriteria.CREATED_AT, disablePadding: false, label: 'Создана', sortCriteria: ApiSortCriteria.CREATED_AT},
];

interface Props {
    onRowSelected?: (row: ApiPaymentsResponseRow) => void
    toastManager: ToastConsumerContext
}

class PaymentsSearchTable extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
        this.tableRef = React.createRef()
    }

    tableRef: RefObject<any> | undefined = undefined

    loadRows = async (params: ApiElectorCommonTableParameters) => {
        const response = await API.getPayments(params, globalStore.getState().authToken)
        return response.rows
    }
    _reloadTable = () => this.tableRef?.current?.loadRowsAsync()

    _renderCells = (row: ApiPaymentsResponseRow) => {
        return (
            [
                <TableCell
                    align='left'>{TypedUtils.renderUserLink(row.createdBy.fullName, row.createdBy.id)}</TableCell>,
                <TableCell align='left'>{TypedUtils.getUserRoleDisplayName(row.createdBy.role)}</TableCell>,
                <TableCell align='left'>{row.value}</TableCell>,
                <TableCell
                    align='left'>{TypedUtils.renderUserLink(row.targetUser.fullName, row.targetUser.id)}</TableCell>,
                <TableCell align='left'>{TypedUtils.getUserRoleDisplayName(row.targetUser.role)}</TableCell>,
                <TableCell align='left'>{row.comment || ''}</TableCell>,
                <TableCell align="left">{commonDateTimeFormat(moment(row.createdAt))}</TableCell>
            ]
        )
    }


    render() {
        return (
            <RemoteSearchTable
                toastManager={this.props.toastManager}
                ref={this.tableRef}
                onRowSelected={this.props.onRowSelected}
                searchPlaceholder={'Кому выплачено, сумма или комментарий'}
                rowKeyExtractor={(u: ApiPaymentsResponseRow) => u.id.toString()}
                renderTableCells={this._renderCells}
                headerCells={HEADER_CELLS}
                loadRows={this.loadRows}/>
        );
    }
}

export default withToastManager(PaymentsSearchTable)
