import React, {RefObject} from "react";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import RemoteSearchTable, {SearchTableHeaderCell} from "../../components/search/RemoteSearchTable";
import {ApiElectorCommonTableParameters} from "../../../api/RequestModels";
import {RouteComponentProps} from "react-router";
import {ApiSortCriteria} from "../../../models/enums";
import {TableCell} from "@material-ui/core";
import {commonDateTimeFormat} from "../../../utils/Formatters";
import moment from "moment";
import {
    ApiAgitationMaterialsByUsersResponseRow,
} from "../../../api/ResponseModels";
import * as TypedUtils from "../../../utils/TypedUtils";
import UpdateMaterialGivenCountForUserModal from "./UpdateMaterialGivenCountForUserModal";


const HEADER_CELLS: SearchTableHeaderCell[] = [
    {
        id: ApiSortCriteria.MATERIAL_TITLE,
        disablePadding: false,
        label: 'Материал',
        sortCriteria: ApiSortCriteria.MATERIAL_TITLE
    },
    {
        id: ApiSortCriteria.MATERIAL_GIVEN_COUNT,
        disablePadding: false,
        label: 'Выдано, шт',
        sortCriteria: ApiSortCriteria.MATERIAL_GIVEN_COUNT
    },
    {id: 'remainingCount', disablePadding: false, label: 'Осталось, шт'},
    {id: 'user', disablePadding: false, label: 'Сотрудник'},
    {id: 'updatedBy', disablePadding: false, label: 'Посл. обновлял'},
    {
        id: ApiSortCriteria.UPDATED_AT,
        disablePadding: false,
        label: 'Посл. обновлена',
        sortCriteria: ApiSortCriteria.UPDATED_AT
    },
];

type RowType = ApiAgitationMaterialsByUsersResponseRow

interface Props {
    filterRows?: (row: RowType) => boolean
    toastManager: ToastConsumerContext
}

interface State {
    returnToBaseResponseRow?: RowType
}

class AgitationMaterialsByUsersSearchTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = ({
            returnToBaseResponseRow: undefined
        })
        this.tableRef = React.createRef()
    }

    tableRef: RefObject<any> | undefined = undefined

    loadRows = async (params: ApiElectorCommonTableParameters) => {
        const users = await API.getAllMaterialDistributionsByUsers(globalStore.getState().authToken, params)
        return users.rows
    }

    _renderCells = (row: RowType) => {
        return ([
            <TableCell component="th" scope="row">{row.materialTitle}</TableCell>,
            <TableCell component="th" scope="row">{row.givenCount}</TableCell>,
            <TableCell component="th" scope="row">{row.remainingCount}</TableCell>,
            <TableCell component="th"
                       scope="row">{TypedUtils.renderUserLink(row.user.fullName, row.user.id)}</TableCell>,
            <TableCell component="th"
                       scope="row">{TypedUtils.renderUserLink(row.updatedBy.fullName, row.updatedBy.id)}</TableCell>,
            <TableCell align="left">{commonDateTimeFormat(moment(row.updatedAt))}</TableCell>,
        ])
    }
    _reloadTable = () => this.tableRef?.current?.loadRowsAsync()

    onRowClicked = (row: RowType) => this.setState({returnToBaseResponseRow: row})
    onReturnToBaseSuccessful = () => {
        this.setState({returnToBaseResponseRow: undefined}, () => {
            this._reloadTable()
        })
    }

    render() {
        return (
            <>
                <RemoteSearchTable
                    toastManager={this.props.toastManager}
                    ref={this.tableRef}
                    searchPlaceholder={'Название материала, сотрудник или выданное количество'}
                    rowKeyExtractor={(u: RowType) => u.materialId.toString()}
                    renderTableCells={this._renderCells}
                    filterRows={this.props.filterRows ? this.props.filterRows : undefined}
                    headerCells={HEADER_CELLS}
                    loadRows={this.loadRows}
                    onRowSelected={this.onRowClicked}/>
                {
                    this.state.returnToBaseResponseRow &&
                    <UpdateMaterialGivenCountForUserModal
                        distributionInfo={this.state.returnToBaseResponseRow}
                        onCancel={() => this.setState({returnToBaseResponseRow: undefined})}
                        onSaved={this.onReturnToBaseSuccessful}
                        toastManager={this.props.toastManager}/>
                }
            </>
        );
    }
}

export default AgitationMaterialsByUsersSearchTable
