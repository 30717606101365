import {UserRole} from "../models/enums";
import {UserSummaryInfoResponse} from "./ResponseModels";
import {ApiCampaignVerificationSettings} from "../models/ExternalAdditionalInfo";

export type APIUserInfo = {
    id: number
    name: string
    role: UserRole
    createdAt: string
    phoneNumber: string
    telegramNickname?: string
    whatsAppNickname?: string
    routesDirectionUpsideDown: boolean
}

export interface ApiUser {
    id: number,
    name: string,
    surname: string,
    birthday?: string,
    role: UserRole,
    createdAt: string,
    updatedAt: string,
    phoneNumber: string,
    telegramNickname?: string,
    whatsAppNickname?: string,
    routesDirectionUpsideDown: boolean,
    isActive: boolean,
    updatedBy?: UserSummaryInfoResponse,
    createdBy?: UserSummaryInfoResponse,
    login: string,
    password?: string
    totalPaid: number,
    latestPayments: ApiUserBriefPaymentInfo[]
}
export type APIUserPayment = {
    value: number
    createdAt: string
}
export interface ApiCommonSettingsMapSettings {
    latitude: number,
    longitude: number,
    zoom: number,
    mapToken: string
}
export interface ApiCommonSettings {
    mapSettings: ApiCommonSettingsMapSettings
    possibleAges: string[]
    allowMultiThematicsSelect: boolean
    scoutHQCommunicationInfo: string
    allowContactsCreationWithoutPhoneNumber: boolean
}
export interface ApiCallsSettings {
    customerName: string,
    appName: string,
    userName: string,
    password: string,
}
export type APIExternalAdditionalInfo = {
    userInfo: APIUserInfo,
    thematics: string[]
    payments: APIUserPayment[]
    commonSettings: ApiCommonSettings
    callsSettings?: ApiCallsSettings
    verificationScenarioId?: number
    verificationSettings: ApiCampaignVerificationSettings
}

export type APIAuthResponse = {
    token: string
}

export enum ContactGeoType {
    HOUSE_REQUEST_GEO = 'HouseRequestGeo'
}
export interface APICampaignPaymentSettings {
    paymentPerContact: number,
    shiftContactsStandard?: number
    shiftContactsStandardExceedBonus?: number
    successDaysStreakCountForBonus?: number
    successDaysStreakCountFixedBonus?: number
    useVerificationForPayments: boolean
    paymentPerAgitationDistribution?: number

}
export interface APICampaignSettings {
    thematics: string[]
    paymentSettings: APICampaignPaymentSettings
    disableDuplicateContacts: boolean
    updatedAt: string
}
export interface APICallShiftInfo {
    id: number
    scenarioId: number
    scenarioTitle: string
    createdBy: UserSummaryInfoResponse
    createdAt: string
    finishedAt?: string
}

export interface APIScenarioPossibleResult {
    title: string
    color: string
}

export interface ApiUserBriefPaymentInfo {
    id: number,
    value: number,
    comment?: string,
    createdBy: UserSummaryInfoResponse,
    createdAt: string,
}
