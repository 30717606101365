import {APIScenarioPossibleResult} from "../../api/Models";
import React, {useState} from "react";
import {Button} from "react-bootstrap-v5";
import Icon from "@iconify/react";
import x24 from "@iconify/icons-octicon/x-24";

interface PossibleResultsPanelProps {
    results: APIScenarioPossibleResult[],
    selected?: APIScenarioPossibleResult,
    onClick?: (r: APIScenarioPossibleResult) => void,
    onDeleteClicked?: (title: string) => void
}

export const PossibleResultsPanel = (props: PossibleResultsPanelProps) => {
    const ResultButton = (buttonProps: any) => {
        const [isHovered, setIsHovered] = useState(false)
        return (
            <Button key={buttonProps.index}
                    onClick={() => props.onClick && props.onClick(buttonProps.res)}
                    variant={'secondary'}
                    className={'me-2 mb-1 position-relative ' + (props.selected === buttonProps.res ? 'possible-result-button__selected' : '')}
                    onMouseLeave={() => setIsHovered(false)}
                    onMouseEnter={() => setIsHovered(true)}
                    style={{backgroundColor: buttonProps.res.color}}>
                {buttonProps.res.title}
                {
                    props.onDeleteClicked && isHovered &&
                    <div
                        onClick={() => props.onDeleteClicked ? props.onDeleteClicked(buttonProps.res.title) : {}}
                        className='possible-result-button__delete'>
                        <Icon icon={x24} width={16} color='black'/>
                    </div>
                }
            </Button>
        )
    }
    return (
        <div className='col-12'>
            {
                props.results.map((r, index) =>
                    <ResultButton res={r} index={index}/>
                )
            }
        </div>
    )
}
