import React from "react";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import Geocoder from "react-mapbox-gl-geocoder";
import search16 from "@iconify/icons-octicon/search-16";
import {Icon} from "@iconify/react";

export default class MapGeocoderSearchbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({
            isOpened: false
        })
    }

    render() {
        return (

            this.state.isOpened
                ? <Geocoder
                    viewport={this.props.viewport}
                    onSelected={this.props.onSelected}
                    mapRef={this.props.mapRef}
                    mapboxApiAccessToken={globalStore.getState().externalAdditionalInfo.commonSettings.mapSettings.mapToken}
                    position="top-right"
                />
                : <div className='mapboxgl-ctrl-group mapboxgl-ctrl d-flex justify-content-center align-items-center react-geocoder'
                       style={{width: 29, height: 29}}
                       onClick={() => this.setState({isOpened: true})}>
                    <Icon className='mapbox-gl-draw_ctrl-draw-btn' icon={search16} width={20}/>
                </div>


        )
    }
}
