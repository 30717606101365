import React, {RefObject} from "react";
import RemoteSearchTable, {SearchTableHeaderCell} from "../../components/search/RemoteSearchTable";
import {ApiElectorCommonTableParameters} from "../../../api/RequestModels";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import * as API from "../../../api/API";
import {ApiPaymentsResponseRow} from "../../../api/ResponseModels";
import {TableCell} from "@material-ui/core";
import {ToastConsumerContext} from "react-toast-notifications";


const HEADER_CELLS: SearchTableHeaderCell[] = [
    {
        id: 'id',
        disablePadding: false,
        label: 'id',
    },
    {
        id: 'address',
        disablePadding: false,
        label: 'Адрес',
    }
];

interface Props {
    onRowSelected?: (row: any) => void
    toastManager: ToastConsumerContext
}

interface State {
    addressesData: any[]
}

class GeoAddressSearchTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = ({
            addressesData: []
        })
    }
    tableRef: RefObject<RemoteSearchTable<any>> | undefined = React.createRef()

    async componentDidMount() {
        await this.loadRowsAsync()
    }

    loadRowsAsync = async () => {
        const data = await API.loadGeoInfo(globalStore.getState().authToken)
        this.setState({addressesData: data.features})
        this.tableRef?.current?.loadRowsAsync()
    }

    loadRows = async (params: ApiElectorCommonTableParameters) => {
        const filteredBySQ = this.state.addressesData.filter(d =>
            params.search ? d.properties.address.toLowerCase().includes(params.search.toLowerCase()) : true
        )
        return filteredBySQ.slice(params.offset, params.offset + params.limit)
    }

    _renderCells = (row: any) => {
        return (
            [
                <TableCell align='left'>{row.properties.houseId}</TableCell>,
                <TableCell align="left">{row.properties.address}</TableCell>
            ]
        )
    }


    render() {
        return (
                <RemoteSearchTable
                    toastManager={this.props.toastManager}
                    ref={this.tableRef}
                    onRowSelected={this.props.onRowSelected}
                    searchPlaceholder={'Адрес'}
                    rowKeyExtractor={(u: any) => u.properties.houseId.toString()}
                    renderTableCells={this._renderCells}
                    headerCells={HEADER_CELLS}
                    initialTableSettings={{rowsPerPage: 10}}
                    size={'small'}
                    loadRows={this.loadRows}/>
        );
    }
}

export default GeoAddressSearchTable
