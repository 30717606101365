import {applyMiddleware, createStore} from 'redux'
import thunk from "redux-thunk";
import {ReducerAction, UPDATE_AUTH_TOKEN, UPDATE_LOADED_DATA, UPDATE_USER_INFO} from "./types";
import {ExternalAdditionalInfo} from "../../models/ExternalAdditionalInfo";
import * as LocalStorage from '../LocalStorage'

export interface GlobalState {
    externalAdditionalInfo: ExternalAdditionalInfo
    authToken: string
}

const EMPTY_GLOBAL_STATE: GlobalState = {
    authToken: LocalStorage.getLocalToken()
} as GlobalState // should never be really used

function myReducer(state: GlobalState = EMPTY_GLOBAL_STATE, action: ReducerAction) {
    switch (action.type) {
        case UPDATE_LOADED_DATA:
            return {...state, externalAdditionalInfo: action.payload.info}
        case UPDATE_AUTH_TOKEN:
            if (action.payload) {
                LocalStorage.setLocalToken(action.payload)
            } else LocalStorage.setLocalToken(undefined)
            return {...state, authToken: action.payload}
        case UPDATE_USER_INFO:
            return {...state, externalAdditionalInfo: {...state.externalAdditionalInfo, user: action.payload}}
        default:
            return state;
    }
}

export const globalStore = createStore(
    myReducer,
    EMPTY_GLOBAL_STATE,
    applyMiddleware(thunk)
);

