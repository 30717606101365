import * as React from 'react'
import {GlobalState} from "../../utils/redux/ReduxUtils";
import {connect} from "react-redux";
import './ProfileScreen.css'
import ContentScreen from "../ContentScreen";
import {useHistory} from "react-router-dom";
import UserSearchTableNew from "./users/UserSearchTable";
import {ApiUser} from "../../api/Models";

interface Props {
}

const UsersScreen = (props: Props) => {
    const history = useHistory();
    const onRowClicked = (user: ApiUser) => history.push(`/user/${user.id}`)

    return (
        <ContentScreen>
            <div className='mx-auto w-100 mt-2 mb-2 d-flex gx-2 flex-column align-items-center'>
                <div className='row col-12'>
                    <div className="col-auto h4 fw-normal mb-4">
                        <svg className="icon icon-24 text-primary align-text-bottom me-2">
                            <use href="#person-24"/>
                        </svg>
                        Сотрудники
                    </div>
                    <div className="col-auto ms-auto mt-n1">
                        <button className="btn btn-outline-primary" onClick={() => history.push('/user/0')}>
                            <svg className="icon icon-16 me-1">
                                <use href="#plus-16"/>
                            </svg>
                            <svg className="icon icon-16 me-2">
                                <use href="#person-24"/>
                            </svg>
                            Новый сотрудник
                        </button>
                    </div>
                </div>
                <div className='col-12'>
                    <UserSearchTableNew onRowClicked={onRowClicked}/>
                </div>
            </div>
        </ContentScreen>

    )
}

function mapStateToProps(state: GlobalState): Props {
    return {}
}


export default connect(
    mapStateToProps
)(UsersScreen);
