import * as React from 'react'
import ContentScreen from "../../ContentScreen";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import CallScriptEditModal from "./CallScriptEditModal";
import {CallScriptModel} from "../../../models/CallScriptModel";
import moment from "moment";
import ScriptsSearchTable from "./ScriptsSearchTable";
import {RefObject} from "react";

export const makeEmptyScript: () => CallScriptModel = () => ({
    id: 0,
    title: '',
    content: '',
    createdAt: moment(),
    updatedAt: moment(),
    isArchived: false
})

interface Props extends RouteComponentProps {
    toastManager: ToastConsumerContext
}

interface State {
    currentlyEditedScript?: CallScriptModel
}

class CallScriptsScreen extends React.Component<Props, State> {
    state = {
        currentlyEditedScript: undefined as CallScriptModel | undefined
    }
    scriptsSearchTableRef: RefObject<any> | undefined = undefined

    constructor(props: Props) {
        super(props);
        this.state = ({
            currentlyEditedScript: undefined as CallScriptModel | undefined
        })
        this.scriptsSearchTableRef = React.createRef()
    }

    openScriptEditModal = () => this.setState({currentlyEditedScript: makeEmptyScript()})

    render() {
        return (
            <ContentScreen>
                <div className='mx-auto w-100 mt-2 mb-2 d-flex gx-2 flex-column align-items-center'>
                    <div className='row col-12'>
                        <div className="col-auto h4 fw-normal mb-4">
                            <svg className="icon icon-24 text-primary align-text-bottom me-2">
                                <use href="#checklist-24"/>
                            </svg>
                            Скрипты звонков
                        </div>
                        <div className="col-auto ms-auto mt-n1">
                            <button className="btn btn-outline-primary"
                                    onClick={() => this.openScriptEditModal()}>
                                <svg className="icon icon-16 me-1">
                                    <use href="#plus-16"/>
                                </svg>
                                <svg className="icon icon-16 me-2">
                                    <use href="#checklist-16"/>
                                </svg>
                                Новый скрипт
                            </button>
                        </div>
                    </div>
                    <div className='col-7'>
                        <ScriptsSearchTable
                            ref={this.scriptsSearchTableRef}
                            onRowSelected={(row: CallScriptModel) => this.setState({currentlyEditedScript: row})}
                        />
                    </div>
                    {
                        this.state.currentlyEditedScript &&
                        <CallScriptEditModal
                            onSaved={() => {
                                this.setState({currentlyEditedScript: undefined})
                                this.props.toastManager.add('Скрипт успешно создан', {
                                    appearance: 'success',
                                });
                                this.scriptsSearchTableRef?.current?._reloadScriptsAsync()
                            }}
                            onCancel={() => this.setState({currentlyEditedScript: undefined})}
                            script={this.state.currentlyEditedScript}/>
                    }
                </div>
            </ContentScreen>

        )
    }

}

export default withToastManager(withRouter(CallScriptsScreen))
