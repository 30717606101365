import React, {PropsWithChildren, ReactNode} from "react";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import {
    Checkbox, IconButton,
    Paper, Size,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import * as _ from "lodash";
import {ApiElectorCommonTableParameters, ApiElectorCommonTableParametersSort} from "../../../api/RequestModels";
import {ApiSortCriteria, ApiSortDirection} from "../../../models/enums";
import {RemoteSearchTableHeader} from "./RemoteSearchTableHeader";
import AppSpinner from "../AppSpinner";
import {EnhancedTableToolbar} from "./RemoteSearchTableToolbar";
import classnames from "classnames";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {ruRU} from '@material-ui/core/locale';
import {COLORS} from "../../../utils/Consts";
import {RemoteSearchTableFilterModel} from "../../../models/RemoteSearchTableFilterModel";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const TABLE_THEME = createMuiTheme({
    palette: {
        primary: {
            main: COLORS.BLUE,
        },
        error: {
            main: COLORS.RED,
        },

    },
}, ruRU);

export interface SearchTableHeaderCell {
    id: string;
    label: string;
    disablePadding: boolean;
    sortCriteria?: ApiSortCriteria
}


interface State<T> {
    isLoading: boolean
    rows: T[]
    selectedRows: T[]
    searchQuery: string
    sortingOrder: ApiSortDirection
    sortCriteria?: ApiSortCriteria
    rowsPerPage: number
    currentPageNo: number
    currentlyAppliedFilters: RemoteSearchTableFilterModel[]
    filterValues: RemoteSearchTableFilterModel[]
    expandedRowIds: string[]
}
interface RemoteSearchTableInitialSettings {
    rowsPerPage?: number
}

interface Props<T> {
    toastManager: ToastConsumerContext
    title?: string
    onRowSelected?: (row: T) => void
    onMultipleRowsSelected?: (rows: T[]) => void
    applyClassToRow?: (rows: T) => string
    applyStyleToRow?: (rows: T) => React.CSSProperties
    loadRows: (params: ApiElectorCommonTableParameters) => Promise<T[]>
    filterRows?: (row: T) => boolean
    rowKeyExtractor: (row: T) => string
    renderTableCells: (row: T) => React.ReactNode[]
    headerCells: SearchTableHeaderCell[]
    searchPlaceholder: string
    size?: Size
    allFilters?: RemoteSearchTableFilterModel[]
    renderExpandedContent?: (row: T) => React.ReactNode[]
    additionalToolbarElements?: ReactNode
    initialTableSettings?: RemoteSearchTableInitialSettings
}

class RemoteSearchTable<T> extends React.Component<Props<T>, State<T>> {

    constructor(props: Props<T>) {
        super(props);
        this.state = ({
            isLoading: true,
            rows: [],
            selectedRows: [] as T[],
            searchQuery: '',
            sortingOrder: ApiSortDirection.DESC,
            sortCriteria: undefined,
            rowsPerPage: props?.initialTableSettings?.rowsPerPage || 25,
            currentPageNo: 0,
            currentlyAppliedFilters: [],
            filterValues: props.allFilters || [],
            expandedRowIds: []
        })
    }

    componentDidMount() {
        this.loadRowsAsync()
    }

    loadRowsAsync = () => {
        const retrieveRowsF = async () => {
            // TODO use filters
            const trimmedSearchQuery = this.getCurrentSearchQuery()
            let sortParam: ApiElectorCommonTableParametersSort | undefined = undefined
            if (this.state.sortCriteria) {
                sortParam = {
                    criteria: this.state.sortCriteria || ApiSortCriteria.FULL_ADDRESS,
                    direction: this.state.sortingOrder
                }
            }
            const params: ApiElectorCommonTableParameters = {
                limit: this.state.rowsPerPage,
                offset: this.state.currentPageNo * this.state.rowsPerPage,
                filters: this.state.currentlyAppliedFilters,
                sort: sortParam,
                search: trimmedSearchQuery
            }
            const responseRows = await this.props.loadRows(params)
            this.setState({
                rows: responseRows,
            })
        }
        this.setState({isLoading: true}, () => {
            retrieveRowsF().catch(e => {
                console.log(e)
                this.props.toastManager.add('Не удалось загрузить данные таблицы!', {
                    appearance: 'error',
                });
            }).finally(() => this.setState({isLoading: false}))
        })
    }

    _debouncedLoadContactsForQuery = _.debounce(() => {
        this.loadRowsAsync()
    }, 500)

    onSearchQueryChanged = (q: string) => {
        this.setState({searchQuery: q, currentPageNo: 0}, () => {
            this._debouncedLoadContactsForQuery()
        })
    }

    onRowsPerPageChanged = (s: string) => {
        const nextRowsPerPage = Number.parseInt(s, 10) || 0
        this.setState({rowsPerPage: nextRowsPerPage}, () => {
            this.loadRowsAsync()
        })
    }
    onPageNoChanged = (nextPageNo: number) => {
        if (this.state.rows.length === 0 && nextPageNo > this.state.currentPageNo) {
        } else {
            this.setState({currentPageNo: nextPageNo}, () => this.loadRowsAsync())
        }
    }
    onSubmitSelectedRowsClicked = () => {
        this.props.onMultipleRowsSelected &&
        this.props.onMultipleRowsSelected(this.state.selectedRows)
    }
    changeFilterValue = (value: any, idx: number) => {
        const newFilters = [...this.state.filterValues]
        newFilters[idx].value = value
        this.setState({filterValues: newFilters})
    }
    applyAllNonEmptyFilters = () => {
        this.setState({currentlyAppliedFilters: this.state.filterValues.filter(f => !_.isEqual(f.value, f.defaultValue))}, () => {
            this.loadRowsAsync()
        })
    }

    resetFiltersPanelToPrevious = () => {
        const newFilters = this.props.allFilters?.map(af => this.state.currentlyAppliedFilters.find(app => app.type === af.type) || af) || []
        this.setState({filterValues: newFilters})
    }
    getCurrentlyAppliedFilters = () => this.state.currentlyAppliedFilters
    getCurrentSearchQuery = () => {
        const trimmedSearchQuery = this.state.searchQuery.trim()
        return trimmedSearchQuery ? trimmedSearchQuery : undefined
    }

    render() {
        const handleRequestSort = (property: ApiSortCriteria) => {
            const isAsc = this.state.sortCriteria === property && this.state.sortingOrder === ApiSortDirection.ASC;
            const isDescAndSameCriteria = this.state.sortCriteria === property && this.state.sortingOrder === ApiSortDirection.DESC
            this.setState({
                sortingOrder: isAsc ? ApiSortDirection.DESC : ApiSortDirection.ASC,
                sortCriteria: isDescAndSameCriteria ? undefined : property // reset if clicked on same three times
            }, () => this.loadRowsAsync())
        };
        const hasMultipleRowsSelection = Boolean(this.props.onMultipleRowsSelected)
        const selectedRowsIds = this.state.selectedRows.map(this.props.rowKeyExtractor) //for performance

        const handleRowClick = (row: T) => {
            if (!hasMultipleRowsSelection) {
                this.props.onRowSelected && this.props.onRowSelected(row)
            } else {
                const rowId = this.props.rowKeyExtractor(row)
                const selectedIndex = selectedRowsIds.indexOf(rowId);
                let newSelected: T[] = [];
                if (selectedIndex === -1) {
                    newSelected = newSelected.concat(this.state.selectedRows, row);
                } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(this.state.selectedRows.slice(1));
                } else if (selectedIndex === this.state.selectedRows.length - 1) {
                    newSelected = newSelected.concat(this.state.selectedRows.slice(0, -1));
                } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(
                        this.state.selectedRows.slice(0, selectedIndex),
                        this.state.selectedRows.slice(selectedIndex + 1),
                    );
                }
                this.setState({selectedRows: newSelected})
            }
        }
        const shownRows = this.props.filterRows ? this.state.rows.filter(this.props.filterRows) : this.state.rows

        const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                const newRows = Array.from(new Set(this.state.selectedRows.concat(shownRows)))
                this.setState({selectedRows: newRows})
            } else {
                this.setState({selectedRows: []})
            }
        };

        const hasClickableRows = Boolean(this.props.onRowSelected)
        return (
            <>
                {
                    this.state.isLoading &&
                    <div className='app-spinner-fixed'>
                        <AppSpinner/>
                    </div>
                }
                <div>
                    <div className="col-auto col-sm ms-auto mt-n2">
                        <div className="input-group my-1">
                            <span className="input-group-text">Поиск</span>
                            <input type="text" className="form-control"
                                   value={this.state.searchQuery}
                                   onChange={e => this.onSearchQueryChanged(e.target.value)}
                                   placeholder={this.props.searchPlaceholder}/>
                        </div>
                    </div>
                    <ThemeProvider theme={TABLE_THEME}>
                        <TableContainer component={Paper}>
                            {
                                (Boolean(this.props.title) || this.state.filterValues.length > 0 || this.state.selectedRows.length > 0
                                    || Boolean(this.props.additionalToolbarElements)) &&
                                <EnhancedTableToolbar
                                    title={this.props.title}
                                    appliedFiltersCount={this.state.currentlyAppliedFilters?.length || 0}
                                    onApplyFiltersPressed={this.applyAllNonEmptyFilters}
                                    onHideFiltersPanelPressed={this.resetFiltersPanelToPrevious}
                                    onFilterValueChanged={this.changeFilterValue}
                                    onCheckPressed={this.onSubmitSelectedRowsClicked}
                                    allFilters={this.state.filterValues || []}
                                    numSelected={this.state.selectedRows.length}
                                    additionalToolbarElements={this.props.additionalToolbarElements}/>
                            }
                            <Table size={this.props.size}>
                                <RemoteSearchTableHeader
                                    numSelected={this.state.selectedRows.length}
                                    sortingOrder={this.state.sortingOrder}
                                    orderBy={this.state.sortCriteria}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={this.state.rows.length}
                                    hasMultipleRowSelection={hasMultipleRowsSelection}
                                    size={this.props.size || 'medium'}
                                    cells={this.props.headerCells}
                                    hasRowExpansion={Boolean(this.props.renderExpandedContent)}/>
                                <TableBody>
                                    {shownRows.map((row) => {
                                        const rowId = this.props.rowKeyExtractor(row)
                                        const isItemSelected = selectedRowsIds.includes(rowId)
                                        const isRowExpanded = this.state.expandedRowIds.includes(rowId)
                                        const toggleRowExpanded = () => {
                                            const newExpandedRowIds = isRowExpanded
                                                ? this.state.expandedRowIds.filter(id => id !== rowId)
                                                : this.state.expandedRowIds.concat([rowId])

                                            this.setState({expandedRowIds: newExpandedRowIds})
                                        }
                                        return (
                                            <>
                                                <TableRow key={this.props.rowKeyExtractor(row)}
                                                          className={classnames(hasClickableRows ? 'cursor-pointer' : '', this.props.applyClassToRow ? this.props.applyClassToRow(row) : '')}
                                                          style={this.props.applyStyleToRow ? this.props.applyStyleToRow(row) : undefined}
                                                          hover
                                                          selected={isItemSelected}
                                                          onClick={() => handleRowClick(row)}>
                                                    {
                                                        hasMultipleRowsSelection &&
                                                        <TableCell align='left' component="th"
                                                                   scope="row"
                                                                   padding="none">
                                                            <div className={'d-flex flex-row align-items-center'}>
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                    }
                                                    {
                                                        Boolean(this.props.renderExpandedContent) &&
                                                        <TableCell padding="checkbox">
                                                            <IconButton onClick={toggleRowExpanded}>
                                                                {isRowExpanded ? <KeyboardArrowUpIcon/> :
                                                                    <KeyboardArrowDownIcon/>}
                                                            </IconButton>
                                                        </TableCell>
                                                    }
                                                    {
                                                        this.props.renderTableCells(row)
                                                    }
                                                </TableRow>
                                                {
                                                    this.props.renderExpandedContent && isRowExpanded &&
                                                    this.props.renderExpandedContent(row)
                                                }
                                            </>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                nextIconButtonProps={{disabled: this.state.rows.length < this.state.rowsPerPage}}
                                component="span"
                                count={10000}
                                size={'medium'}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.currentPageNo}
                                onChangePage={(e, newPageNo) => this.onPageNoChanged(newPageNo)}
                                onChangeRowsPerPage={(e) => this.onRowsPerPageChanged(e.target.value)}
                            />
                        </TableContainer>
                    </ThemeProvider>
                </div>
                {
                    !this.state.isLoading && this.state.rows.length === 0 &&
                    <div className='text-center mt-3'>
                        Подходящие данные не найдены!
                    </div>
                }
            </>
        );
    }
}

export default RemoteSearchTable
