import React, {PropsWithChildren, RefObject} from "react";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import RemoteSearchTable, {SearchTableHeaderCell} from "../../components/search/RemoteSearchTable";
import {ApiElectorCommonTableParameters} from "../../../api/RequestModels";
import {RouteComponentProps} from "react-router";
import {ApiSortCriteria} from "../../../models/enums";
import {Badge, Size, TableCell} from "@material-ui/core";
import * as TypedUtils from "../../../utils/TypedUtils";
import {commonDateTimeFormat} from "../../../utils/Formatters";
import moment from "moment";
import {
    ApiHqContactsImportResponse,
    ApiHqPhoneCallsImportResponse,
    ApiPhoneCallsResponseRow
} from "../../../api/ResponseModels";
import {RemoteSearchTableFilterModel} from "../../../models/RemoteSearchTableFilterModel";
import {ApiIsArchivedFilterValues, SearchTableFilterType} from "../../../models/ApiFilterEnums";
import {EMPTY_FILTER_INFO} from "../../components/search/filters/DateFromToSearchTableFilter";
import CommentIcon from "@material-ui/icons/Comment";
import * as RoleUtils from "../../../utils/RoleUtils";
import RemoteSearchTableSettingsToolbarButton from "../../components/search/RemoteSearchTableSettingsToolbarButton";
import ContactCSVImportModal from "../contacts/ContactCSVImportModal";
import PhoneCallsCSVImportModal from "./PhoneCallsCSVImportModal";

type TableRowType = ApiPhoneCallsResponseRow

const HEADER_CELLS: SearchTableHeaderCell[] = [
    {id: 'fullName', disablePadding: false, label: 'Контакт'},
    {
        id: ApiSortCriteria.FULL_ADDRESS,
        disablePadding: false,
        label: 'Адрес контакта',
        sortCriteria: ApiSortCriteria.FULL_ADDRESS
    },
    {
        id: ApiSortCriteria.PHONE_NUMBER,
        disablePadding: false,
        label: 'Телефон',
        sortCriteria: ApiSortCriteria.PHONE_NUMBER
    },
    {
        id: ApiSortCriteria.THEMATICS,
        disablePadding: false,
        label: 'Проблематика',
        sortCriteria: ApiSortCriteria.THEMATICS
    },
    {
        id: 'createdBy',
        disablePadding: false,
        label: 'Автор',
    },
    {
        id: ApiSortCriteria.CALL_RESULT,
        disablePadding: false,
        label: 'Результат',
        sortCriteria: ApiSortCriteria.CALL_RESULT
    },
    {
        id: ApiSortCriteria.IS_ARCHIVED,
        disablePadding: false,
        label: 'Статус',
        sortCriteria: ApiSortCriteria.IS_ARCHIVED
    },
    {id: ApiSortCriteria.CREATED_AT, disablePadding: false, label: 'Создан', sortCriteria: ApiSortCriteria.CREATED_AT},
];
const ALL_FILTERS: RemoteSearchTableFilterModel[] = [
    {
        type: SearchTableFilterType.IS_ARCHIVED,
        value: ApiIsArchivedFilterValues.NOT_ARCHIVED,
        defaultValue: ApiIsArchivedFilterValues.NOT_ARCHIVED
    },
    {
        type: SearchTableFilterType.HOUSE_ID,
        value: undefined,
        defaultValue: undefined
    },
    {
        type: SearchTableFilterType.DATE_FROM_TO,
        value: EMPTY_FILTER_INFO,
        defaultValue: EMPTY_FILTER_INFO
    },
    {
        type: SearchTableFilterType.CREATED_BY,
        value: undefined,
        defaultValue: undefined,
        customTitle: 'Создал контакт'
    },
    {
        type: SearchTableFilterType.CALL_CREATED_BY,
        value: undefined,
        defaultValue: undefined,
    },
    {
        type: SearchTableFilterType.VERIFICATION_RESULT,
        value: undefined,
        defaultValue: undefined,
        showNoCallsOption: false
    }
]

interface Props {
    onRowClicked: (row: TableRowType) => void
    toastManager: ToastConsumerContext
}

interface State {
    showImportModal: boolean
}

class PhoneCallsSearchTable extends React.Component<Props, State> {
    tableRef: RefObject<RemoteSearchTable<TableRowType>> | undefined = undefined

    constructor(props: Props) {
        super(props);
        this.state = ({
            showImportModal: false
        })
        this.tableRef = React.createRef()
    }

    loadRows = async (params: ApiElectorCommonTableParameters) => {
        const response = await API.getAllPhoneCalls(params, globalStore.getState().authToken)
        return response.rows
    }

    onImportSuccessful = (r: ApiHqPhoneCallsImportResponse, notFullyImported: boolean) => {
        if (!notFullyImported) this.setState({showImportModal: false})

        let message = `Импорт успешно осуществлён. Создано ${r.createdCallsCount} звонков`
        this.props.toastManager.add(message, {
            appearance: notFullyImported? 'warning' : 'success',
            autoDismiss: false
        });
        this.tableRef?.current?.loadRowsAsync()
    }

    _renderCells = (row: TableRowType) => {
        return (
            [
                <TableCell align='left'>
                    <a
                        className={'col-auto'}
                        onClick={e => e.stopPropagation()}
                        target={'_blank'}
                        href={`/contact/${row.contact.id}`}>{row.contact.fullName}</a>
                </TableCell>,
                <TableCell
                    align="left">{row.contact.fullAddress ? TypedUtils.makeFullAddressWithGeo(row.contact.fullAddress, row.contact.geo) : ''}</TableCell>,
                <TableCell
                    align="left">{row.contact.phoneNumber ? TypedUtils.renderWhatsAppLink(row.contact.phoneNumber) : ''}</TableCell>,
                <TableCell align="left">{row.contact.thematics.join(', ')}</TableCell>,
                <TableCell
                    align="left">{TypedUtils.renderUserLink(row.createdBy.fullName, row.createdBy.id)}</TableCell>,
                <TableCell align="left">{row.resultAndColor.result}</TableCell>,
                <TableCell align="left">{TypedUtils.renderArchived(row.isArchived)}</TableCell>,
                <TableCell align="left">
                    <Badge
                        className="table-row__comment-badge dense"
                        badgeContent={
                            row.comment ? <CommentIcon style={{width: 10, height: 10}}/> : 0
                        }
                        color="secondary"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                        {commonDateTimeFormat(moment(row.createdAt))}
                    </Badge>
                </TableCell>,
            ]
        )
    }
    getCurrentFilterValues: () => RemoteSearchTableFilterModel[] = () => {
        return this.tableRef?.current?.getCurrentlyAppliedFilters() || []
    }
    getCurrentSearchQuery: () => string | undefined = () => {
        return this.tableRef?.current?.getCurrentSearchQuery()
    }

    onExportClicked = () => {
        const currentFilterValues = this.getCurrentFilterValues()
        API.exportPhoneCallsCSV(globalStore.getState().authToken, {
            limit: 25,
            offset: 0,
            filters: currentFilterValues,
            search: this.getCurrentSearchQuery()
        }).then(blob =>
            TypedUtils.saveContentAsCSVFile(blob, 'phoneCalls.csv')
        ).catch(e => {
            console.log(e)
            this.props.toastManager.add('Не удалось осуществить экспорт!', {
                appearance: 'error',
            });
        })
    }

    render() {
        return (
            <>
                <RemoteSearchTable
                    ref={this.tableRef}
                    toastManager={this.props.toastManager}
                    title={'Все звонки'}
                    size='small'
                    searchPlaceholder={'Автор звонка, ФИО контакта, телефон, проблематика или адрес'}
                    rowKeyExtractor={(u: TableRowType) => u.id.toString()}
                    applyClassToRow={(u: TableRowType) => u.isArchived ? 'table-row__archived' : ''}
                    applyStyleToRow={(u: TableRowType) =>
                        u.resultAndColor.color ? {backgroundColor: TypedUtils.convertHexToRGBA(u.resultAndColor.color, 10)} : {}
                    }
                    renderTableCells={this._renderCells}
                    headerCells={HEADER_CELLS}
                    loadRows={this.loadRows}
                    allFilters={ALL_FILTERS}
                    onRowSelected={this.props.onRowClicked}
                    additionalToolbarElements={
                        RoleUtils.hasPhoneCallManagementPermission(globalStore.getState().externalAdditionalInfo.user.role)
                            ? <RemoteSearchTableSettingsToolbarButton
                                items={[
                                    {title: 'Экспортировать таблицу', onClick: this.onExportClicked},
                                    {
                                        title: 'Импортировать из Mango Office',
                                        onClick: () => this.setState({showImportModal: true})
                                    }
                                ]}
                            />
                            : undefined
                    }
                />
                {
                    this.state.showImportModal &&
                    <PhoneCallsCSVImportModal
                        toastManager={this.props.toastManager}
                        onCancel={() => this.setState({showImportModal: false})}
                        onSuccess={this.onImportSuccessful}/>
                }
            </>
        );
    }

}

export default PhoneCallsSearchTable
