import React from "react";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import {ApiUser} from "../../../api/Models";
import RemoteSearchTable, {SearchTableHeaderCell} from "../../components/search/RemoteSearchTable";
import {ApiElectorCommonTableParameters} from "../../../api/RequestModels";
import {RouteComponentProps} from "react-router";
import {ApiSortCriteria} from "../../../models/enums";
import {Size, TableCell} from "@material-ui/core";
import * as TypedUtils from "../../../utils/TypedUtils";
import {commonDateTimeFormat} from "../../../utils/Formatters";
import moment from "moment";
import {RemoteSearchTableFilterModel} from "../../../models/RemoteSearchTableFilterModel";
import {ApiIsArchivedFilterValues, SearchTableFilterType} from "../../../models/ApiFilterEnums";

const ALL_FILTERS: RemoteSearchTableFilterModel[] = [
    {
        type: SearchTableFilterType.IS_ARCHIVED,
        value: ApiIsArchivedFilterValues.NOT_ARCHIVED,
        defaultValue: ApiIsArchivedFilterValues.NOT_ARCHIVED
    },
]

const HEADER_CELLS: SearchTableHeaderCell[] = [
    {id: 'fullName', disablePadding: false, label: 'ФИО сотрудника'},
    {id: ApiSortCriteria.USER_ROLE, disablePadding: false, label: 'Должность', sortCriteria: ApiSortCriteria.USER_ROLE},
    {id: ApiSortCriteria.USER_LOGIN, disablePadding: false, label: 'Логин', sortCriteria: ApiSortCriteria.USER_LOGIN},
    {id: ApiSortCriteria.USER_PHONE, disablePadding: false, label: 'Телефон', sortCriteria: ApiSortCriteria.USER_PHONE},
    {
        id: ApiSortCriteria.USER_TELEGRAM,
        disablePadding: false,
        label: 'Телеграм',
        sortCriteria: ApiSortCriteria.USER_TELEGRAM
    },
    {id: ApiSortCriteria.CREATED_AT, disablePadding: false, label: 'Создан', sortCriteria: ApiSortCriteria.CREATED_AT},
    {
        id: ApiSortCriteria.IS_ARCHIVED,
        disablePadding: false,
        label: 'Статус',
        sortCriteria: ApiSortCriteria.IS_ARCHIVED
    },
];


interface Props extends RouteComponentProps {
    onRowClicked: (row: ApiUser) => void
    onMultipleRowsSelected?: (rows: ApiUser[]) => void
    filterRows?: (row: ApiUser) => boolean
    size?: Size
    toastManager: ToastConsumerContext
}

class UserSearchTable extends React.Component<Props, {}> {
    loadRows = async (params: ApiElectorCommonTableParameters) => {
        const users = await API.getUsers(params, globalStore.getState().authToken)
        return users.rows
    }

    _renderCells = (row: ApiUser) => {
        return (
            [
                <TableCell align='left'>{`${row.name} ${row.surname}`}</TableCell>,
                <TableCell align='left'>{TypedUtils.getUserRoleDisplayName(row.role)}</TableCell>,
                <TableCell align='left'>{row.login}</TableCell>,
                <TableCell align='left'>{TypedUtils.renderWhatsAppLink(row.phoneNumber)}</TableCell>,
                <TableCell
                    align='left'>{row.telegramNickname ? TypedUtils.renderTelegramLink(row.telegramNickname) : ''}</TableCell>,
                <TableCell align="left">{commonDateTimeFormat(moment(row.createdAt))}</TableCell>,
                <TableCell align="left">{TypedUtils.renderArchived(!row.isActive)}</TableCell>
            ]
        )
    }

    render() {
        return (
            <>
                <RemoteSearchTable
                    toastManager={this.props.toastManager}
                    size={this.props.size}
                    searchPlaceholder={'ФИО, телефон, телеграм или логин'}
                    rowKeyExtractor={(u: ApiUser) => u.id.toString()}
                    renderTableCells={this._renderCells}
                    applyClassToRow={(u: ApiUser) => u.isActive ? '' : 'table-row__archived'}
                    filterRows={this.props.filterRows ? this.props.filterRows : undefined}
                    headerCells={HEADER_CELLS}
                    loadRows={this.loadRows}
                    allFilters={ALL_FILTERS}
                    onMultipleRowsSelected={this.props.onMultipleRowsSelected}
                    onRowSelected={this.props.onRowClicked}/>
            </>
        );
    }
}

export default withToastManager(UserSearchTable)
