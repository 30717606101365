import React, {useState} from "react";
import Icon from "@iconify/react";
import plus24 from '@iconify/icons-octicon/plus-24';
import {COLORS} from "../../../utils/Consts";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import ContentScreen from "../../ContentScreen";
import {RouteComponentProps} from "react-router";
import {APIScenarioPossibleResult, ApiUser} from "../../../api/Models";
import * as _ from 'lodash'
import {ColorPickerElement} from "../../components/ColorPickerElement";
import {PossibleResultsPanel} from "../../components/PossibleResultsPanel";
import {CancellableButton} from "../../components/CancellableButton";
import * as RoleUtils from "../../../utils/RoleUtils";
import {CallScriptModel} from "../../../models/CallScriptModel";
import ScriptsSearchTable from "./ScriptsSearchTable";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {UserSummaryInfo} from "../../../models/UserModel";
import UserSearchTableNew from "../users/UserSearchTable";
import ContactSearchTableNew from "../contacts/ContactSearchTable";
import {ApiContactBriefInfoResponse} from "../../../api/ResponseModels";
import archive24 from "@iconify/icons-octicon/archive-24";

interface UserAndContactsPanelProps {
    onDeleteClicked: (row: UserSummaryInfo) => void
    rows: UserAndContacts[]
}

const UserAndContactsPanel = (props: UserAndContactsPanelProps) => {
    return (
        <div className='col-12 mb-1'>
            {
                props.rows.map((r, index) =>
                    <CancellableButton
                        row={r.user}
                        index={index}
                        className='scenario-edit__selected_user'
                        onCancelClicked={props.onDeleteClicked}
                        title={`${r.user.fullName}` + (r.contacts.length > 0 ? `. Контактов: ${r.contacts.length}` : '')}/>
                )
            }
        </div>
    )
}


const DEFAULT_POSSIBLE_RESULTS = [
    {
        title: 'Не дозвонились',
        color: COLORS.RED
    },
    {
        title: 'Неправильный номер',
        color: COLORS.ORANGE
    },
    {
        title: 'Сторонник',
        color: COLORS.GREEN
    },
    {
        title: 'Скорее сторонник',
        color: COLORS.INDIGO
    },
]

type INNER_TABLE_MODE = 'NONE' | 'USERS' | 'CONTACTS' | 'SCRIPTS'

interface UserAndContacts {
    user: UserSummaryInfo
    contacts: number[]
}

interface State {
    scenarioName: string
    possibleResultsWithColors: APIScenarioPossibleResult[]
    currentlyEditedPossibleResult: APIScenarioPossibleResult,
    usersAndContacts: UserAndContacts[]
    innerTableMode: INNER_TABLE_MODE
    script?: CallScriptModel
    isArchived: boolean
    error: string
}

interface Props extends RouteComponentProps {
    toastManager: ToastConsumerContext
}

const EMPTY_POSSIBLE_RESULT: APIScenarioPossibleResult = {
    title: '',
    color: COLORS.GREY400
}

class CallScenarioEditScreen extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            scenarioName: '',
            script: undefined,
            possibleResultsWithColors: DEFAULT_POSSIBLE_RESULTS,
            currentlyEditedPossibleResult: EMPTY_POSSIBLE_RESULT,
            usersAndContacts: [],
            innerTableMode: 'NONE',
            isArchived: false,
            error: ''
        }
    }

    componentDidMount() {
        const scenarioId = this._getScenarioId()
        const token = globalStore.getState().authToken
        const loadScenarioAsync = async () => {
            const scenarioResponse = await API.getScenarioById(scenarioId, token)
            const script = await API.getScriptById(scenarioResponse.scriptId, token)
            this.setState({
                script: script,
                scenarioName: scenarioResponse.title,
                possibleResultsWithColors: scenarioResponse.possibleResults,
                usersAndContacts: scenarioResponse.usersAndContacts,
                isArchived: scenarioResponse.isArchived
            })
        }
        if (scenarioId !== 0) {
            loadScenarioAsync().catch(e => {
                console.log("unable to load scenario by id", e)
                this.props.toastManager.add('Не удалось загрузить сценарий!', {
                    appearance: 'error',
                });
            })
        }
    }

    _getScenarioId: () => number = () => {
        try {
            //@ts-ignore
            return Number.parseInt(this.props.match.params.id, 10)
        } catch (e) {
            console.log('unable to parse scenario id number', e)
            return 0
        }
    }

    setScenarioName = (newValue: string) => this.setState({scenarioName: newValue})
    saveScenario = () => {
        const saveScenarioF = async () => {
            await API.saveCallScenario({
                id: this._getScenarioId(),
                title: this.state.scenarioName,
                scriptId: this.state.script?.id || 0,
                usersAndContacts: this.state.usersAndContacts.map(uAndC => ({
                    user: uAndC.user.id,
                    contacts: uAndC.contacts
                })),
                possibleResults: this.state.possibleResultsWithColors,
                isArchived: this.state.isArchived
            }, globalStore.getState().authToken)

            this.props.toastManager.add('Сценарий успешно сохранён!', {
                appearance: 'success',
            });
            this.props.history.goBack()
        }
        saveScenarioF().catch(err => {
            console.log(err)
            this.props.toastManager.add('Не удалось сохранить сценарий!', {
                appearance: 'error',
            });
        })
    }
    onAddPossibleResultPressed = () => {
        const trimmedInputText = this.state.currentlyEditedPossibleResult.title.trim()
        const nextResultWithTrimmedText = {...this.state.currentlyEditedPossibleResult, ...{title: trimmedInputText}}
        const maybeExistingResultWithThisTitle = this.state.possibleResultsWithColors.find(p => p.title === trimmedInputText)
        if (maybeExistingResultWithThisTitle) {
            this.setState({error: 'Возможный результат с таким названием уже существует!'})
        } else if (trimmedInputText) {
            this.setState({
                currentlyEditedPossibleResult: EMPTY_POSSIBLE_RESULT,
                possibleResultsWithColors: this.state.possibleResultsWithColors.concat([nextResultWithTrimmedText])
            })
        }
    }
    deletePossibleResult = (title: string) => {
        this.setState({
            possibleResultsWithColors: this.state.possibleResultsWithColors.filter(r => r.title !== title)
        })
    }

    onCurrentlyEditedPossibleResultTitleChanged = (title: string) => {
        let pr = {...this.state.currentlyEditedPossibleResult}
        pr.title = title
        this.setState({currentlyEditedPossibleResult: pr})
    }
    onCurrentlyEditedPossibleResultColorChanged = (color: string) => {
        let pr = {...this.state.currentlyEditedPossibleResult}
        pr.color = color
        this.setState({currentlyEditedPossibleResult: pr})
    }
    onAddEmployeeClicked = () => {
        if (this.state.innerTableMode === 'NONE') {
            this.setState({innerTableMode: 'USERS'})
        }
    }
    onAddScriptClicked = () => {
        this.setState({innerTableMode: 'SCRIPTS'})
    }
    onUserSelected = (user: ApiUser) => {
        this.setState({
            innerTableMode: 'CONTACTS',
            usersAndContacts: this.state.usersAndContacts.concat([{
                user: {
                    id: user.id,
                    fullName: `${user.name} ${user.surname}`,
                    role: user.role
                }, contacts: []
            }])
        })
    }
    onContactsForEmployeePicked = (contacts: ApiContactBriefInfoResponse[]) => {
        const contactIds = contacts.map(c => c.id)
        if (contactIds.length === 0) {
            this.setState({error: 'Невозможно назначить сотрудника без списка контактов!'})
        } else {
            const currentUser = _.last(this.state.usersAndContacts)
            if (currentUser) {
                const allButLast = _.initial(this.state.usersAndContacts)
                this.setState({usersAndContacts: allButLast.concat([{user: currentUser.user, contacts: contactIds}])})
            }
        }
        this.setState({innerTableMode: 'NONE'})
    }
    onDeleteEmployeeClicked = (user: UserSummaryInfo) => {
        this.setState({
            usersAndContacts: this.state.usersAndContacts.filter(uc => uc.user.id !== user.id),
            innerTableMode: "NONE"
        })
    }

    render() {
        const scenarioId = this._getScenarioId()
        const allCurrentlySelectedContactIds = this.state.usersAndContacts.flatMap(uc => uc.contacts)
        const IS_VERIFICATION_SCENARIO = scenarioId === globalStore.getState().externalAdditionalInfo.verificationScenarioId
        return (
            <ContentScreen>
                <main id="call-scenario-editor" className="container-fluid"
                      style={{minHeight: '100vh', maxWidth: 1000}}>
                    <div className="shadow-lg mb-4">
                        <div
                            className="bg-dark text-white text-center fw-500 py-3 d-flex flex-row justify-content-between align-items-center">
                            <span className='ms-5'>
                                {scenarioId === 0 ? 'Новый сценарий звонков' : `Сценарий звонков `}
                                {scenarioId !== 0 &&
                                <span className="font-monospace fw-bold text-red ms-1">{scenarioId}</span>
                                }
                            </span>
                        </div>
                        <div className="bg-white text-center sticky-top m-auto border-top p-4 col-12 col-md-8">
                            <div className="row gx-4">
                                <div className="col-6">
                                    <button id="cancel"
                                            onClick={this.props.history.goBack}
                                            className="btn btn-danger w-100 fw-500">Отмена
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button id="save" onClick={this.saveScenario}
                                            className="btn btn-success w-100 fw-500">Сохранить
                                    </button>
                                </div>
                                {
                                    this.state.error && <div
                                        className='col-12 border-top font-monospace mt-1 pt-1 fw-bold text-red text-center'>{this.state.error}</div>
                                }
                            </div>
                        </div>
                        <div className="px-4 col-12 col-md-8 m-auto">
                            {
                                IS_VERIFICATION_SCENARIO && <div
                                    className='col-12 border-bottom pb-2 mb-2 font-monospace fw-bold text-orange text-center'>
                                    Это сценарий, использующийся для верификации контактов.<br/>
                                    При соответствующей включённой настройке кампании, при расчёте выплат скаутам будут учитываться только звонки со статусом "Верифицирован"<br/>
                                    <span className='text-green'>Для него вы можете выбрать сотрудников и контакты, изменить текст скрипта</span>
                                </div>
                            }
                            <div className='row'>
                                <label htmlFor="scenarioName" className="col-form-label col-6 form-label-required">1.
                                    Название сценария
                                </label>
                                <div className="col-12 mb-3">
                                    <input id="scenarioName" className="form-control"
                                           value={this.state.scenarioName || ''}
                                           placeholder="..."
                                           readOnly={IS_VERIFICATION_SCENARIO}
                                           type="text" onChange={e => this.setScenarioName(e.target.value)}/>
                                </div>
                            </div>
                            <label className="col-form-label col-12 col-md-8 form-label-required mt-2">
                                2. Выберите скрипт
                            </label>
                            {
                                this.state.innerTableMode === 'NONE' && !this.state.script &&
                                <div className='d-flex flex-row align-items-center cursor-pointer'
                                     onClick={this.onAddScriptClicked}
                                >
                                    <button className="btn border-0 p-2">
                                        <Icon width={22} icon={plus24}/>
                                    </button>
                                    <div className="text-muted me-2">Скрипт</div>
                                </div>
                            }
                            {
                                this.state.script &&
                                <div className={'col-6'}>
                                    <CancellableButton
                                        className={'scenario-edit__selected_user'}
                                        index={1}
                                        title={this.state.script.title}
                                        row={this.state.script}
                                        onCancelClicked={!IS_VERIFICATION_SCENARIO? () => this.setState({script: undefined}): undefined}/>
                                </div>

                            }
                            {
                                this.state.innerTableMode === 'SCRIPTS' &&
                                <div className='row pb-4 px-4 col-12 m-auto app-spinner-inner'>
                                    <ScriptsSearchTable
                                        size={'small'}
                                        onRowSelected={(script: CallScriptModel) => this.setState({
                                            script: script,
                                            innerTableMode: "NONE"
                                        })}/>
                                </div>

                            }
                            <label className="col-form-label col-12 col-md-8 form-label-required mt-2">3. Добавьте
                                сотрудников
                                и контакты</label>
                            {
                                this.state.usersAndContacts &&
                                <UserAndContactsPanel rows={this.state.usersAndContacts}
                                                      onDeleteClicked={this.onDeleteEmployeeClicked}/>
                            }
                            {
                                this.state.innerTableMode === 'NONE' &&
                                <div className='d-flex flex-row align-items-center cursor-pointer'
                                     onClick={this.onAddEmployeeClicked}
                                >
                                    <button className="btn border-0 p-2">
                                        <Icon width={22} icon={plus24}/>
                                    </button>
                                    <div className="text-muted me-2">Сотрудник</div>
                                </div>
                            }
                        </div>
                        <div className='row pb-4 px-4 col-12 m-auto app-spinner-inner'>
                            {
                                this.state.innerTableMode === 'USERS' &&
                                <UserSearchTableNew
                                    size={'small'}
                                    filterRows={(r: ApiUser) => RoleUtils.hasMakeCallPermission(r.role) &&
                                        !this.state.usersAndContacts.flatMap(r => r.user.id).includes(r.id)}
                                    onRowClicked={this.onUserSelected}/>
                            }
                            {
                                this.state.innerTableMode === 'CONTACTS' &&
                                <ContactSearchTableNew
                                    isForCalls={true}
                                    toastManager={this.props.toastManager}
                                    filterRows={(r: ApiContactBriefInfoResponse) => !allCurrentlySelectedContactIds.includes(r.id)}
                                    size={'small'}
                                    onMultipleRowsSelected={this.onContactsForEmployeePicked}/>
                            }
                        </div>
                        <div className="pt-2 pb-2 px-4 col-12 col-md-8 m-auto">
                            <label htmlFor="possibleResults" className="col-form-label col-6 form-label-required">
                                4. Возможные результаты</label>
                            {
                                !IS_VERIFICATION_SCENARIO &&
                                <div className="d-flex align-items-center justify-content-between me-2 mb-3"
                                     style={{cursor: 'pointer'}}>
                                    <div className='col-5'>
                                        <input id="scriptPossibleResult" className="form-control"
                                               value={this.state.currentlyEditedPossibleResult.title}
                                               onKeyDown={e => e.key === 'Enter' ? this.onAddPossibleResultPressed() : {}}
                                               placeholder=""
                                               type="text"
                                               onChange={e => this.onCurrentlyEditedPossibleResultTitleChanged(e.target.value)}/>
                                    </div>
                                    <div className='d-flex flex-row align-items-center'
                                         onClick={this.onAddPossibleResultPressed}
                                    >
                                        <button className="btn border-0 p-2">
                                            <Icon width={22} icon={plus24}/>
                                        </button>
                                        <div className="text-muted me-2">Возможный результат</div>
                                    </div>
                                    <ColorPickerElement
                                        className={'col-auto'}
                                        onColorChanged={this.onCurrentlyEditedPossibleResultColorChanged}
                                        currentColor={this.state.currentlyEditedPossibleResult.color}/>
                                </div>
                            }

                            <PossibleResultsPanel
                                onDeleteClicked={IS_VERIFICATION_SCENARIO? undefined: this.deletePossibleResult}
                                results={this.state.possibleResultsWithColors}/>
                        </div>
                        {
                            scenarioId !== 0 && !IS_VERIFICATION_SCENARIO &&
                            <div className="pb-4 px-4 col-8 col-md-6 m-auto">
                                <div className="btn-group w-100">
                                    <input id="active" className="btn-check" type="radio" name="status"
                                           checked={!this.state.isArchived}
                                           onClick={() => this.setState({isArchived: false})}/>
                                    <label htmlFor="active"
                                           className="btn btn-outline-green">Активен</label>
                                    <input id="inactive" className="btn-check" type="radio" name="status"
                                           checked={this.state.isArchived}
                                           onClick={() => this.setState({isArchived: true})}/>
                                    <label htmlFor="inactive"
                                           className="btn btn-outline-secondary">
                                        <Icon icon={archive24}
                                              color={!this.state.isArchived ? 'grey' : 'white'}
                                              className='me-2'/>
                                        Архив
                                    </label>
                                </div>
                            </div>
                        }
                    </div>
                </main>
            </ContentScreen>
        )
    }

}

export default withToastManager(CallScenarioEditScreen)
