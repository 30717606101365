import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Reaction} from "../../../../models/enums";
import * as TypedUtils from "../../../../utils/TypedUtils";

interface Props {
    value?: Reaction
    setValue: (v: Reaction | undefined) => void
}

export default function ContactReactionSearchTableFilter(props: Props) {
    const [inputValue, setInputValue] = React.useState('');
    const availableOptions: string[] = [
        TypedUtils.getReactionDisplayName(Reaction.VOLUNTEER),
        TypedUtils.getReactionDisplayName(Reaction.BEST),
        TypedUtils.getReactionDisplayName(Reaction.GOOD),
        TypedUtils.getReactionDisplayName(Reaction.BAD),
        TypedUtils.getReactionDisplayName(Reaction.WORST),
    ]

    return (
        <Autocomplete
            size={'small'}
            value={props.value ? TypedUtils.getReactionFromName(props.value) : undefined}
            onChange={(e, value) => {
                props.setValue(value? TypedUtils.getReactionFromName(value): undefined)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            options={availableOptions}
            renderInput={(params) =>
                <TextField {...params} label="Реакция контакта" variant="outlined"/>
            }
        />
    );
}
