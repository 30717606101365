import * as React from 'react'
import {GlobalState, globalStore} from "../../utils/redux/ReduxUtils";
import {connect} from "react-redux";
import './ProfileScreen.css'
import ContentScreen from "../ContentScreen";
import {useHistory} from "react-router-dom";
import {Icon, InlineIcon} from '@iconify/react';
import earth20Filled from '@iconify-icons/fluent/earth-20-filled';
import {COLORS} from "../../utils/Consts";
import {DropzoneArea} from "material-ui-dropzone";
import * as API from "../../api/API";
import * as TypedUtils from "../../utils/TypedUtils";
import download24 from "@iconify/icons-octicon/download-24";
import GeoAddressSearchTable from "./geo/GeoAddressSearchTable";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import FileDragNDropArea from "./FileDragNDropArea";
import {ApiHqUpsertAddressesResponse} from "../../api/ResponseModels";
import RemoteSearchTable from "../components/search/RemoteSearchTable";
import {RefObject} from "react";

interface Props {
    toastManager: ToastConsumerContext
}

class GeoSettingsScreen extends React.Component<Props, any> {
    tableRef: RefObject<GeoAddressSearchTable> = React.createRef()

    render() {
        return (
            <ContentScreen>
                <div className='mx-auto w-100 mt-2 mb-2 d-flex gx-2 flex-column align-items-center'>
                    <div className='row col-12'>
                        <div className="col-auto h4 fw-normal mb-4">
                            <Icon icon={earth20Filled} className='icon icon-24 text-primary align-text-bottom me-2'
                                  color={COLORS.BLUE}/>
                            Геоданные
                        </div>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end me-2 mb-1"
                         onClick={() =>
                             API.exportAllAddressesCSV(globalStore.getState().authToken).then(blob =>
                                 TypedUtils.saveContentAsCSVFile(blob, 'allAddresses.csv')
                             ).catch(e => {
                                 console.log(e)
                                 this.props.toastManager.add('Не удалось осуществить экспорт!', {
                                     appearance: 'error',
                                 });
                             })
                         }
                    >
                        <div className='cursor-pointer text-blue'>
                            Скачать все адреса <Icon style={{}} width={14} icon={download24}/>
                        </div>
                    </div>
                    <div className='col-6 mt-2'>
                        <GeoAddressSearchTable
                            ref={this.tableRef}
                            onRowSelected={async r => {
                                await navigator.clipboard.writeText(JSON.stringify(r))
                                this.props.toastManager.add('Данные по адресу скопированы в буфер обмена', {
                                    appearance: 'success',
                                })
                            }}
                            toastManager={this.props.toastManager}/>
                    </div>
                    <div className='row col-6 mt-1'>
                        <FileDragNDropArea
                            uploadContent={(content) => API.importAddressesGeoJSON(content, globalStore.getState().authToken)}
                            handleResponse={(r: ApiHqUpsertAddressesResponse) => {
                                let message = `Импорт успешно осуществлён. Обновлено ${r.addressesCount} адресов`
                                this.props.toastManager.add(message, {
                                    appearance: 'success',
                                });
                                this.tableRef?.current?.loadRowsAsync()
                            }}
                            labelText='Выберите файл для обновления геоданных'
                            fileExtensions={[".geojson", ".json"]}
                            inputHintText={`Для корректного импорта файл должен иметь формат GeoJson в кодировке UTF-8`}
                            toastManager={this.props.toastManager}
                        />
                    </div>
                </div>
            </ContentScreen>
        )
    }


}

function mapStateToProps(state: GlobalState): any {
    return {}
}


export default withToastManager(connect(
    mapStateToProps
)(GeoSettingsScreen));
