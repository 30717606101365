import React from "react";
import TableSearchFilterView, {FilterOption, TableSearchFilterPropType} from "./TableSearchFilterView";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import * as API from "../../../../api/API";
import {globalStore} from "../../../../utils/redux/ReduxUtils";

export interface LayerIdAndPropertyName {
    layerId: number
    layerTitle: string
    name: string
}

interface Props {
    toastManager: ToastConsumerContext
    value?: FilterOption<string, LayerIdAndPropertyName>[]
    setValue: (v: FilterOption<string, LayerIdAndPropertyName>[] | undefined) => void
}

class LayerIntersectionSearchTableFilter extends React.Component<Props, any> {
    searchRows = async (searchQueryOpt?: string) => {
        const response = await API.getAllCampaignMapSubLayers(globalStore.getState().authToken).catch(err => {
            this.props.toastManager.add('Не удалось загрузить список возможных слоёв карты!', {appearance: 'error'})
            throw err
        })
        const sq = searchQueryOpt?.trim().toLowerCase() || ''
        const filteredBySearchRows = response.rows.filter(r => r.name.toLowerCase().includes(sq) || r.layerTitle.toLowerCase().includes(sq))
        return filteredBySearchRows.map(r => ({
            key: `${r.layerId}_${r.name}`,
            value: r,
            label: r.name
        }))

    }

    render() {
        return (
            <TableSearchFilterView
                type={TableSearchFilterPropType.MULTIPLE}
                title={'По слоям карты'}
                groupBy={v => `${v.value.layerTitle} (${v.value.layerId})`}
                value={this.props.value}
                setValue={this.props.setValue}
                searchRows={this.searchRows}
            />
        )
    }
}

export default withToastManager(LayerIntersectionSearchTableFilter)
