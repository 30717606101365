import {ContactModel} from "../models/ContactModel";
import {ApiContactGeo, UpsertContactRequest, ApiUpsertUserPaymentRequest} from "./RequestModels";
import moment from "moment";
import {UserModel} from "../models/UserModel";
import {ApiUser} from "./Models";

export function momentToLocalDateTimeString(moment: moment.Moment): string {
    return moment.format('YYYY-MM-DDTHH:mm:ss.SSS')
}

export function momentToDateString(moment: moment.Moment): string {
    return moment.format('YYYY-MM-DD')
}

export function mapContactToRequest(c: ContactModel, geo?: ApiContactGeo): UpsertContactRequest {
    return {
        id: c.id || 0,
        name: c.name,
        surname: c.surname,
        patronymic: c.patronymic,
        gender: c.gender,
        reaction: c.reaction,
        thematics: c.thematics,
        age: c.age,
        phoneNumber: c.phoneNumber,
        email: c.email,
        comment: c.comment,
        contactType: c.contactType,
        geo: geo,
        isArchived: c.isArchived,
        createdById: c.createdBy?.id
    }
}

export function mapUserToRequest(u: UserModel): ApiUser {
    return {
        id: u.id,
        name: u.name,
        surname: u.surname,
        phoneNumber: u.phoneNumber,
        telegramNickname: u.telegramNickname,
        whatsAppNickname: u.whatsAppNickname,
        birthday: u.birthday ? momentToDateString(u.birthday) : undefined,
        role: u.role,
        isActive: u.isActive,
        createdAt: momentToLocalDateTimeString(u.createdAt),
        routesDirectionUpsideDown: false,
        updatedAt: momentToLocalDateTimeString(u.updatedAt),
        login: u.login,
        password: u.password,
        totalPaid: 0,
        latestPayments: []
    }
}

export function mapUserPayment(paymentIdOpt: number | undefined, userId: number, paymentSum: number, comment?: string): ApiUpsertUserPaymentRequest {
    return ({
        id: paymentIdOpt || 0,
        targetUserId: userId,
        sum: paymentSum,
        comment: comment
    })
}
