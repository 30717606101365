export enum Gender {
    MALE = 'male',
    FEMALE = 'female'
}

export enum Age {
    YOUNG = 'young',
    PREADULT = 'preAdult',
    ADULT = 'adult',
    ELDER = 'elder',
    UNKNOWN = 'unknown'
}

// @see getReactionDisplayName
export enum Reaction {
    VOLUNTEER = 'volunteer',
    BEST = 'best',
    GOOD = 'good',
    BAD = 'bad',
    WORST = 'worst'
}

export enum ContactType {
    ODD = 'odd',
    STREET_CUBE = 'streetCube',
    STREET_PROMO = 'streetPromo'
}

// @see getUserRoleDisplayName
export enum UserRole {
    HR = 'hr',
    CHIEF = 'chief',
    ANALYST = 'analyst',
    FIELD = 'field',
    SCOUT = 'scout',
    SIGNATURE_SCOUT = 'signatureScout',
    CALLS_CHIEF = 'callsChief',
    CALLS_EMPLOYEE = 'callsEmployee',
}

export enum ApiSortCriteria {
    PHONE_NUMBER = 'phoneNumber',
    USER_ROLE = 'userRole',
    USER_LOGIN = 'userLogin',
    USER_FULL_NAME = 'userFullName',
    USER_TELEGRAM = 'userTelegram',
    USER_PHONE = 'userPhone',
    THEMATICS = 'thematics',
    FULL_ADDRESS = 'fullAddress',
    ENTRANCE_NO = 'entranceNo',
    FLAT_NO = 'flatNo',
    CREATED_AT = 'createdAt',
    COMMENT = 'comment',
    IS_ARCHIVED = 'isArchived',
    UPDATED_AT = 'updatedAt',

    PAYMENT_SUM = 'paymentSum',

    MATERIAL_TITLE = 'materialTitle',
    MATERIAL_TOTAL_COUNT = 'materialTotalCount',
    MATERIAL_GIVEN_COUNT = 'materialGivenCount',
    MATERIAL_DISTRIBUTED_COUNT = 'materialDistributedCount',

    AUDIT_CONTENT = 'auditContent',

    TOTAL_CONTACT_SHIFTS_COUNT = 'totalContactShiftsCount',
    TOTAL_CONTACTS_COUNT = 'totalContactsCount',

    CALL_RESULT = 'callResult'
}

export enum ApiSortDirection {
    ASC = 'asc',
    DESC = 'desc',
}

export enum ApiFilterCriteria {
    HOUSE_ID = 'houseId',
}

export enum GeoMapsDataType {
    THEMATICS = 'thematics',
    LivingQuarters = 'livingQuarters',
    ENTRANCES = 'entrances',
    GENDER = 'gender',
    AGE = 'age',
    REACTION = 'reaction',
    HAS_PHONE = 'hasPhone',
    PHONE_CALLS = 'phoneCalls',
}

export enum MaterialDistributionStatus {
    SUCCESS = 'success',
    FAILURE = 'failure',
}

export enum VerificationScenarioResultName {
    VERIFIED = 'Верифицирован',
    NOT_VERIFIED = 'Не верифицирован',
    ABSENT = 'Не дозвонились',
}
