
export enum ApiIsArchivedFilterValues {
    ARCHIVED = 'archived',
    NOT_ARCHIVED = 'notArchived',
    ALL = 'all',
}
export enum ApiHasEmailFilterValues {
    HAS = 'has',
    HAS_NOT = 'hasNot',
}

export enum SearchTableFilterType { // DON'T CHANGE NAMES
    IS_ARCHIVED = 'isArchived',
    HOUSE_ID = 'houseId',
    LAYER_ID_NAME = 'layerIdName',
    HOUSE_IDS = 'houseIds',
    THEMATICS = 'thematics',
    DATE_FROM_TO = 'dateFromTo',
    CREATED_BY = 'createdBy',
    CONTACT_REACTION = 'contactReaction',
    CALL_CREATED_BY = 'callCreatedBy',
    VERIFICATION_RESULT = 'verificationResult',
    HAS_EMAIL = 'hasEmail',
    CONTACT_CALLS_COUNT = 'contactCallsCount',
    CALL_SCENARIO_RESULT = 'callScenarioResult',
    CALL_SCENARIO_LATEST_RESULT = 'callScenarioLatestResult',
    CALL_SCENARIO_HAS_NO_RESULT_EXCEPT = 'callScenarioHasNotResultExcept'
}

export enum ApiVerificationResultFilterValues {
    VERIFIED = 'verified',
    NOT_VERIFIED = 'notVerified',
    ABSENT = 'absent',
    NOTHING = 'nothing',
}
