import React from "react";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import AppSpinner from "../../components/AppSpinner";
import {
    Paper,
    Size,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import {commonDateTimeFormat} from "../../../utils/Formatters";
import * as _ from "lodash";
import {CallScriptModel} from "../../../models/CallScriptModel";


interface State {
    rows: CallScriptModel[]
    shownRows: CallScriptModel[]
    isLoading: boolean
}

interface Props {
    toastManager: ToastConsumerContext
    onRowSelected?: (script: CallScriptModel) => void
    filterRows?: (script: CallScriptModel) => boolean
    size?: Size
}

class ScriptsSearchTable extends React.Component<Props, State> {
    state = {
        rows: [] as CallScriptModel[],
        shownRows: [] as CallScriptModel[],
        isLoading: true
    }
    _reloadScriptsAsync = () => {
        const loadAndSetState = async () => {
            const scripts = await API.getAllCallScripts(globalStore.getState().authToken)
            this.setState({rows: scripts, shownRows: scripts})
        }
        this.setState({isLoading: true}, () =>
            loadAndSetState()
                .finally(() => this.setState({isLoading: false}))
                .catch(err => {
                    this.props.toastManager.add('Не удалось загрузить скрипты звонков!', {
                        appearance: 'error',
                    });
                    console.log(err)
                })
        )
    }

    componentDidMount() {
        this._reloadScriptsAsync()
    }

    onSearchQueryChanged = _.debounce((q: string) => {
        const trimmedQuery = q.trim().toLowerCase()
        if (!trimmedQuery) this.setState({shownRows: this.state.rows})
        else {
            this.setState({
                shownRows: this.state.rows.filter(s =>
                    s.title.toLowerCase().includes(trimmedQuery) ||
                    s.createdBy?.fullName?.toLowerCase().includes(trimmedQuery) ||
                    s.content.toLowerCase().includes(trimmedQuery)
                )
            })
        }

    }, 500)

    render() {
        return (
            <>
                {
                    this.state.isLoading &&
                    <div className='app-spinner-fixed'>
                        <AppSpinner/>
                    </div>
                }
                <div>
                    <div className="col-auto col-sm ms-auto mt-n2">
                        <div className="input-group my-1">
                            <span className="input-group-text">Поиск</span>
                            <input type="text" className="form-control"
                                   onChange={e => this.onSearchQueryChanged(e.target.value)}
                                   placeholder='Название или автор'/>
                        </div>
                    </div>
                    <TableContainer component={Paper}>
                        <Table aria-label="callScripts">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Название</TableCell>
                                    <TableCell align="right">Содержимое</TableCell>
                                    <TableCell align="right">Создан</TableCell>
                                    <TableCell align="right">Автор</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.shownRows.map((row) => (
                                    <TableRow key={row.title}
                                              style={{cursor: 'pointer'}}
                                              hover
                                              onClick={() => this.props.onRowSelected && this.props.onRowSelected(row)}>
                                        <TableCell component="th" scope="row">{row.title}</TableCell>
                                        <TableCell
                                            align="right">{row.content.slice(0, 20) + (row.content.length > 20 ? '...' : '')}</TableCell>
                                        <TableCell align="right">{commonDateTimeFormat(row.createdAt)}</TableCell>
                                        <TableCell align="right">{row.createdBy?.fullName}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {
                    !this.state.isLoading && this.state.rows.length === 0 &&
                    <div className='text-center'>
                        Подходящие скрипты не найдены!
                    </div>
                }
            </>
        );
    }
}

export default withToastManager(ScriptsSearchTable)
