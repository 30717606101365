import React, {useState} from "react";
import {Button} from "react-bootstrap-v5";
import Icon from "@iconify/react";
import x24 from "@iconify/icons-octicon/x-24";

interface CancellableButtonProps<T> {
    index?: number | string
    title: string
    color?: string
    className?: string
    variant?: string
    row: T
    onClicked?: (row: T) => void
    onCancelClicked?: (row: T) => void
}

export function CancellableButton<T>(props: CancellableButtonProps<T>) {
    const [isHovered, setIsHovered] = useState(false)
    return (
        <Button key={props.index}
                variant={props.variant? props.variant: 'secondary'}
                className={'me-2 mb-1 position-relative ' + props.className || ''}
                onMouseLeave={() => setIsHovered(false)}
                onMouseEnter={() => setIsHovered(true)}
                onClick={() => props.onClicked && props.onClicked(props.row)}
                style={props.color ? {backgroundColor: props.color} : {}}
        >
            {props.title}
            {
                isHovered && Boolean(props.onCancelClicked) &&
                <div
                    onClick={e => {
                        e.stopPropagation()
                        props.onCancelClicked && props.onCancelClicked(props.row)
                    }}
                    className='possible-result-button__delete'>
                    <Icon icon={x24} width={16} color='black'/>
                </div>
            }
        </Button>
    )
}
