import '../MapScreen.scss'
import React, {useEffect, useState} from "react";
import {Button, Dropdown, Spinner} from "react-bootstrap-v5";
import {Age, Gender, GeoMapsDataType, Reaction} from "../../../models/enums";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons";
import Icon from "@iconify/react";
import filter16 from "@iconify/icons-octicon/filter-16";
import DatePicker from "react-datepicker";
import * as TypedUtils from "../../../utils/TypedUtils";
import {MAP_INFO_MODES} from "./MapTypes";

export function MapInfoPanel(props) {
    const [showFiltersPanel, setShowFiltersPanel] = useState(false)
    const [filters, setFilters] = useState({})
    const [dataTypes, setDataTypes] = useState([GeoMapsDataType.THEMATICS, GeoMapsDataType.GENDER, GeoMapsDataType.AGE, GeoMapsDataType.PHONE_CALLS])
    useEffect(() => {
        onPreferencesChanged(dataTypes, filters)
    }, [dataTypes])
    const data = props.statsData
    const showInfo = !props.isLoading && Boolean(data?.houses?.length > 0)

    const renderByReaction = () => {
        const getCountByReaction = (reaction) => {
            return data.reactions
                .filter(r => r.value === reaction)
                .map(r => r.count)
                .reduce((sum, x) => sum + x, 0)
        }
        const bestCount = getCountByReaction(Reaction.BEST)
        const volunteerCount = getCountByReaction(Reaction.VOLUNTEER)
        const goodCount = getCountByReaction(Reaction.GOOD)
        const badCount = getCountByReaction(Reaction.BAD)
        const worstCount = getCountByReaction(Reaction.WORST)

        return (
            <div className='w-100'>
                <div className='fw-bold'>Реакция:</div>
                <div className='ps-1'>
                    <div className='d-flex justify-content-between'>Волонтёров: <span
                        className='text-success fw-bold'>{volunteerCount}</span></div>
                    <div className='d-flex justify-content-between'>Сторонников: <span
                        className='text-success fw-bold'>{bestCount + volunteerCount}</span></div>
                    <div className='d-flex justify-content-between'>Дверей открыли: <span
                        className='text-secondary fw-bold'>{goodCount}</span></div>
                    <div className='d-flex justify-content-between mb-1'>Негатив: <span
                        className='text-danger fw-bold'>{badCount + worstCount}</span></div>
                </div>

            </div>
        )
    }

    const renderLivingQuarters = () => {
        return (
            <div className='d-flex justify-content-between fw-bold'>
                Всего жилых квартир:
                <span className='text-success fw-bold'>{data.houseInfo.livingQuartersSum || 'неизвестно'}</span>
            </div>
        )
    }
    const renderByAge = () => {
        return (
            <div className='w-100'>
                <div className='fw-bold'>Возраст:</div>
                <div className='ps-1'>
                    <div className='d-flex justify-content-between'>{TypedUtils.getAgeDisplayName(Age.YOUNG)}<span
                        className='text-success fw-bold'>{data.ages.find(a => a.value === Age.YOUNG)?.count || 0}</span>
                    </div>
                    <div className='d-flex justify-content-between'>{TypedUtils.getAgeDisplayName(Age.PREADULT)}<span
                        className='text-secondary fw-bold'>{data.ages.find(a => a.value === Age.PREADULT)?.count || 0}</span>
                    </div>
                    <div className='d-flex justify-content-between'>{TypedUtils.getAgeDisplayName(Age.ADULT)}<span
                        className='text-warning fw-bold'>{data.ages.find(a => a.value === Age.ADULT)?.count || 0}</span>
                    </div>
                    <div className='d-flex justify-content-between'>{TypedUtils.getAgeDisplayName(Age.ELDER)}<span
                        className='text-warning fw-bold'>{data.ages.find(a => a.value === Age.ELDER)?.count || 0}</span>
                    </div>
                    <div className='d-flex justify-content-between mb-1'>{TypedUtils.getAgeDisplayName(Age.UNKNOWN)}
                        <span className='text-danger fw-bold'>
                            {data.ages.find(a => a.value === Age.UNKNOWN)?.count || 0}
                        </span>
                    </div>
                </div>

            </div>
        )
    }
    const renderByGender = () => {
        return (
            <div className='w-100'>
                <div className='fw-bold'>Пол:</div>
                <div className='ps-1'>
                    <div className='d-flex justify-content-between'>Мужской<span
                        className='text-success fw-bold'>{data.genders.find(a => a.value === Gender.MALE)?.count || 0}</span>
                    </div>
                    <div className='d-flex justify-content-between'>Женский<span
                        className='text-secondary fw-bold'>{data.genders.find(a => a.value === Gender.FEMALE)?.count || 0}</span>
                    </div>
                </div>

            </div>
        )
    }
    const renderByPhoneLeft = () => {
        const hasPhonesCount = data.hasPhones.find(a => a.value === true)?.count || 0
        return (
            <div className='w-100'>
                <div className='fw-bold'>Телефон:</div>
                <div className='ps-1'>
                    <div className='d-flex justify-content-between'>Оставили телефон<span
                        className='text-success fw-bold'>{hasPhonesCount}</span>
                    </div>
                    <div className='d-flex justify-content-between'>Не оставили телефон<span
                        className='text-danger fw-bold'>{data.totalContactsCount - hasPhonesCount}</span>
                    </div>
                </div>

            </div>
        )
    }
    const renderByPhoneCalls = () => {
        const contactsCalledTotal = data.phoneCalls.map(c => c.count).reduce((sum, x) => sum + x, 0)
        const rows = data.phoneCalls.map(tRow =>
            <div className='d-flex justify-content-between'>{tRow.value}<span
                className='text-success fw-bold'>{tRow.count}</span>
            </div>
        )
        return (
            <div className='w-100'>
                <div className='fw-bold'>Звонки в КЦ:</div>
                <div className='ps-1'>
                    <div className='d-flex justify-content-between'>Контактов прозвонено<span
                        className='text-success fw-bold'>{contactsCalledTotal}</span>
                    </div>
                    <div className='d-flex justify-content-between'>Контактов не прозвонено<span
                        className='text-danger fw-bold'>{Math.max(data.totalContactsCount - contactsCalledTotal, 0)}</span>
                    </div>
                    {
                        rows.length > 0 && rows
                    }
                </div>

            </div>
        )
    }
    const renderByThematics = () => {
        const rows = data.thematics.map(tRow =>
            <div className='d-flex justify-content-between'>{tRow.value}<span
                className='text-success fw-bold'>{tRow.count}</span>
            </div>
        )
        return (
            <div className='w-100'>
                <div className='fw-bold'>Проблематики звонков:</div>
                <div className='ps-1'>
                    {rows.length > 0 && rows}
                </div>

            </div>
        )
    }
    const dateFromFilter = filters.dateFrom
    const dateToFilter = filters.dateTo
    const setDateFromFilter = (date) => setFilters({...filters, ...{dateFrom: date}})
    const setDateToFilter = (date) => setFilters({...filters, ...{dateTo: date}})
    const toggleDataType = (propName) => {
        let newDataTypes = [...dataTypes]
        if (newDataTypes.includes(propName)) {
            newDataTypes = newDataTypes.filter(dt => dt !== propName)
        } else newDataTypes = newDataTypes.concat([propName])
        setDataTypes(newDataTypes)
    }

    const onPreferencesChanged = (dataTypesOpt, filtersOpt) => {
        props.onPreferencesChanged && props.onPreferencesChanged(dataTypesOpt ? dataTypesOpt : dataTypes, filtersOpt ? filtersOpt : filters)
    }

    const resetFilters = () => {
        setFilters({})
        setShowFiltersPanel(false)
        onPreferencesChanged(dataTypes, {})
    }
    const onConfirmFilters = () => {
        setShowFiltersPanel(false)
        onPreferencesChanged(dataTypes, filters)
    }
    const makeButton = (title, geoMapDataType) => {
        return (
            <li className="list-group-item list-group-item-action cursor-pointer"
                onClick={() => toggleDataType(geoMapDataType)}>
                <input
                    checked={dataTypes.includes(geoMapDataType)}
                    className="form-check-input me-1" type="checkbox"/>
                {title}
            </li>)
    }
    return (
        <div className='d-flex flex-column align-items-center justify-content-center pb-2 pt-1'>
            <div className='col-12 border-bottom pb-1'>
                <div className='d-flex justify-content-between'>
                    <Dropdown>
                        {
                            props.infoMode === MAP_INFO_MODES.CONTACTS
                                ?
                                <Dropdown.Toggle variant={'azure'}>
                                    <FontAwesomeIcon className='icon icon-16 d-md-none d-lg-inline me-3 me-lg-2'
                                                     color='white' icon={faCog}/>
                                    Визуализация
                                </Dropdown.Toggle>
                                : <div></div>
                        }

                        <Dropdown.Menu>
                            <ul className="list-group" style={{minWidth: 250}}>
                                {makeButton('Проблематика', GeoMapsDataType.THEMATICS)}
                                {makeButton('Подъезды', GeoMapsDataType.ENTRANCES)}
                                {makeButton('Количество квартир', GeoMapsDataType.LivingQuarters)}
                                {makeButton('Пол', GeoMapsDataType.GENDER)}
                                {makeButton('Реакция', GeoMapsDataType.REACTION)}
                                {makeButton('Возраст', GeoMapsDataType.AGE)}
                                {makeButton('Оставил телефон', GeoMapsDataType.HAS_PHONE)}
                                {makeButton('Звонки КЦ', GeoMapsDataType.PHONE_CALLS)}
                            </ul>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button variant='azure' onClick={() => {
                        if (showFiltersPanel) setFilters({})
                        setShowFiltersPanel(!showFiltersPanel)
                    }}>
                        Фильтры
                        <Icon icon={filter16} className='ms-2' width={16} color='white'/>
                    </Button>
                </div>
                <div className="col-12 my-1">
                    <div className="btn-group w-100">
                        <input id="male" className="btn-check" type="radio" name="gender"
                               checked={props.infoMode === MAP_INFO_MODES.CONTACTS}
                               onClick={() => props.setInfoMode(MAP_INFO_MODES.CONTACTS)}/>
                        <label htmlFor="male" className="btn btn-outline-azure">Контакты</label>
                        <input id="female" className="btn-check" type="radio" name="gender"
                               checked={props.infoMode === MAP_INFO_MODES.AGITATION}
                               onClick={() => props.setInfoMode(MAP_INFO_MODES.AGITATION)}/>
                        <label htmlFor="female" className="btn btn-outline-pink">Агитация</label>
                    </div>
                </div>
                {
                    showFiltersPanel &&
                    <div className='col-12 row bg-light m-0 pb-2 mt-1'>
                        <div className={'col-6'}>
                            <label htmlFor="dateFrom"
                                   className="col-form-label">Дата (начало)</label>
                            <DatePicker selected={dateFromFilter} className='col-12' onChange={setDateFromFilter}/>
                        </div>
                        <div className={'col-6'}>
                            <label htmlFor="dateFrom"
                                   className="col-form-label">Дата (конец)</label>
                            <DatePicker selected={dateToFilter} className='col-12' onChange={setDateToFilter}/>
                        </div>
                        <div className={'col-12 flex-row d-flex justify-content-between pt-1'}>
                            <Button
                                onClick={resetFilters}
                                className='col-3 me-2' variant={'pink'}>
                                Отмена
                            </Button>
                            <Button variant='azure' className='col-4' onClick={() => onConfirmFilters()}>
                                Применить
                            </Button>
                        </div>
                    </div>
                }
            </div>

            {
                props.show && <div className='map-top-info-panel'>
                    {
                        props.isLoading &&
                        <Spinner style={{height: 30, width: 30}} animation="border" variant='primary' role="status"/>
                    }
                    {
                        showInfo && props.infoMode === MAP_INFO_MODES.CONTACTS && <>
                            <div className='w-100 px-3 pb-2'>
                                <div className='d-flex justify-content-between fw-bold border-top border-bottom py-1'
                                     style={{fontSize: 13}}>Всего контактов: <span
                                    className='text-success fw-bold'>{data.totalContactsCount}</span></div>
                                {dataTypes.includes(GeoMapsDataType.LivingQuarters) && renderLivingQuarters()}
                                {dataTypes.includes(GeoMapsDataType.REACTION) && renderByReaction()}
                                {dataTypes.includes(GeoMapsDataType.AGE) && renderByAge()}
                                {dataTypes.includes(GeoMapsDataType.GENDER) && renderByGender()}
                                {dataTypes.includes(GeoMapsDataType.THEMATICS) && renderByThematics()}
                                {dataTypes.includes(GeoMapsDataType.HAS_PHONE) && renderByPhoneLeft()}
                                {dataTypes.includes(GeoMapsDataType.PHONE_CALLS) && renderByPhoneCalls()}
                            </div>
                        </>
                    }
                    {
                        showInfo && data?.materials && props.infoMode === MAP_INFO_MODES.AGITATION && <>
                            <div className='w-100 px-3 pb-2'>
                                <div className='d-flex justify-content-between fw-bold border-top border-bottom py-1'
                                     style={{fontSize: 13}}>Всего материалов:
                                </div>
                                {
                                    data.materials.map((m, idx) =>
                                        <div key={idx} className={'d-flex justify-content-between'}>
                                            <span style={{fontSize: 14}}>{m.value}</span>
                                            <div className={'d-flex align-items-center'}>
                                                <span style={{fontSize: 14}}
                                                      className='text-success fw-bold'>{`${m.count}`}&nbsp;</span>
                                                шт.
                                            </div>

                                        </div>
                                    )
                                }
                            </div>
                        </>
                    }
                </div>
            }
        </div>

    )
}
