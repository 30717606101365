import React from "react";
import {Modal} from "react-bootstrap-v5";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import NumericInput from "react-numeric-input";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {ApiAgitationMaterialsByUsersResponseRow, ApiAgitationMaterialsResponseRow} from "../../../api/ResponseModels";
import Icon from "@iconify/react";
import archive24 from "@iconify/icons-octicon/archive-24";

interface State {
    isReturn: boolean
    seriesCount: number
}

interface Props {
    onCancel: () => void
    onSaved: () => void
    toastManager: ToastConsumerContext
    distributionInfo: ApiAgitationMaterialsByUsersResponseRow
}

class UpdateMaterialGivenCountForUserModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            seriesCount: props.distributionInfo.remainingCount,
            isReturn: true
        }
    }

    saveReturn = () => {
        const saveF = async () => {
            await API.updateMaterialGivenCountForUser(
                this.props.distributionInfo.user.id,
                {
                    materialId: this.props.distributionInfo.materialId,
                    seriesCount: this.state.isReturn? -this.state.seriesCount: this.state.seriesCount,
                }, globalStore.getState().authToken
            )
            this.props.toastManager.add('Количество материала у пользователя успешно изменено', {
                appearance: 'success',
            });
            this.props.onSaved()
        }
        if (this.state.seriesCount > 0) {
            saveF().catch(err => {
                console.log(err)
                this.props.toastManager.add('Не удалось обновить количество материала у пользователя!', {
                    appearance: 'error',
                });
            })
        } else {
            this.props.onCancel()
        }
    }

    toggleIsReturn = (isReturn: boolean) => {
        let nextSeriesCount = this.state.seriesCount
        if (this.state.isReturn && !isReturn) nextSeriesCount = 0
        else if (!this.state.isReturn && isReturn) nextSeriesCount = this.props.distributionInfo.remainingCount
        this.setState({
            isReturn: isReturn,
            seriesCount: nextSeriesCount
        })
    }

    render() {
        const modalTitle = this.state.isReturn? 'Возврат агитматериалов': 'Добавление агитматериалов'
        const inputHintText = this.state.isReturn
            ? `Количество к возврату (макс. ${this.props.distributionInfo.remainingCount}), шт`
            : 'Количество к добавлению, шт'
        return (
            <Modal show={true} onHide={this.props.onCancel} centered>
                <Modal.Body>
                    <main id="call-script-editor" className="container-fluid">
                        <div className="max-w-md shadow-lg mb-4">
                            <div
                                className="bg-dark text-white text-center fw-500 py-3 d-flex flex-row justify-content-between align-items-center">
                                <span className='ms-5'>{modalTitle}</span>
                            </div>
                            <div className='row mx-2'>
                                <div className="col-12 my-2">
                                    <div className="btn-group w-100">
                                        <input id="active" className="btn-check" type="radio" name="status"
                                               checked={!this.state.isReturn}
                                               onClick={() => this.toggleIsReturn(false)}/>
                                        <label htmlFor="active"
                                               className="btn btn-outline-green">Добавить</label>
                                        <input id="inactive" className="btn-check" type="radio" name="status"
                                               checked={this.state.isReturn}
                                               onClick={() => this.toggleIsReturn(true)}/>
                                        <label htmlFor="inactive"
                                               className="btn btn-outline-danger">
                                            Возврат
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white pt-2 pb-4 px-4">
                                <div className='row'>
                                    <label htmlFor="seriesTotal"
                                           className="col-form-label col-12 form-label-required">{inputHintText}</label>
                                    <div className="col-12">
                                        <NumericInput
                                            id="seriesTotal"
                                            className="form-control"
                                            min={0}
                                            max={this.props.distributionInfo.remainingCount}
                                            placeholder={`штук`}
                                            onChange={n => this.setState({seriesCount: n || 0})}
                                            value={this.state.seriesCount}/>
                                    </div>
                                </div>
                                <div className="bg-white text-center sticky-bottom p-4">
                                    <div className="row gx-4">
                                        <div className="col-6">
                                            <button id="cancel"
                                                    onClick={this.props.onCancel}
                                                    className="btn btn-danger w-100 fw-500">Отмена
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button id="save" onClick={this.saveReturn}
                                                    className="btn btn-success w-100 fw-500">
                                                Сохранить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Modal.Body>
            </Modal>
        )
    }

}

export default UpdateMaterialGivenCountForUserModal
