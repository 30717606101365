import React, {PropsWithChildren, useEffect, useState} from 'react';
import './elector.scss'
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {GlobalState, globalStore} from "./utils/redux/ReduxUtils";
import AuthScreen from "./screens/AuthScreen";
import HomeScreen from "./screens/inner/HomeScreen";
import * as API from "./api/API";
import {updateLoadedDataAction} from "./utils/redux/Actions";
import {ToastProvider} from 'react-toast-notifications'
import {connect} from "react-redux";
import AppSpinner from "./screens/components/AppSpinner";
import ProfileScreen from "./screens/inner/ProfileScreen";
import 'moment/locale/ru' // without this line it didn't work
import moment from 'moment'
import MapScreen from "./screens/inner/MapScreen";
import FinancesScreen from "./screens/inner/finances/FinancesScreen";
import ContactViewScreen from "./screens/inner/contacts/ContactViewScreen";
import UsersScreen from "./screens/inner/UsersScreen";
import UserViewScreen from './screens/inner/users/UserViewScreen';
import CallsScreen from "./screens/inner/calls/CallsScreen";
import CampaignSettingsScreen from "./screens/inner/CampaignSettingsScreen";
import CallScriptsScreen from "./screens/inner/calls/CallScriptsScreen";
import CallScenariosScreen from "./screens/inner/calls/CallScenariosScreen";
import CallScenarioEditScreen from "./screens/inner/calls/CallScenarioEditScreen";
import FieldScreen from "./screens/inner/FieldScreen";
import MyCallsScreen from "./screens/inner/calls/MyCallsScreen";
import {ExternalAdditionalInfoUserModel} from "./models/ExternalAdditionalInfo";
import {UserRole} from "./models/enums";
import AuditScreen from "./screens/inner/AuditScreen";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CallViewScreen from "./screens/inner/calls/CallViewScreen";
import ContactsAggregatesScreen from "./screens/inner/ContactsAggregatesScreen";
import ContactsScreen from "./screens/inner/ContactsScreen";
import GeoSettingsScreen from "./screens/inner/GeoSettingsScreen";

interface Props {
    authToken: string,
    user?: ExternalAdditionalInfoUserModel
}

const App = (props: PropsWithChildren<Props>) => {
    const isLoggedIn = Boolean(props.authToken);
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        moment.locale('ru')

        async function loadData() {
            const additionalInfo = await API.loadAdditionalInfo(props.authToken)
            globalStore.dispatch(updateLoadedDataAction(additionalInfo))
        }

        if (props.authToken) {
            API.checkHealth(props.authToken).then(() => {
                loadData().finally(() => setIsLoading(false))
            })
        }
    }, [props.authToken, props.user?.role])

    const showApp = isLoggedIn && !isLoading && Boolean(props.user?.role)
    return (
        <ToastProvider autoDismiss={true} placement={'top-center'} autoDismissTimeout={2000}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <div className="App">
                    {
                        showApp &&
                        <Router>
                            <Switch>
                                <Route exact path="/dashboard">
                                    <HomeScreen/>
                                </Route>
                                <Route exact path="/profile">
                                    <ProfileScreen/>
                                </Route>
                                <Route exact path="/geo">
                                    <MapScreen/>
                                </Route>
                                <Route exact path="/contacts">
                                    <ContactsScreen/>
                                </Route>
                                <Route exact path="/contact-aggregates">
                                    <ContactsAggregatesScreen/>
                                </Route>
                                <Route exact path="/users">
                                    <UsersScreen/>
                                </Route>
                                <Route exact path="/calls">
                                    <CallsScreen/>
                                </Route>
                                <Route exact path="/field">
                                    <FieldScreen/>
                                </Route>
                                <Route exact path="/my-calls">
                                    <MyCallsScreen/>
                                </Route>
                                <Route exact path="/call-scripts">
                                    <CallScriptsScreen/>
                                </Route>
                                <Route exact path="/audit">
                                    <AuditScreen/>
                                </Route>
                                <Route exact path="/geo-settings">
                                    <GeoSettingsScreen/>
                                </Route>
                                <Route exact path="/call-scenarios">
                                    <CallScenariosScreen/>
                                </Route>
                                <Route exact path="/campaign-settings">
                                    <CampaignSettingsScreen/>
                                </Route>
                                <Route path="/call/:id" component={CallViewScreen}/>
                                <Route path="/contact/:id" component={ContactViewScreen}/>
                                <Route path="/call-scenarios/:id" component={CallScenarioEditScreen}/>
                                <Route path="/user/:id" component={UserViewScreen}/>
                                <Route exact path="/finances">
                                    <FinancesScreen/>
                                </Route>
                                <Route>
                                    {
                                        props.user?.role === UserRole.CALLS_EMPLOYEE ?
                                            <Redirect to="/my-calls"/> :
                                            props.user?.role === UserRole.HR ?
                                                <Redirect to="/users"/> :
                                                <Redirect to="/dashboard"/>
                                    }
                                </Route>
                            </Switch>
                        </Router>
                    }
                    {
                        !isLoggedIn &&
                        <Router>
                            <Switch>
                                <Route exact path="/">
                                    <AuthScreen/>
                                </Route>
                                <Redirect to="/"/>
                            </Switch>
                        </Router>
                    }
                    {
                        isLoggedIn && !showApp && <AppSpinner/>

                    }
                </div>
            </MuiPickersUtilsProvider>
        </ToastProvider>
    );
}

function mapStateToProps(state: GlobalState): Props {
    return {
        authToken: state.authToken,
        user: state.externalAdditionalInfo?.user
    }
}

export default connect(
    mapStateToProps
)(App);
