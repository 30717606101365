import {APIExternalAdditionalInfo, ApiUser, APIUserInfo, APIUserPayment} from "./Models";
import moment from "moment";
import {
    ExternalAdditionalInfo,
    ExternalAdditionalInfoPayment,
    ExternalAdditionalInfoUserModel
} from "../models/ExternalAdditionalInfo";
import {ContactModel} from "../models/ContactModel";
import {UpsertContactRequest} from "./RequestModels";
import {
    APICallScriptResponse,
    ApiContactInfoResponseRow,
    DashboardAnalyticsResponse,
    UserSummaryInfoResponse
} from "./ResponseModels";
import {DashboardAnalyticsModel} from "../models/DashboardAnalyticsModel";
import {UserModel, UserSummaryInfo} from "../models/UserModel";
import {CallScriptModel} from "../models/CallScriptModel";

export function mapToExternalAdditionalInfoUserModel(response: APIUserInfo): ExternalAdditionalInfoUserModel {
    return (
        {
            id: response.id,
            name: response.name,
            role: response.role,
            createdAt: moment(response.createdAt),
            phoneNumber: response.phoneNumber,
            telegramNickname: response.telegramNickname,
            whatsAppNickname: response.whatsAppNickname,
            routesDirectionUpsideDown: response.routesDirectionUpsideDown
        }
    )
}

function mapUserPayment(api: APIUserPayment): ExternalAdditionalInfoPayment {
    return {
        value: api.value,
        createdAt: moment(api.createdAt)
    }
}

export function mapToExternalAdditionalInfo(api: APIExternalAdditionalInfo): ExternalAdditionalInfo {
    return {
        user: mapToExternalAdditionalInfoUserModel(api.userInfo),
        thematics: api.thematics,
        payments: api.payments.map(mapUserPayment),
        commonSettings: api.commonSettings,
        callsSettings: api.callsSettings,
        verificationScenarioId: api.verificationScenarioId,
        verificationSettings: api.verificationSettings
    }

}

export function mapApiContactToContact(c: ApiContactInfoResponseRow): ContactModel {
    return {
        id: c.id,
        name: c.name,
        surname: c.surname,
        patronymic: c.patronymic,
        gender: c.gender,
        reaction: c.reaction,
        thematics: c.thematics,
        age: c.age,
        phoneNumber: c.phoneNumber,
        email: c.email,
        comment: c.comment,
        contactType: c.contactType,
        createdAt: moment(c.createdAt),
        geo: c.geo,
        fullAddress: c.fullAddress,
        isArchived: c.isArchived,
        createdBy: c.createdBy
    }
}

function mapUserSummaryInfo(api: UserSummaryInfoResponse): UserSummaryInfo {
    return {
        id: api.id,
        fullName: api.fullName,
        role: api.role
    }
}

export function mapApiUserToUser(api: ApiUser): UserModel {
    return {
        id: api.id,
        name: api.name,
        surname: api.surname,
        phoneNumber: api.phoneNumber,
        telegramNickname: api.telegramNickname,
        whatsAppNickname: api.whatsAppNickname,
        birthday: api.birthday ? moment(api.birthday) : undefined,
        role: api.role,
        isActive: api.isActive,
        updatedAt: moment(api.updatedAt),
        updatedBy: api.updatedBy ? mapUserSummaryInfo(api.updatedBy) : undefined,
        createdAt: moment(api.createdAt),
        createdBy: api.createdBy ? mapUserSummaryInfo(api.createdBy) : undefined,
        login: api.login,
        password: undefined,
        totalPaid: api.totalPaid,
        latestPayments: api.latestPayments
    }
}

export function mapApiCallScript(api: APICallScriptResponse): CallScriptModel {
    return {
        id: api.id,
        title: api.title,
        content: api.content,
        createdAt: moment(api.createdAt),
        updatedAt: moment(api.updatedAt),
        createdBy: mapUserSummaryInfo(api.createdBy),
        isArchived: api.isArchived
    }
}
