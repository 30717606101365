import React from "react";
import TableSearchFilterView, {FilterOption, TableSearchFilterPropType} from "./TableSearchFilterView";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import * as API from "../../../../api/API";
import {globalStore} from "../../../../utils/redux/ReduxUtils";

interface Props {
    toastManager: ToastConsumerContext
    value?: FilterOption<number, string>
    setValue: (v: FilterOption<number, string> | undefined) => void
}

const NO_ADDRESS_VALUE: FilterOption<number, string> = {
    key: 0,
    value: '0',
    label: '0',
}

class ContactCallsCountSearchTableFilter extends React.Component<Props, any> {
    searchRows = async (searchQueryOpt?: string) => {
        const response = await API.getAllDistinctContactCallCounts(globalStore.getState().authToken).catch(err => {
            this.props.toastManager.add('Не удалось загрузить список количества звонков по контактам!', {appearance: 'error'})
            throw err
        })
        const possibleValues: FilterOption<number, string>[] = response.rows
            .filter(r => r.toString().includes(searchQueryOpt?.trim() || ''))
            .map(r => ({
                key: r.id,
                value: r.value.toString(),
                label: r.value.toString()
            }))
        return [NO_ADDRESS_VALUE].concat(possibleValues)
    }

    render() {
        return (
            <TableSearchFilterView
                type={TableSearchFilterPropType.SINGLE}
                title={'Звонков по контакту'}
                value={this.props.value}
                setValue={this.props.setValue}
                searchRows={this.searchRows}
            />
        )
    }
}

export default withToastManager(ContactCallsCountSearchTableFilter)
