import React, {RefObject} from "react";
import RemoteSearchTable, {SearchTableHeaderCell} from "../../components/search/RemoteSearchTable";
import {ApiSortCriteria, MaterialDistributionStatus} from "../../../models/enums";
import {ApiElectorCommonTableParameters} from "../../../api/RequestModels";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import * as API from "../../../api/API";
import {ApiAgitationDistributionsResponseRow} from "../../../api/ResponseModels";
import {TableCell} from "@material-ui/core";
import {ToastConsumerContext} from "react-toast-notifications";
import * as TypedUtils from "../../../utils/TypedUtils";
import {commonDateTimeFormat} from "../../../utils/Formatters";
import moment from "moment";
import {RemoteSearchTableFilterModel} from "../../../models/RemoteSearchTableFilterModel";
import {ApiIsArchivedFilterValues, SearchTableFilterType} from "../../../models/ApiFilterEnums";
import {EMPTY_FILTER_INFO} from "../../components/search/filters/DateFromToSearchTableFilter";
import MaterialDistributionViewModal from "./MaterialDistributionViewModal";

type TableRowType = ApiAgitationDistributionsResponseRow

const ALL_FILTERS: RemoteSearchTableFilterModel[] = [
    {
        type: SearchTableFilterType.HOUSE_ID,
        value: undefined,
        defaultValue: undefined
    },
    {
        type: SearchTableFilterType.DATE_FROM_TO,
        value: EMPTY_FILTER_INFO,
        defaultValue: EMPTY_FILTER_INFO
    },
    {
        type: SearchTableFilterType.CREATED_BY,
        value: undefined,
        defaultValue: undefined
    },
]
const HEADER_CELLS: SearchTableHeaderCell[] = [
    {
        id: ApiSortCriteria.MATERIAL_TITLE,
        disablePadding: false,
        label: 'Материал',
        sortCriteria: ApiSortCriteria.MATERIAL_TITLE
    },
    {
        id: ApiSortCriteria.MATERIAL_DISTRIBUTED_COUNT,
        disablePadding: false,
        label: 'Роздано, шт',
        sortCriteria: ApiSortCriteria.MATERIAL_DISTRIBUTED_COUNT
    },
    {
        id: ApiSortCriteria.FULL_ADDRESS,
        disablePadding: false,
        label: 'Адрес',
        sortCriteria: ApiSortCriteria.FULL_ADDRESS
    },
    {
        id: ApiSortCriteria.ENTRANCE_NO,
        disablePadding: false,
        label: 'Подъезд',
        sortCriteria: ApiSortCriteria.ENTRANCE_NO
    },
    {id: ApiSortCriteria.CREATED_AT, disablePadding: false, label: 'Создана', sortCriteria: ApiSortCriteria.CREATED_AT},
    {
        id: 'createdBy',
        disablePadding: false,
        label: 'Автор',
    },
    {
        id: ApiSortCriteria.COMMENT,
        disablePadding: false,
        label: 'Комментарий',
        sortCriteria: ApiSortCriteria.COMMENT
    },
];

interface Props {
    toastManager: ToastConsumerContext
}

interface State {
    currentlyViewedDistributionRow?: TableRowType
}

class AgitationDistributionsSearchTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.tableRef = React.createRef()
        this.state = ({
            currentlyViewedDistributionRow: undefined
        })
    }

    tableRef: RefObject<any> | undefined = undefined

    loadRows = async (params: ApiElectorCommonTableParameters) => {
        const response = await API.getAllMaterialsDistributions(params, globalStore.getState().authToken)
        return response.rows
    }
    _reloadTable = () => this.tableRef?.current?.loadRowsAsync()

    _renderCells = (row: TableRowType) => {
        return (
            [
                <TableCell align='left'>{row.materialTitle}</TableCell>,
                <TableCell align='left'>{row.distributionCount > 0 ? row.distributionCount : '-'}</TableCell>,
                <TableCell align='left'>{row.fullAddress || '-'}</TableCell>,
                <TableCell align='left'>{row.entranceNo || '-'}</TableCell>,
                <TableCell align='left'>{commonDateTimeFormat(moment(row.createdAt))}</TableCell>,
                <TableCell
                    align='left'>{TypedUtils.renderUserLink(row.createdBy.fullName, row.createdBy.id)}</TableCell>,
                <TableCell align='left'>{row.comment}</TableCell>,
            ]
        )
    }
    onRowSelected = (r: TableRowType) => {
        this.setState({currentlyViewedDistributionRow: r})
    }
    onDistributionDeleted = () => {
        this.setState({currentlyViewedDistributionRow: undefined}, () => this._reloadTable())
    }


    render() {
        return (
            <>
                <RemoteSearchTable
                    toastManager={this.props.toastManager}
                    ref={this.tableRef}
                    onRowSelected={this.onRowSelected}
                    searchPlaceholder={'Материал, автор, адрес или количество'}
                    rowKeyExtractor={(u: TableRowType) => u.id.toString()}
                    applyClassToRow={(u: TableRowType) => u.status === MaterialDistributionStatus.FAILURE ? 'all-distributions-table__row_failure' : ''}
                    renderTableCells={this._renderCells}
                    headerCells={HEADER_CELLS}
                    allFilters={ALL_FILTERS}
                    loadRows={this.loadRows}/>
                {
                    this.state.currentlyViewedDistributionRow &&
                    <MaterialDistributionViewModal
                        onDeleted={this.onDistributionDeleted}
                        onCancel={() => this.setState({currentlyViewedDistributionRow: undefined})}
                        toastManager={this.props.toastManager}
                        distributionInfo={this.state.currentlyViewedDistributionRow}/>
                }
            </>
        );
    }
}

export default AgitationDistributionsSearchTable
