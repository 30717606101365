import React from 'react';
import {KeyboardDateTimePicker} from '@material-ui/pickers';
import moment from "moment";

export interface DateFromToFilterInfo {
    dateFrom?: moment.Moment
    dateTo?: moment.Moment
}
export const EMPTY_FILTER_INFO: DateFromToFilterInfo = {
    dateFrom: undefined,
    dateTo: undefined,
}
interface Props {
     value?: DateFromToFilterInfo
     setValue: (v: DateFromToFilterInfo) => void
}

export default function DateFromToSearchTableFilter(props: Props) {
    const setDateTo = (newMoment: moment.Moment | null) => {
        let nextValue = {...props.value}
        nextValue.dateTo = newMoment || undefined
        props.setValue(nextValue)
    }
    const setDateFrom = (newMoment: moment.Moment | null) => {
        let nextValue = {...props.value}
        nextValue.dateFrom = newMoment || undefined
        props.setValue(nextValue)
    }

    return (
        <>
            <KeyboardDateTimePicker
                ampm={false}
                value={props.value?.dateFrom || null}
                onChange={setDateFrom}
                label="Начальная дата"
                onError={console.log}
                minDate={new Date("2000-01-01T00:00")}
                format="DD.MM.YYYY HH:mm"
                size={'small'}
                inputVariant={'outlined'}
                className={'me-2'}
            />
            <KeyboardDateTimePicker
                ampm={false}
                value={props.value?.dateTo || null}
                onChange={setDateTo}
                label="Конечная дата"
                onError={console.log}
                minDate={new Date("2000-01-01T00:00")}
                format="DD.MM.YYYY HH:mm"
                size={'small'}
                inputVariant={'outlined'}
            />
        </>
    );
}
