import ContentScreen from "../../ContentScreen";
import React from "react";
import AppSpinner from "../../components/AppSpinner";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import PhoneCallInput from "./PhoneCallInput";
import {
    APICallScriptResponse,
    ApiContactInfoResponseRow,
    ApiPhoneCallByIdResponse,
    APIScenarioResponse
} from "../../../api/ResponseModels";
import {ApiUpsertPhoneCallRequest} from "../../../api/RequestModels";

interface Props {
    toastManager: ToastConsumerContext
}

interface State {
    isLoading: boolean
    contact?: ApiContactInfoResponseRow
    phoneCall?: ApiPhoneCallByIdResponse
    script?: APICallScriptResponse
    scenario?: APIScenarioResponse
}

class CallViewScreen extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = ({
            isLoading: true,
            contact: undefined,
            phoneCall: undefined
        })
    }

    componentDidMount() {
        //@ts-ignore
        const callId: number = this.props.match.params.id

        const loadInfoF = async () => {
            const token = globalStore.getState().authToken
            const callInfo = await API.getPhoneCallById(callId, token)
            const contactResponse = await API.getContactById(callInfo.contactId, token)
            const scenarioAndScript = await API.getScenarioAndScriptForShift(callInfo.shiftId, token)

            this.setState({
                contact: contactResponse,
                phoneCall: callInfo,
                scenario: scenarioAndScript.scenario,
                script: scenarioAndScript.script,
            })
        }
        loadInfoF().finally(() => {
            this.setState({isLoading: false})
        }).catch(err => {
            console.log(err)
            this.props.toastManager.add('Не удалось загрузить контакт и существующий звонок', {
                appearance: 'error',
            });
        })
    }

    savePhoneCall = (m: ApiUpsertPhoneCallRequest) => {
        const saveF = async () => {
            await API.savePhoneCall(m, globalStore.getState().authToken)
            this.props.toastManager.add('Звонок успешно сохранён', {
                appearance: 'success',
            });
        }
        saveF().catch(err => {
            console.log(err)
            this.props.toastManager.add('Не удалось сохранить звонок!', {
                appearance: 'error',
            });
        })

    }

    render() {
        return (
            <ContentScreen>
                {
                    this.state.phoneCall && this.state.contact && this.state.script && this.state.scenario &&
                    <div>
                        <PhoneCallInput
                            onSaved={this.savePhoneCall}
                            toastManager={this.props.toastManager}
                            shiftId={this.state.phoneCall.shiftId}
                            script={this.state.script}
                            possibleResults={this.state.scenario.possibleResults}
                            call={this.state.phoneCall}
                            contact={this.state.contact}/>
                    </div>
                }
                {
                    this.state.isLoading && <AppSpinner/>
                }
            </ContentScreen>
        )
    }

}

export default withToastManager(CallViewScreen)
