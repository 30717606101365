import React, {RefObject} from "react";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import RemoteSearchTable, {SearchTableHeaderCell} from "../../components/search/RemoteSearchTable";
import {ApiElectorCommonTableParameters} from "../../../api/RequestModels";
import {RouteComponentProps} from "react-router";
import {ApiSortCriteria} from "../../../models/enums";
import {TableCell} from "@material-ui/core";
import {commonDateTimeFormat} from "../../../utils/Formatters";
import moment from "moment";
import {ApiAgitationMaterialsResponseRow} from "../../../api/ResponseModels";
import * as TypedUtils from '../../../utils/TypedUtils'
import {ApiUser} from "../../../api/Models";

const HEADER_CELLS: SearchTableHeaderCell[] = [
    {
        id: ApiSortCriteria.MATERIAL_TITLE,
        disablePadding: false,
        label: 'Материал',
        sortCriteria: ApiSortCriteria.MATERIAL_TITLE
    },
    {
        id: ApiSortCriteria.MATERIAL_TOTAL_COUNT,
        disablePadding: false,
        label: 'Общее количество, шт',
        sortCriteria: ApiSortCriteria.MATERIAL_TOTAL_COUNT
    },
    {id: 'availableCount', disablePadding: false, label: 'В наличии, шт'},
    {id: 'givenCount', disablePadding: false, label: 'Выдано, шт'},
    {id: 'createdBy', disablePadding: false, label: 'Автор'},
    {id: ApiSortCriteria.CREATED_AT, disablePadding: false, label: 'Создан', sortCriteria: ApiSortCriteria.CREATED_AT},
    {
        id: ApiSortCriteria.IS_ARCHIVED,
        disablePadding: false,
        label: 'Статус',
        sortCriteria: ApiSortCriteria.IS_ARCHIVED
    },
];


interface Props extends RouteComponentProps {
    onRowClicked: (row: ApiAgitationMaterialsResponseRow) => void
    filterRows?: (row: ApiAgitationMaterialsResponseRow) => boolean
    toastManager: ToastConsumerContext
}

class AgitationMaterialsSearchTable extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
        this.materialsTableRef = React.createRef()
    }

    materialsTableRef: RefObject<any> | undefined = undefined

    loadRows = async (params: ApiElectorCommonTableParameters) => {
        const users = await API.getAllMaterials(globalStore.getState().authToken, params)
        return users.rows
    }

    _renderCells = (row: ApiAgitationMaterialsResponseRow) => {
        return (
            [
                <TableCell component="th" scope="row">{row.title}</TableCell>,
                <TableCell component="th" scope="row">{row.totalSeriesCount}</TableCell>,
                <TableCell component="th" scope="row">{row.totalSeriesCount - row.totalSeriesGiven}</TableCell>,
                <TableCell component="th" scope="row">{row.totalSeriesGiven}</TableCell>,
                <TableCell component="th"
                           scope="row">{TypedUtils.renderUserLink(row.createdBy.fullName, row.createdBy.id)}</TableCell>,
                <TableCell align="left">{commonDateTimeFormat(moment(row.createdAt))}</TableCell>,
                <TableCell align="left">{TypedUtils.renderArchived(row.isArchived)}</TableCell>
            ]
        )
    }
    _reloadTable = () => {
        this.materialsTableRef?.current?.loadRowsAsync()
    }

    render() {
        return (
            <>
                <RemoteSearchTable
                    ref={this.materialsTableRef}
                    toastManager={this.props.toastManager}
                    searchPlaceholder={'Название материала, автор или общее количество'}
                    rowKeyExtractor={(u: ApiAgitationMaterialsResponseRow) => u.id.toString()}
                    renderTableCells={this._renderCells}
                    applyClassToRow={(u: ApiAgitationMaterialsResponseRow) => u.isArchived ? 'table-row__archived' : ''}
                    filterRows={this.props.filterRows ? this.props.filterRows : undefined}
                    headerCells={HEADER_CELLS}
                    loadRows={this.loadRows}
                    onRowSelected={this.props.onRowClicked}/>
            </>
        );
    }
}

export default withToastManager(AgitationMaterialsSearchTable)
