import React, {useMemo, useState} from "react";
import {useToasts} from "react-toast-notifications";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {ApiHqContactsGeoAggregatesResponse, ApiHqGeoAgitationResponse} from "../../../api/ResponseModels";
import {ApiElectorGeoMapsCommonParameters} from "../../../api/RequestModels";
import AppSpinner from "../../components/AppSpinner";
import {Gender, GeoMapsDataType} from "../../../models/enums";
import * as TypedUtils from "../../../utils/TypedUtils";
import {MAP_INFO_MODES} from "./MapTypes";

interface Props {
    hoverInfo: any
    infoMode: MAP_INFO_MODES
    aggregationParams: ApiElectorGeoMapsCommonParameters
}

export const MapHouseHoverTooltip = (props: Props) => {
    const [aggInfo, setAggInfo] = useState(undefined as ApiHqContactsGeoAggregatesResponse | ApiHqGeoAgitationResponse | undefined)
    const [isLoading, setIsLoading] = useState(false)
    const {addToast} = useToasts()
    useMemo(() => {
        setIsLoading(true)

        const getResultF = props.infoMode === MAP_INFO_MODES.CONTACTS ? API.loadGeoContactAggregates : API.loadGeoAgitationAggregates
        const resultPromise: Promise<ApiHqContactsGeoAggregatesResponse | ApiHqGeoAgitationResponse> = getResultF(
            {houseIds: [props.hoverInfo.feature.properties.houseId]},
            props.aggregationParams,
            globalStore.getState().authToken
        )
        resultPromise.then(aggInfo => {
            props.infoMode === MAP_INFO_MODES.CONTACTS ? aggInfo.type = "ApiHqContactsGeoAggregatesResponse" : aggInfo.type = "ApiHqGeoAgitationResponse"
            setAggInfo(aggInfo)
        }).catch(err => {
            console.log(err)
            addToast('Не удалось загрузить информацию по дому', {appearance: 'error'})
        }).finally(() => setIsLoading(false))
    }, [props.hoverInfo.feature.properties.houseId])

    const showEntrancesAgg = aggInfo && props.aggregationParams.dataTypes.includes(GeoMapsDataType.ENTRANCES)
        && aggInfo.entrances.length > 0

    const renderForContactInfo = (contactInfo: ApiHqContactsGeoAggregatesResponse) => {
        return (
            <>
                <div>
                    {`Контактов: `}
                    <span className={'text-success'}>{contactInfo.totalContactsCount}</span>
                </div>
                {
                    showEntrancesAgg &&
                    <div className='border-bottom col-12 border-top'>
                        <div>{`Подъездов пройдено ${contactInfo.entrances[0].rows.length} / ${contactInfo.houseInfo.entranceCount || '???'}`}</div>
                        {
                            contactInfo.entrances[0].rows.map((tR, idx) =>
                                <div key={idx}>
                                    {`Подъезд ${tR.entranceNo}: `}
                                    <span className={'text-warning'}>{tR.count}</span>
                                </div>
                            )
                        }
                    </div>
                }
                {
                    props.aggregationParams.dataTypes.includes(GeoMapsDataType.LivingQuarters) &&
                    <div className='border-bottom col-12 border-top'>
                        <div>
                            Жилых квартир:
                            <span
                                className={'text-warning'}> {contactInfo.houseInfo.livingQuartersSum ? contactInfo.houseInfo.livingQuartersSum : 'неизвестно'}</span>
                        </div>
                    </div>
                }
                {
                    props.aggregationParams.dataTypes.includes(GeoMapsDataType.THEMATICS) &&
                    <div className='border-bottom col-12'>
                        {contactInfo.thematics.map((tR, idx) => <div key={idx}>
                            {`${tR.value}: `}
                            <span className={'text-success'}>{tR.count}</span>
                        </div>)}
                    </div>
                }
                {
                    props.aggregationParams.dataTypes.includes(GeoMapsDataType.GENDER) &&
                    <div className='border-bottom col-12'>
                        <div>
                            {`Мужчин: `}
                            <span
                                className={'text-success'}>{contactInfo.genders.find(a => a.value === Gender.MALE)?.count || 0}</span>
                        </div>
                        <div>
                            {`Женщин: `}
                            <span
                                className={'text-success'}>{contactInfo.genders.find(a => a.value === Gender.FEMALE)?.count || 0}</span>
                        </div>
                    </div>
                }
                {
                    props.aggregationParams.dataTypes.includes(GeoMapsDataType.REACTION) &&
                    <div className='border-bottom col-12'>
                        {contactInfo.reactions.map((tR, idx) => <div key={idx}>
                            {`${TypedUtils.getReactionDisplayName(tR.value)}: `}
                            <span className={'text-success'}>{tR.count}</span>
                        </div>)}

                    </div>
                }
                {
                    props.aggregationParams.dataTypes.includes(GeoMapsDataType.AGE) &&
                    <div className='border-bottom col-12'>
                        {contactInfo.ages.map((tR, idx) => <div key={idx}>
                            {`${TypedUtils.getAgeDisplayName(tR.value)}: `}
                            <span className={'text-success'}>{tR.count}</span>
                        </div>)}
                    </div>
                }
                {
                    props.aggregationParams.dataTypes.includes(GeoMapsDataType.HAS_PHONE) &&
                    <div className='border-bottom col-12'>
                        <div>
                            {`Оставили телефон: `}
                            <span
                                className={'text-success'}>{contactInfo.hasPhones.find(a => a.value)?.count || 0}</span>
                        </div>
                    </div>
                }
                {
                    props.aggregationParams.dataTypes.includes(GeoMapsDataType.PHONE_CALLS) &&
                    <div className='border-bottom col-12'>
                        {contactInfo.thematics.map((tR, idx) => <div key={idx}>
                            {`${tR.value}: `}
                            <span className={'text-success'}>{tR.count}</span>
                        </div>)}
                    </div>
                }
            </>
        )
    }
    const renderForAgitationInfo = (agitationInfo: ApiHqGeoAgitationResponse) => {
        const rowsForEntrances = agitationInfo.entrances.flatMap(e => e.rows)
        return (
            <>
                {agitationInfo.materials.length > 0 &&
                <div className='border-bottom col-12 border-top'>
                    <div>Розданы материалы:</div>
                    {
                        agitationInfo.materials.map((materialInfo, idx) =>
                            <div key={idx}>
                                {materialInfo.value}&nbsp;-&nbsp;
                                <span
                                    className={'text-success'}> {materialInfo.count}</span>

                            </div>
                        )
                    }
                </div>
                }
                <div className='border-bottom col-12 border-top'>
                    <div>{`Подъездов пройдено ${rowsForEntrances.length} / ${agitationInfo.houseInfo.entranceCount || '???'}`}</div>
                    {
                        rowsForEntrances.map((tR, idx) =>
                            <div key={idx}>
                                {`Подъезд ${tR.entranceNo}: `}
                                {
                                    tR.materialsAndCounts.map(matAndCount =>
                                        <div>
                                            <span>{matAndCount.materialTitle}</span>
                                            &nbsp;-&nbsp;
                                            {
                                                matAndCount.count === 0
                                                    ? <span className={'text-danger'}>НЕУДАЧНО</span>
                                                    : <span className={'text-success'}>{matAndCount.count}</span>
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
                <div className='border-bottom col-12 border-top'>
                    <div>
                        Жилых квартир:
                        <span
                            className={'text-warning'}> {agitationInfo.houseInfo.livingQuartersSum ? agitationInfo.houseInfo.livingQuartersSum : 'неизвестно'}</span>
                    </div>
                </div>
            </>
        )
    }
    return (
        <div className='map-hover-tooltip' style={{left: props.hoverInfo.x, top: props.hoverInfo.y,}}>
            <div>{props.hoverInfo.feature.properties.address}</div>
            {
                isLoading && <AppSpinner size={16}/>
            }
            {
                !isLoading && aggInfo &&
                <div className='d-flex flex-column align-items-center'>
                    {
                        aggInfo?.type === 'ApiHqContactsGeoAggregatesResponse' &&
                        renderForContactInfo(aggInfo as ApiHqContactsGeoAggregatesResponse)
                    }
                    {
                        aggInfo?.type === 'ApiHqGeoAgitationResponse' &&
                        renderForAgitationInfo(aggInfo as ApiHqGeoAgitationResponse)
                    }
                </div>
            }

        </div>
    )
}
