import ContentScreen from "../../ContentScreen";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import AppSpinner from "../../components/AppSpinner";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {useToasts} from "react-toast-notifications";
import {mapUserToRequest} from "../../../api/ApiRequestMappers";
import {UserModel, UserSummaryInfo} from "../../../models/UserModel";
import UserInput from "./UserInput";
import moment from "moment";
import {UserRole} from "../../../models/enums";

interface Props {
}

const EMPTY_USER_MODEL: UserModel = {
    id: 0,
    name: '',
    surname: '',
    birthday: moment(),
    phoneNumber: '',
    role: UserRole.SCOUT,
    isActive: true,
    updatedAt: moment(),
    createdAt: moment(),
    login: '',
    totalPaid: 0,
    latestPayments: []
}

const ContactViewScreen = (props: Props) => {
    const history = useHistory();
    const [user, setUser] = useState(undefined as UserModel | undefined)
    const {addToast} = useToasts()
    const authToken = globalStore.getState().authToken

    // @ts-ignore
    const userId = props.match.params.id

    async function retrieveUserAsync() {
        const response = await API.getUserById(userId, globalStore.getState().authToken)
        setUser(response)
    }

    useEffect(() => {
        if (userId != 0) {
            retrieveUserAsync().catch(e =>
                addToast('Не удалось загрузить информацию о контакте', {appearance: 'error'})
            )
        } else if (userId) {
            setUser({
                ...EMPTY_USER_MODEL
            })
        } else {
            history.push('/')
        }
    }, [userId])

    const onSaveUserPressed = async () => {
        if (user) {
            try {
                const newUser = await API.upsertUser(mapUserToRequest(user), authToken)
                if (user.id) {
                    addToast('Пользователь успешно обновлён', {appearance: "success"})
                } else {
                    addToast('Пользователь успешно создан', {appearance: "success"})
                }
                setUser(newUser)
                history.replace(`/user/${newUser.id}`)
                return false
            } catch (e) {
                addToast('Не удалось сохранить пользователя!', {appearance: "error"})
                return true
            }
        } else return false
    }

    return (
        <ContentScreen>
            {
                user &&
                <UserInput
                    onSaveUser={onSaveUserPressed}
                    onCancelPressed={() => {
                        history.goBack()
                    }}
                    onUserDelete={() => {
                    }}
                    onUserEdited={setUser}
                    reloadUser={retrieveUserAsync}
                    user={user}/>
            }
            {
                !user && <AppSpinner/>
            }
        </ContentScreen>
    )
}

export default ContactViewScreen
