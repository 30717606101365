import * as React from 'react'
import '../ProfileScreen.css'
import ContentScreen from "../../ContentScreen";
import PaymentsSearchTable from "./PaymentsSearchTable";
import {ApiPaymentsResponseRow} from "../../../api/ResponseModels";
import AddPaymentModal from "../../components/AddPaymentModal";
import {RefObject} from "react";

interface State {
    currentlyShownPayment?: ApiPaymentsResponseRow
}
export default class FinancesScreen extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = ({
            currentlyShownPayment: undefined
        })
        this.tableRef = React.createRef()
    }
    tableRef: RefObject<any> | undefined = undefined

    onPaymentCreated = () => {
        this.tableRef?.current?._reloadTable()
    }
    render() {
        return (
            <ContentScreen>
                <div className='mx-auto w-100 mt-2 mb-2 d-flex gx-2 flex-column align-items-center'>
                    <div className='row col-12'>
                        <div className="col-auto h4 fw-normal mb-4">
                            <svg className="icon icon-24 text-primary align-text-bottom me-2">
                                <use href="#credit-card-16"/>
                            </svg>
                            Финансы
                        </div>
                    </div>
                    <div className='col-10'>
                        <PaymentsSearchTable
                            ref={this.tableRef}
                            onRowSelected={(row: ApiPaymentsResponseRow) => this.setState({currentlyShownPayment: row})}
                        />
                    </div>
                    {
                        this.state.currentlyShownPayment &&
                        <AddPaymentModal
                            userId={this.state.currentlyShownPayment.targetUser.id}
                            visible={Boolean(this.state.currentlyShownPayment)}
                            initialPayment={this.state.currentlyShownPayment}
                            toggleVisibility={() => this.setState({currentlyShownPayment: undefined})}
                            onPaymentCreated={this.onPaymentCreated}/>
                    }

                </div>
            </ContentScreen>

        )
    }


}
