import React from "react";
import {Modal} from "react-bootstrap-v5";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import NumericInput from "react-numeric-input";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {ApiAgitationMaterialsResponseRow} from "../../../api/ResponseModels";
import Icon from "@iconify/react";
import archive24 from "@iconify/icons-octicon/archive-24";

interface State {
    title: string
    seriesTotal: number
    isArchived: boolean
    error: string
}

interface Props {
    onCancel: () => void
    onSaved: () => void
    toastManager: ToastConsumerContext
    initialMaterial?: ApiAgitationMaterialsResponseRow
}

class EditMaterialModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            title: props.initialMaterial?.title || '',
            seriesTotal: props.initialMaterial?.totalSeriesCount || 0,
            isArchived: props.initialMaterial?.isArchived || false,
            error: ''
        }
    }

    saveMaterial = () => {
        const saveF = async () => {
            await API.saveMaterial(
                this.props.initialMaterial?.id || 0,
                {
                    title: this.state.title.trim(),
                    seriesTotal: this.state.seriesTotal,
                    isArchived: this.state.isArchived
                }, globalStore.getState().authToken
            )
            this.props.toastManager.add('Материал успешно сохранён', {
                appearance: 'success',
            });
            this.props.onSaved()
        }
        if (this.state.title.trim()) {
            saveF().catch(err => {
                console.log(err)
                this.props.toastManager.add('Не удалось сохранить материал!', {
                    appearance: 'error',
                });
            })
        } else {
            this.setState({error: 'Необходимо указать название'})
        }

    }

    render() {
        return (
            <Modal show={true} onHide={this.props.onCancel} centered>
                <Modal.Body>
                    <main id="call-script-editor" className="container-fluid">
                        <div className="max-w-md shadow-lg mb-4">
                            <div
                                className="bg-dark text-white text-center fw-500 py-3 d-flex flex-row justify-content-between align-items-center">
                                <span className='ms-5'>Агитационный материал</span>
                            </div>
                            <div className="bg-white pt-2 pb-4 px-4">
                                {
                                    this.state.error && <div
                                        className='col-12 border-top font-monospace mt-1 fw-bold text-red text-center'>{this.state.error}</div>
                                }
                                <div className='row'>
                                    <label htmlFor="materialTitle" className="col-form-label col-6 form-label-required">Название
                                        материала</label>
                                    <div className="col-12">
                                        <input id="materialTitle" className="form-control"
                                               value={this.state.title || ''}
                                               placeholder="..."
                                               type="text" onChange={e => this.setState({title: e.target.value})}/>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label htmlFor="seriesTotal"
                                           className="col-form-label col-6 form-label-required">Выпуск,
                                        штук</label>
                                    <div className="col-12">
                                        <NumericInput
                                            id="seriesTotal"
                                            className="form-control"
                                            min={0}
                                            placeholder="штук"
                                            onChange={n => this.setState({seriesTotal: n || 0})}
                                            value={this.state.seriesTotal}/>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className="col-form-label col-3">Статус</label>
                                    <div className="col-12 mb-3">
                                        <div className="btn-group w-100">
                                            <input id="active" className="btn-check" type="radio" name="status"
                                                   checked={!this.state.isArchived}
                                                   onClick={() => this.setState({isArchived: false})}/>
                                            <label htmlFor="active"
                                                   className="btn btn-outline-green">Активен</label>
                                            <input id="inactive" className="btn-check" type="radio" name="status"
                                                   checked={this.state.isArchived}
                                                   onClick={() => this.setState({isArchived: true})}/>
                                            <label htmlFor="inactive"
                                                   className="btn btn-outline-secondary">
                                                <Icon icon={archive24} color={!this.state.isArchived? 'grey': 'white'} className='me-2'/>
                                                Архив
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white text-center sticky-bottom border-top p-4">
                                    <div className="row gx-4">
                                        <div className="col-6">
                                            <button id="cancel"
                                                    onClick={this.props.onCancel}
                                                    className="btn btn-danger w-100 fw-500">Отмена
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button id="save" onClick={this.saveMaterial}
                                                    className="btn btn-success w-100 fw-500">{
                                                        this.props.initialMaterial? 'Обновить': 'Сохранить'
                                            }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Modal.Body>
            </Modal>
        )
    }

}

export default withToastManager(EditMaterialModal)
