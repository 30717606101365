import React from "react";
import {Modal} from "react-bootstrap-v5";
import {CallScriptModel} from "../../../models/CallScriptModel";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import Icon from "@iconify/react";
import archive24 from "@iconify/icons-octicon/archive-24";

interface State {
    scriptName: string
    content: string
    isArchived: boolean
}

interface Props {
    onCancel: () => void
    script: CallScriptModel
    onSaved: () => void
    toastManager: ToastConsumerContext
}

class CallScriptEditModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            scriptName: props.script.title,
            isArchived: props.script.isArchived,
            content: props.script.content
        }
    }

    setScriptName = (newValue: string) => this.setState({scriptName: newValue})
    setScriptContent = (newValue: string) => this.setState({content: newValue})
    saveScript = () => {
        const saveScriptF = async () => {
            await API.saveCallScript({
                title: this.state.scriptName,
                content: this.state.content,
                id: this.props.script.id,
                isArchived: this.state.isArchived
            }, globalStore.getState().authToken)
            this.props.onSaved()
        }
        saveScriptF().catch(err => {
            console.log(err)
            this.props.toastManager.add('Не удалось сохранить скрипт!', {
                appearance: 'error',
            });
        })


    }

    render() {
        return (
            <Modal show={true} onHide={this.props.onCancel} centered>
                <Modal.Body>
                    <main id="call-script-editor" className="container-fluid">
                        <div className="max-w-md shadow-lg mb-4">
                            <div
                                className="bg-dark text-white text-center fw-500 py-3 d-flex flex-row justify-content-between align-items-center">
                            <span className='ms-5'>
                                Скрипт звонка
                            </span>
                            </div>
                            <div className="bg-white pt-2 pb-4 px-4">
                                <div className='row'>
                                    <label htmlFor="scriptName" className="col-form-label col-6 form-label-required">Название
                                        скрипта</label>
                                    <div className="col-12 mb-3">
                                        <input id="scriptName" className="form-control"
                                               value={this.state.scriptName || ''}
                                               placeholder="..."
                                               type="text" onChange={e => this.setScriptName(e.target.value)}/>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label htmlFor="scriptContent"
                                           className="col-form-label col-6 form-label-required">Содержание</label>
                                    <div className="col-12 mb-3">
                                        <textarea id="scriptContent" className="form-control"
                                                  value={this.state.content || ''}
                                                  placeholder="..."
                                                  style={{minHeight: '20em'}}
                                                  maxLength={10000}
                                                  onChange={e => this.setScriptContent(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="btn-group w-100">
                                        <input id="active" className="btn-check" type="radio" name="status"
                                               checked={!this.state.isArchived}
                                               onClick={() => this.setState({isArchived: false})}/>
                                        <label htmlFor="active"
                                               className="btn btn-outline-green">Активен</label>
                                        <input id="inactive" className="btn-check" type="radio" name="status"
                                               checked={this.state.isArchived}
                                               onClick={() => this.setState({isArchived: true})}/>
                                        <label htmlFor="inactive"
                                               className="btn btn-outline-secondary">
                                            <Icon icon={archive24}
                                                  color={!this.state.isArchived ? 'grey' : 'white'}
                                                  className='me-2'/>
                                            Архив
                                        </label>
                                    </div>
                                </div>
                                <div className="bg-white text-center sticky-bottom border-top p-4">
                                    <div className="row gx-4">
                                        <div className="col-6">
                                            <button id="cancel"
                                                    onClick={this.props.onCancel}
                                                    className="btn btn-danger w-100 fw-500">Отмена
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button id="save" onClick={this.saveScript}
                                                    className="btn btn-success w-100 fw-500">Сохранить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Modal.Body>
            </Modal>
        )
    }

}

export default withToastManager(CallScriptEditModal)
