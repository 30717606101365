import React from "react";
import './PaymentTooltip.scss'
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {APICampaignSettings} from "../../../api/Models";
import AppSpinner from "../../components/AppSpinner";
import {ApiPaymentSuggestionResponse} from "../../../api/ResponseModels";
import moment from "moment";
import ReactTooltip from "react-tooltip";

interface State {
    suggestion?: ApiPaymentSuggestionResponse
}

interface Props {
    settings?: APICampaignSettings // if example needed
    userId?: number // normally use this
}

export default class PaymentTooltip extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = ({
            suggestion: undefined
        })
    }
    componentDidMount() {
        this._suggestPaymentAsync()
    }

    _suggestPaymentAsync = async () => {
        if (this.props.settings) { //example needed
            const res = await API.getExamplePaymentSuggestionForSettings(this.props.settings, globalStore.getState().authToken)
            this.setState({suggestion: res})
        } else if (this.props.userId) {
            const res = await API.getPaymentSuggestionForUser(this.props.userId, globalStore.getState().authToken)
            this.setState({suggestion: res})
        }
    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.settings !== this.props.settings || prevProps.userId !== this.props.userId)
            this._suggestPaymentAsync()
        ReactTooltip.rebuild() // sometimes not shown without that
    }
    _renderDay = (dayStr: string, reason: string, key: number) => {
        const formattedDay = moment(dayStr).format("DD.MM")
        return (
            <div key={key} className='mb-1'>
                <div className='text-bold'>
                    {formattedDay}:
                </div>
                 <div className='ms-2 text-green'>
                     {reason}
                 </div>
            </div>
        )
    }

    render() {
        return (
                <div className='payment-description-tooltip shadow'>
                    {
                        this.state.suggestion?.description &&
                            <div className='payment-tooltip__description mb-1'>
                                {
                                    this.state.suggestion?.description
                                }
                            </div>

                    }
                    {
                        this.state.suggestion?.dailyReasons.map(([day, reason], idx) =>
                            this._renderDay(day, reason, idx)
                        )
                    }
                    {
                        this.state.suggestion?.suggestedPaymentSum &&
                            <div className='mt-1 pt-1 payment-tooltip__sum'>
                                <span className='me-1'>Предполагаемая выплата:</span>
                                <span className='fw-bold'>{this.state.suggestion?.suggestedPaymentSum}</span>
                            </div>
                    }
                    {
                        !this.state.suggestion &&
                            <AppSpinner size={32}/>
                    }
                </div>
        )
    }


}
