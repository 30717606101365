import {Checkbox, Size, TableCell, TableHead, TableRow, TableSortLabel} from "@material-ui/core";
import {ApiSortCriteria, ApiSortDirection} from "../../../models/enums";
import React from "react";
import {SearchTableHeaderCell} from "./RemoteSearchTable";

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (property: ApiSortCriteria) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    sortingOrder: ApiSortDirection;
    orderBy?: ApiSortCriteria;
    rowCount: number;
    hasMultipleRowSelection: boolean,
    hasRowExpansion: boolean,
    cells: SearchTableHeaderCell[]
    size: Size
}

export const RemoteSearchTableHeader = (props: EnhancedTableProps) => {
    const {
        onSelectAllClick,
        hasMultipleRowSelection,
        sortingOrder,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        hasRowExpansion
    } = props;
    return (
        <TableHead>
            <TableRow>
                {
                    hasMultipleRowSelection &&
                    <TableCell
                        align={'left'}
                        padding={'none'}
                    >
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{'aria-label': 'Выбрать все'}}
                        />
                    </TableCell>
                }
                {
                    hasRowExpansion &&
                    <TableCell padding="checkbox" />
                }
                {props.cells.map((headCell, index) => {
                    return (
                        <TableCell
                            key={headCell.id}
                            align={'left'}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCell.id ? sortingOrder : false}
                        >
                            {
                                headCell.sortCriteria ?
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? sortingOrder : 'asc'}
                                        onClick={() => onRequestSort(headCell.sortCriteria || ApiSortCriteria.FULL_ADDRESS)}
                                    >
                                        {headCell.label}
                                    </TableSortLabel> : <span>{headCell.label}</span>
                            }

                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}
