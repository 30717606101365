import * as React from 'react'
import ContentScreen from "../../ContentScreen";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {
    APICallScriptResponse,
    ApiCallsGroupedByScenarioResponseContact,
    ApiCallsGroupedByScenarioResponseRow
} from "../../../api/ResponseModels";
import Icon from "@iconify/react";
import comment16 from "@iconify/icons-octicon/comment-16";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import MyCallsSearchTable from "./MyCallsSearchTable";
import PhoneCallModal from "./PhoneCallModal";
import {APIScenarioPossibleResult} from "../../../api/Models";


interface Props extends RouteComponentProps {
    toastManager: ToastConsumerContext
}
interface PhoneCallData {
    contact: ApiCallsGroupedByScenarioResponseContact
    script: APICallScriptResponse
    possibleResults: APIScenarioPossibleResult[]
    shiftId: number
}

interface State {
    callRowsGroupedByScenario: ApiCallsGroupedByScenarioResponseRow[]
    currentlyShownData?: PhoneCallData
    isLoading: boolean
}

class MyCallsScreen extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = ({
            callRowsGroupedByScenario: [] as ApiCallsGroupedByScenarioResponseRow[],
            currentlyShownData: undefined as PhoneCallData | undefined,
            isLoading: false
        })
    }
    _loadRowsAsync = () => {
        const loadF = async () => {
            const callsResponse = await API.getMyCalls(globalStore.getState().authToken)
            this.setState({callRowsGroupedByScenario: callsResponse.rows})
        }
        this.setState({isLoading: true}, () => {
            loadF().finally(() => this.setState({isLoading: false}))
                .catch(err => {
                    console.log(err)
                    this.props.toastManager.add('Не удалось загрузить контакты для звонков!', {
                        appearance: 'error',
                    });
                })
        })
    }

    async componentDidMount() {
        this._loadRowsAsync()
    }
    onRowSelected = (r: ApiCallsGroupedByScenarioResponseContact, cg: ApiCallsGroupedByScenarioResponseRow) => {
        this.setState({
            currentlyShownData: {
                shiftId: cg.shiftId,
                script: cg.script,
                possibleResults: cg.possibleResults,
                contact: r
            }
        })
    }
    onPhoneCallSaved = () => {
        this.setState({
            currentlyShownData: undefined
        }, () => {
            this.props.toastManager.add('Звонок успешно сохранён', {
                appearance: 'success',
            });
            this._loadRowsAsync()

        })
    }

    render() {
        return (
            <ContentScreen>
                <div className='mx-auto w-100 mt-2 mb-2 d-flex gx-2 flex-column align-items-center'>
                    <div className='row col-12'>
                        <div className="col-auto h4 fw-normal mb-4">
                            <Icon icon={comment16} className='icon icon-24 text-primary align-text-bottom me-2'/>
                            Мои звонки
                        </div>
                    </div>
                    {
                        !this.state.isLoading && this.state.callRowsGroupedByScenario.map((cg, idx) =>
                            <div className='col-10 col-xs-12' key={idx}>
                                <h5 className='my-4'>{`Сценарий: ${cg.scenarioTitle}`}</h5>
                                <MyCallsSearchTable
                                    onRowSelected={(r: ApiCallsGroupedByScenarioResponseContact) => this.onRowSelected(r, cg)}
                                    source={cg}/>
                            </div>
                        )
                    }
                    {
                        this.state.currentlyShownData &&
                        <PhoneCallModal
                            onSaved={this.onPhoneCallSaved}
                            onCancel={() => this.setState({currentlyShownData: undefined})}
                            shiftId={this.state.currentlyShownData.shiftId}
                            script={this.state.currentlyShownData.script}
                            phoneCallId={this.state.currentlyShownData.contact.callId}
                            possibleResults={this.state.currentlyShownData.possibleResults}
                            contactId={this.state.currentlyShownData.contact.contact.id}
                            toastManager={this.props.toastManager}/>
                    }
                </div>
            </ContentScreen>

        )
    }

}

export default withToastManager(withRouter(MyCallsScreen))
