import React from "react";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import AppSpinner from "../../components/AppSpinner";
import {Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import {commonDateTimeFormat} from "../../../utils/Formatters";
import * as _ from "lodash";
import {ApiScenariosBriefInfoResponseRow} from "../../../api/ResponseModels";
import moment from "moment";
import * as TypedUtils from "../../../utils/TypedUtils";

interface State {
    rows: ApiScenariosBriefInfoResponseRow[]
    shownRows: ApiScenariosBriefInfoResponseRow[]
    isLoading: boolean
}

interface Props {
    toastManager: ToastConsumerContext
    onRowSelected?: (scenario: ApiScenariosBriefInfoResponseRow) => void
    filterRows?: (scenario: ApiScenariosBriefInfoResponseRow) => boolean
}

class CallScenariosSearchTable extends React.Component<Props, State> {
    state = {
        rows: [] as ApiScenariosBriefInfoResponseRow[],
        shownRows: [] as ApiScenariosBriefInfoResponseRow[],
        isLoading: true
    }
    _reloadScenariosAsync = () => {
        const loadAndSetState = async () => {
            const response = await API.getAllScenarios(globalStore.getState().authToken)
            let shownRows = response.rows
            if (this.props.filterRows) {
                shownRows = shownRows.filter(this.props.filterRows)
            }
            this.setState({rows: response.rows, shownRows: shownRows})
        }
        this.setState({isLoading: true}, () =>
            loadAndSetState()
                .finally(() => this.setState({isLoading: false}))
                .catch(err => {
                    this.props.toastManager.add('Не удалось загрузить сценарии звонков!', {
                        appearance: 'error',
                    });
                    console.log(err)
                })
        )
    }

    componentDidMount() {
        this._reloadScenariosAsync()
    }

    onSearchQueryChanged = _.debounce((q: string) => {
        const trimmedQuery = q.trim().toLowerCase()
        if (!trimmedQuery) this.setState({shownRows: this.state.rows})
        else {
            this.setState({
                shownRows: this.state.rows.filter(s =>
                    s.title.toLowerCase().includes(trimmedQuery) ||
                    s.createdBy?.fullName?.toLowerCase().includes(trimmedQuery)
                ).filter(this.props.filterRows? this.props.filterRows: () => true)
            })
        }

    }, 500)

    render() {
        return (
            <>
                {
                    this.state.isLoading &&
                    <div className='app-spinner-fixed'>
                        <AppSpinner/>
                    </div>
                }
                {
                    <div>
                        <div className="col-auto col-sm ms-auto mt-n2">
                            <div className="input-group my-1">
                                <span className="input-group-text">Поиск</span>
                                <input type="text" className="form-control"
                                       onChange={e => this.onSearchQueryChanged(e.target.value)}
                                       placeholder='Название или автор'/>
                            </div>
                        </div>
                        <TableContainer component={Paper}>
                            <Table aria-label="callscenarios">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Название</TableCell>
                                        <TableCell align="right">Автор</TableCell>
                                        <TableCell align="right">Создан</TableCell>
                                        <TableCell align="right">Статус</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.shownRows.map((row) => (
                                        <TableRow key={row.title}
                                                  style={{cursor: 'pointer'}}
                                                  hover
                                                  onClick={() => this.props.onRowSelected && this.props.onRowSelected(row)}>
                                            <TableCell component="th" scope="row">{row.title}</TableCell>
                                            <TableCell align="right">{row.createdBy.fullName}</TableCell>
                                            <TableCell
                                                align="right">{commonDateTimeFormat(moment(row.createdAt))}</TableCell>
                                            <TableCell
                                                align="right">{TypedUtils.renderArchived(row.isArchived)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }
                {
                    !this.state.isLoading && this.state.rows.length === 0 &&
                    <div className='text-center'>
                        Подходящие сценарии не найдены!
                    </div>
                }
            </>
        );
    }
}

export default withToastManager(CallScenariosSearchTable)
