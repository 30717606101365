

export const API_HOST = 'https://elector-hq.nikita.ovh/api'

export const COLORS = {
    RED: '#f56565',
    BLUE: '#206bc4',
    ORANGE: '#ed8936',
    GREEN: '#48bb78',
    GREY600: '#718096',
    GREY400: '#cbd5e0',
    GREY200: '#edf2f7',
    INDIGO: '#667eea',
}
