import * as React from 'react'
import ContentScreen from "../../ContentScreen";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import CallScenariosSearchTable from "./CallScenariosSearchTable";
import {ApiScenariosBriefInfoResponseRow} from "../../../api/ResponseModels";


interface Props extends RouteComponentProps {
    toastManager: ToastConsumerContext
}

interface State {
}

class CallScenariosScreen extends React.Component<Props, State> {
    goToScenarioEditScreen = (id: number) => {
        this.props.history.push(`/call-scenarios/${id}`)
    }

    render() {
        return (
            <ContentScreen>
                <div className='mx-auto w-100 mt-2 mb-2 d-flex gx-2 flex-column align-items-center'>
                    <div className='row col-12'>
                        <div className="col-auto h4 fw-normal mb-4">
                            <svg className="icon icon-24 text-primary align-text-bottom me-2">
                                <use href="#checklist-24"/>
                            </svg>
                            Сценарии звонков
                        </div>
                        <div className="col-auto ms-auto mt-n1">
                            <button className="btn btn-outline-primary"
                                    onClick={() => this.goToScenarioEditScreen(0)}>
                                <svg className="icon icon-16 me-1">
                                    <use href="#plus-16"/>
                                </svg>
                                <svg className="icon icon-16 me-2">
                                    <use href="#checklist-16"/>
                                </svg>
                                Новый сценарий
                            </button>
                        </div>
                    </div>

                    <div className='col-8 col-xs-12'>
                        <CallScenariosSearchTable
                            onRowSelected={(r: ApiScenariosBriefInfoResponseRow) => this.goToScenarioEditScreen(r.id)}/>
                    </div>
                </div>
            </ContentScreen>

        )
    }

}

export default withToastManager(withRouter(CallScenariosScreen))
