import ContentScreen from "../../ContentScreen";
import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {ContactModel} from "../../../models/ContactModel";
import ContactInput from "../ContactInput";
import AppSpinner from "../../components/AppSpinner";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {useToasts} from "react-toast-notifications";
import {mapContactToRequest} from "../../../api/ApiRequestMappers";
import {Age, ContactType, Gender, Reaction} from "../../../models/enums";
import moment from "moment";
import * as RoleUtils from '../../../utils/RoleUtils'
import {ApiContactInfoResponseRow} from "../../../api/ResponseModels";
import * as ApiResponseMappers from '../../../api/ApiResponseMappers'
interface Props {
}
const THEMATICS_UNKNOWN = 'Неизвестна'
const EMPTY_CONTACT_MODEL: ContactModel = {
    name: '',
    gender: Gender.FEMALE,
    reaction: Reaction.GOOD,
    thematics: [THEMATICS_UNKNOWN],
    age: Age.ADULT, // dont change or handle properly in contactInput
    contactType: ContactType.STREET_PROMO,
    createdAt: moment(),
    isArchived: false
}

const ContactViewScreen = (props: Props) => {
    const history = useHistory();
    const [contact, setContact] = useState(undefined as ContactModel | undefined)
    const [rawApiContact, setRawApiContact] = useState(undefined as ApiContactInfoResponseRow | undefined)
    const {addToast} = useToasts()
    const authToken = globalStore.getState().authToken
    const THEMATICS = globalStore.getState().externalAdditionalInfo.thematics

    // @ts-ignore
    const contactId = props.match.params.id

    useEffect(() => {
        async function retrieveContactAsync() {
            const response = await API.getContactById(contactId, globalStore.getState().authToken)
            const contactModelFromApi = ApiResponseMappers.mapApiContactToContact(response)
            setRawApiContact(response)
            setContact(contactModelFromApi)
        }

        if (contactId != 0){
            retrieveContactAsync().catch(e =>
                addToast('Не удалось загрузить информацию о контакте', {appearance: 'error'})
            )
        } else if (contactId) {
            setContact({
                ...EMPTY_CONTACT_MODEL,
                ...{
                    thematics: THEMATICS.length > 0 ? [THEMATICS[0]] : [THEMATICS_UNKNOWN]
                }
            })
        } else {
            history.push('/')
        }
    }, [contactId])

    const onContactDelete = async () => {
        if (contact?.id) {
            try {
                if (window.confirm("Вы уверены, удалить контакт? Все имеющиеся звонки по данному контакту также будут удалены")) {
                    await API.deleteContact(contact.id, authToken)
                    history.push('/contacts')
                    addToast('Контакт успешно удалён', {appearance: "success"})
                }


            } catch (e) {
                addToast('Не удалось удалить контакт!', {appearance: "error"})
            }
        }
    }
    const onSaveContactPressed = async () => {
        if (contact) {
            try {
                await API.upsertContact(mapContactToRequest(contact, contact.geo), authToken)
                if (contact.id) {
                    addToast('Контакт успешно обновлён', {appearance: "success"})
                } else {
                    addToast('Контакт успешно создан', {appearance: "success"})
                    history.push('/contacts')
                }
            } catch (e) {
                addToast('Не удалось сохранить контакт!', {appearance: "error"})
            }
        }
    }
    const hasPermissionToEdit = RoleUtils.hasContactEditPermission(globalStore.getState().externalAdditionalInfo.user.role)

    return (
        <ContentScreen>
            {
                contact &&
                <ContactInput
                    onSaveContact={onSaveContactPressed}
                    onCancelPressed={() => history.goBack()}
                    onContactDelete={onContactDelete}
                    onContactEdited={hasPermissionToEdit? c => setContact(c): undefined}
                    rawApiContact={rawApiContact}
                    contact={contact}
                    contactsCount={1}
                    contactNo={0}
                />
            }
            {
                !contact && <AppSpinner/>
            }
        </ContentScreen>
    )
}

export default ContactViewScreen
