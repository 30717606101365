import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {ApiIsArchivedFilterValues} from "../../../../models/ApiFilterEnums";

interface Props {
    value: ApiIsArchivedFilterValues
    setValue: (v: ApiIsArchivedFilterValues) => void
    defaultValue: ApiIsArchivedFilterValues
}

type POSSIBLE_OPTIONS_TYPE = 'Только активные' | 'Только архивные' | 'Все'

const optTypeToValue = (s: POSSIBLE_OPTIONS_TYPE) => {
    if (s === 'Только активные') return ApiIsArchivedFilterValues.NOT_ARCHIVED
    else if (s === 'Только архивные') return ApiIsArchivedFilterValues.ARCHIVED
    else return ApiIsArchivedFilterValues.ALL
}

function apiTypeToOpt(t: ApiIsArchivedFilterValues): POSSIBLE_OPTIONS_TYPE {
    if (t === ApiIsArchivedFilterValues.NOT_ARCHIVED) return 'Только активные'
    else if (t === ApiIsArchivedFilterValues.ARCHIVED) return 'Только архивные'
    else return 'Все'
}

export default function IsArchivedTableFilter(props: Props) {
    const [inputValue, setInputValue] = React.useState('');

    return (
        <Autocomplete
            size={'small'}
            value={apiTypeToOpt(props.value)}
            onChange={(e, value) => {
                props.setValue(optTypeToValue(value || 'Только активные'))
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            options={['Только активные', 'Только архивные', 'Все'] as POSSIBLE_OPTIONS_TYPE[]}
            renderInput={(params) =>
                <TextField {...params} label="Статус" variant="outlined"/>
            }
        />
    );
}
