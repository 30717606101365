import React, {RefObject} from "react";
import ContentScreen from "../ContentScreen";
import {Button} from "react-bootstrap-v5";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";
import Icon, {IconifyIcon} from "@iconify/react";
import home16 from "@iconify/icons-octicon/home-16";
import plus16 from "@iconify/icons-octicon/plus-16";
import fileSymlinkFile16 from "@iconify/icons-octicon/file-symlink-file-16";
import book16 from "@iconify/icons-octicon/book-16";
import AddMaterialModal from "./field/EditMaterialModal";
import AgitationMaterialsSearchTable from "./field/AgitationMaterialsSearchTable";
import {ApiAgitationDistributionsResponseRow, ApiAgitationMaterialsResponseRow} from "../../api/ResponseModels";
import AgitationDistributionEditModal from "./field/AgitationDistributionEditModal";
import AgitationMaterialsByUsersSearchTable from "./field/AgitationMaterialsByUsersSearchTable";
import megaphone16 from "@iconify/icons-octicon/megaphone-16";
import AgitationDistributionsSearchTable from "./field/AgitationDistributionsSearchTable";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";

interface Props extends RouteComponentProps {
    toastManager: ToastConsumerContext
}

interface State {
    showAddModal: boolean
    currentlyShownMaterial?: ApiAgitationMaterialsResponseRow
}

class FieldScreen extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = ({
            showAddModal: false,
            currentlyShownMaterial: undefined
        })
        this.materialsTableRef = React.createRef()
        this.materialsByUsersTableRef = React.createRef()
    }

    materialsTableRef: RefObject<any> | undefined = undefined
    materialsByUsersTableRef: RefObject<any> | undefined = undefined

    onMaterialCreated = () => {
        this.setState({showAddModal: false}, () => {
            this.materialsTableRef?.current?._reloadTable()
        })
    }
    onMaterialDistributionSaved = () => {
        this.setState({showAddModal: false}, () => {
            this.materialsByUsersTableRef?.current?._reloadTable()
        })
    }
    navigateToAnchor = (anchor: string) => {
        window.location.hash = anchor
        this.setState({currentlyShownMaterial: undefined})
    }

    render() {
        const renderAddButton = (title: string, icon: object) => {
            return (
                <Button
                    onClick={() => this.setState({showAddModal: true})}
                    variant={'outline-primary'}>
                    <Icon icon={plus16} className='me-1'/>
                    <Icon icon={icon} className='me-2'/>
                    {title}
                </Button>
            )
        }
        const isDistributionView = window.location.href.includes('#distribution')
        const isMaterialsView = window.location.href.includes('#materials')
        const isAllDistributionsView = !isDistributionView && !isMaterialsView

        return (
            <ContentScreen>
                <div className='mx-auto w-100 mt-2 mb-2 d-flex gx-2 flex-column align-items-center'>
                    <div className='row col-12 justify-content-between'>
                        <div className="col-auto h4 fw-normal">
                            <Icon icon={megaphone16} className='icon icon-24 text-primary align-text-bottom me-2'/>
                            Поле
                        </div>
                        <div className='col-auto'>
                            {
                                isMaterialsView &&
                                renderAddButton('Новый материал', book16)
                            }
                            {
                                isDistributionView &&
                                renderAddButton('Новая разноска', fileSymlinkFile16)
                            }
                        </div>
                    </div>
                    <div className="mb-1 btn-group d-flex justify-content-center">
                        <Button size={'lg'}
                                variant={isAllDistributionsView ? 'primary' : 'outline-primary'}
                                onClick={() => this.navigateToAnchor('#allDistributions')}>Все разноски</Button>
                        <Button size={'lg'}
                                variant={isMaterialsView ? 'primary' : 'outline-primary'}
                                onClick={() => this.navigateToAnchor('#materials')}>Материалы</Button>
                        <Button size={'lg'}
                                variant={isDistributionView ? 'primary' : 'outline-primary'}
                                onClick={() => this.navigateToAnchor('#distribution')}>Разноски</Button>
                    </div>
                    {
                        this.state.showAddModal && isMaterialsView &&
                        <AddMaterialModal
                            onCancel={() => this.setState({showAddModal: false})}
                            onSaved={this.onMaterialCreated}
                            initialMaterial={this.state.currentlyShownMaterial}
                        />
                    }
                    {
                        this.state.showAddModal && isDistributionView &&
                        <AgitationDistributionEditModal
                            onCancel={() => this.setState({showAddModal: false})}
                            onSaved={this.onMaterialDistributionSaved}
                        />
                    }
                    <div className='col-10 pt-2'>
                        {
                            isAllDistributionsView &&
                            <AgitationDistributionsSearchTable
                                ref={this.materialsTableRef}
                                toastManager={this.props.toastManager}/>
                        }
                        {
                            isMaterialsView &&
                            <AgitationMaterialsSearchTable
                                onRowClicked={(row: ApiAgitationMaterialsResponseRow) => this.setState(
                                    {currentlyShownMaterial: row, showAddModal: true}
                                )}
                                ref={this.materialsTableRef}
                            />
                        }
                        {
                            isDistributionView &&
                            <AgitationMaterialsByUsersSearchTable
                                toastManager={this.props.toastManager}
                                ref={this.materialsByUsersTableRef}
                            />
                        }
                    </div>
                </div>
            </ContentScreen>
        )
    }
}

export default withToastManager(withRouter(FieldScreen))
