import React, {RefObject} from "react";
import moment from "moment";
import RemoteSearchTable from "../../components/search/RemoteSearchTable";
import {ApiSortCriteria} from "../../../models/enums";
import {ApiElectorCommonTableParameters} from "../../../api/RequestModels";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import * as API from "../../../api/API";
import {APIContactsBriefInfosWithCallsResponseRow, UserSummaryInfoResponse} from "../../../api/ResponseModels";
import {TableCell} from "@material-ui/core";
import * as TypedUtils from "../../../utils/TypedUtils";
import {commonDateTimeFormat} from "../../../utils/Formatters";
import {ToastConsumerContext} from "react-toast-notifications";
import {Modal} from "react-bootstrap-v5";
import {RemoteSearchTableFilterModel} from "../../../models/RemoteSearchTableFilterModel";

type TableRowType = APIContactsBriefInfosWithCallsResponseRow
const HEADER_CELLS = [
    {id: 'fullName', disablePadding: false, label: 'ФИО'},
    {
        id: ApiSortCriteria.FULL_ADDRESS,
        disablePadding: false,
        label: 'Адрес',
        sortCriteria: ApiSortCriteria.FULL_ADDRESS
    },
    {
        id: ApiSortCriteria.PHONE_NUMBER,
        disablePadding: false,
        label: 'Телефон',
        sortCriteria: ApiSortCriteria.PHONE_NUMBER
    },
    {
        id: ApiSortCriteria.THEMATICS,
        disablePadding: false,
        label: 'Проблематика',
        sortCriteria: ApiSortCriteria.THEMATICS
    },
    {
        id: 'phoneCallResults',
        disablePadding: false,
        label: 'Звонки',
    },
    {id: ApiSortCriteria.CREATED_AT, disablePadding: false, label: 'Создан', sortCriteria: ApiSortCriteria.CREATED_AT},
]

interface Props {
    onRowSelected?: (row: TableRowType) => void
    onCancel: () => void
    user: UserSummaryInfoResponse
    toastManager: ToastConsumerContext
    filters: RemoteSearchTableFilterModel[]
}

interface State {
}

class ContactsWithCallsForUserSearchTableModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = ({})
        this.tableRef = React.createRef()
    }

    tableRef: RefObject<any> | undefined = undefined

    loadRows = async (params: ApiElectorCommonTableParameters) => {
        const alwaysAppliedFilters = this.props.filters
        const newParams: ApiElectorCommonTableParameters = {...params, filters: alwaysAppliedFilters}

        const response = await API.getContactsWithCalls(newParams, globalStore.getState().authToken)
        return response.rows
    }
    _reloadTable = () => this.tableRef?.current?.loadRowsAsync()

    _renderCells = (row: TableRowType) => {
        return (
            [
                <TableCell align='left'>
                    <a href={`/contact/${row.contact.id}`} target={'_blank'}>
                        {row.contact.fullName}
                    </a>
                </TableCell>,
                <TableCell
                    align='left'>{row.contact.fullAddress ? TypedUtils.makeFullAddressWithGeo(row.contact.fullAddress, row.contact.geo) : ''}</TableCell>,
                <TableCell
                    align='left'>{row.contact.phoneNumber ? TypedUtils.renderWhatsAppLink(row.contact.phoneNumber) : ''}</TableCell>,
                <TableCell align="left">{row.contact.thematics.join(', ')}</TableCell>,
                <TableCell align="left">
                    <div className='d-flex flex-column'>
                        {
                            row.calls.map(c => {
                                    const colorStyleOpt = c.resultAndColor.color ? {color: TypedUtils.convertHexToRGBA(c.resultAndColor.color, 100)} : undefined
                                    return <a style={colorStyleOpt}
                                              target={'_blank'}
                                              href={`/call/${c.id}`}
                                              className='mb-1 cursor-pointer'>
                                        {c.resultAndColor.result}
                                    </a>
                                }
                            )
                        }
                    </div>
                </TableCell>,
                <TableCell align='left'>{commonDateTimeFormat(moment(row.contact.createdAt))}</TableCell>,

            ]
        )
    }


    render() {
        return (
            <Modal show={true} onHide={this.props.onCancel} centered className={'modal-desktop-wide'}>
                <Modal.Body>
                    <main id="call-script-editor" className="container-fluid px-0">
                        <div className="col-12 shadow-lg mb-4">
                            <div
                                className="bg-dark text-white text-center fw-500 py-3 d-flex flex-row justify-content-between align-items-center">
                                <span className='ms-5'>
                                    {`Контакты пользователя `}
                                    {
                                        TypedUtils.renderUserLink(this.props.user.fullName, this.props.user.id, 'font-monospace fw-bold text-red')
                                    }
                                </span>
                            </div>
                            <div className="bg-white pt-2 pb-4 px-4">
                                <RemoteSearchTable
                                    toastManager={this.props.toastManager}
                                    loadRows={this.loadRows}
                                    rowKeyExtractor={r => r.contact.id.toString()}
                                    renderTableCells={this._renderCells}
                                    headerCells={HEADER_CELLS}
                                    searchPlaceholder={'ФИО, телефон, проблематика или адрес'}/>
                            </div>
                        </div>
                    </main>
                </Modal.Body>
            </Modal>
        );
    }

}

export default ContactsWithCallsForUserSearchTableModal
