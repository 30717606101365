import React from "react";
import * as VoxImplant from 'voximplant-websdk'
import {CallEvents} from 'voximplant-websdk'
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import {Client} from "voximplant-websdk/Client";
import {Button} from "react-bootstrap-v5";
import Icon from "@iconify/react";
import comment16 from "@iconify/icons-octicon/comment-16";
import bellSlash16 from "@iconify/icons-octicon/bell-slash-16";
import {Call} from "voximplant-websdk/Call/Call";
import {globalStore} from "../../../../utils/redux/ReduxUtils";

interface Props {
    toastManager: ToastConsumerContext
    onCallEnded: (durationSeconds: number) => void
    phoneNumber: string
}

interface State {
    status: string
    outgoingCall?: Call

}

class VoxImplantButtonsPanel extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = ({
            status: '',
            outgoingCall: undefined
        })
    }

    componentDidMount() {
        this._initVoxImplant()
    }

    handleCallEnd = () => {
        if (this.state.outgoingCall) {
            const duration = (this.state.outgoingCall.getCallDuration() || 0) / 1000
            this.props.onCallEnded(duration)
        }
    }

    _hangUpCall = async () => {
        if (this.state.outgoingCall) {
            this.handleCallEnd()
            this.state.outgoingCall.hangup()
        }
        this.setState({outgoingCall: undefined})
    }

    _callVI = async () => {
        this.setState({
            status: 'Устанавливается соединение'
        })
        const sdk = VoxImplant.getInstance()
        const out = sdk.call(
            this.props.phoneNumber.trim().replaceAll('+ ', '')
        );
        this.setState({outgoingCall: out})
        out.on(CallEvents.Connected, e => {
            console.log('connected', e)
            this.setState({status: 'Соединение установлено'})
        })
        out.on(CallEvents.Updated, e => {
            console.log('Updated', e)
        })
        out.on(CallEvents.ProgressToneStart, e => this.setState({status: 'Ожидание ответа'}))
        out.on(CallEvents.Failed, e => {
            console.log('CallEvents.Failed', e)
            this.handleCallEnd()
            this.setState({status: 'Неудачно', outgoingCall: undefined})
        })
        out.on(CallEvents.Disconnected, e => {
            this.handleCallEnd()
            this.setState({status: 'Завершён', outgoingCall: undefined})
        })
    }

    _initVoxImplant = async () => {
        const loginVIAsync = async (sdk: Client) => {
            const globalPhoneCallSettings = globalStore.getState().externalAdditionalInfo.callsSettings
            if (!globalPhoneCallSettings) {
                this.props.toastManager.add('Недостаточно прав для осуществления звонков!', {
                    appearance: 'error',
                });
                return
            } else {
                const customerName = globalPhoneCallSettings.customerName,
                    appName = globalPhoneCallSettings.appName,
                    userName = globalPhoneCallSettings.userName,
                    password = globalPhoneCallSettings.password;

                const fullUserName = `${customerName}@${appName}.${userName}.voximplant.com`

                await sdk.init({showDebugInfo: true})
                await sdk.connect(false)
                await sdk.login(fullUserName, password)
            }

        }

        const sdk = VoxImplant.getInstance();

        const isSupported = sdk.isRTCsupported();

        if (isSupported) {
            if (!sdk.alreadyInitialized) {
                loginVIAsync(sdk).catch(err => {
                    console.log(err)
                    throw err
                })
            }
        } else {
            console.error('Данный браузер не поддерживает звонки!');
            this.props.toastManager.add('Данный браузер не поддерживает звонки!', {
                appearance: 'error',
            });
        }
    }

    render() {
        return (
            <div className='col-12 flex-row justify-content-between'>
                <Button size='sm' onClick={this._callVI} className={'me-2'}>
                    <Icon icon={comment16} className='icon icon-16 d-md-none d-lg-inline me-3 me-lg-2'/>
                    Позвонить
                </Button>
                {
                    this.state.outgoingCall &&
                    <Button size='sm' onClick={this._hangUpCall} variant={'danger'}>
                        <Icon icon={bellSlash16} className='icon icon-16 d-md-none d-lg-inline me-3 me-lg-2'/>
                        Положить трубку
                    </Button>
                }


                <div>
                    {
                        this.state.status &&
                        <>
                            <span className='fw-bold text-primary'>Статус: </span>
                            <span className='text-green'>{this.state.status}</span>
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default withToastManager(VoxImplantButtonsPanel)
