import React from "react";
import TableSearchFilterView, {FilterOption, TableSearchFilterPropType} from "./TableSearchFilterView";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import * as API from "../../../../api/API";
import {globalStore} from "../../../../utils/redux/ReduxUtils";
import {UserSummaryInfoResponse} from "../../../../api/ResponseModels";

interface Props {
    toastManager: ToastConsumerContext
    value?: FilterOption<number, UserSummaryInfoResponse>[]
    setValue: (v: FilterOption<number, UserSummaryInfoResponse>[] | undefined) => void
    title?: string
    singleOption?: boolean
}

class ContactCreatedBySearchTableFilter extends React.Component<Props, any> {
    searchRows = async (searchQueryOpt?: string) => {
        const response = await API.searchContactCreatedBy(searchQueryOpt, globalStore.getState().authToken).catch(err => {
            this.props.toastManager.add('Не удалось загрузить список пользователей!', {appearance: 'error'})
            throw err
        })
        return response.rows.map(r => ({
            key: r.id,
            value: r.value,
            label: `${r.value.fullName} (${r.id})`
        }))
    }

    render() {
        const setValueSingle: (v: FilterOption<number, UserSummaryInfoResponse> | undefined) => void = v => {
            if (!v) this.props.setValue(v)
            else this.props.setValue([v])
        };
        return (
            this.props.singleOption ?
                <TableSearchFilterView
                    type={TableSearchFilterPropType.SINGLE}
                    title={this.props.title || 'Создал'}
                    value={this.props.value?.pop()}
                    searchRows={this.searchRows}
                    setValue={setValueSingle}/>
                :
                <TableSearchFilterView
                    type={TableSearchFilterPropType.MULTIPLE}
                    title={this.props.title || 'Создал'}
                    value={this.props.value}
                    searchRows={this.searchRows}
                    setValue={this.props.setValue}/>
        )
    }
}

export default withToastManager(ContactCreatedBySearchTableFilter)
