import React, {RefObject} from "react";
import moment from "moment";
import RemoteSearchTable, {SearchTableHeaderCell} from "../../components/search/RemoteSearchTable";
import {ApiSortCriteria, VerificationScenarioResultName} from "../../../models/enums";
import {ApiElectorCommonTableParameters} from "../../../api/RequestModels";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import * as API from "../../../api/API";
import {ApiHqContactAggregatesResponseRow, UserSummaryInfoResponse} from "../../../api/ResponseModels";
import {TableCell} from "@material-ui/core";
import * as TypedUtils from "../../../utils/TypedUtils";
import {commonDateTimeFormat} from "../../../utils/Formatters";
import {ToastConsumerContext} from "react-toast-notifications";
import {Button} from "react-bootstrap-v5";
import {
    RemoteSearchTableCreatedByFilterModel,
    RemoteSearchTableFilterModel
} from "../../../models/RemoteSearchTableFilterModel";
import {ApiIsArchivedFilterValues, SearchTableFilterType} from "../../../models/ApiFilterEnums";
import {EMPTY_FILTER_INFO} from "../../components/search/filters/DateFromToSearchTableFilter";
import AddPaymentModal from "../../components/AddPaymentModal";
import * as RoleUtils from "../../../utils/RoleUtils";
import ContactsWithCallsForUserSearchTableModal from "./ContactsWithCallsForUserSearchTableModal";

type TableRowType = ApiHqContactAggregatesResponseRow
const ALL_FILTERS: RemoteSearchTableFilterModel[] = [
    {
        type: SearchTableFilterType.IS_ARCHIVED,
        value: ApiIsArchivedFilterValues.NOT_ARCHIVED,
        defaultValue: ApiIsArchivedFilterValues.NOT_ARCHIVED
    },
    {
        type: SearchTableFilterType.DATE_FROM_TO,
        value: EMPTY_FILTER_INFO,
        defaultValue: EMPTY_FILTER_INFO
    },
]
const getHeaderCells: () => SearchTableHeaderCell[] = () => {
    return [
        {
            id: 'author',
            disablePadding: false,
            label: 'Сборщик',
        },
        {
            id: ApiSortCriteria.CREATED_AT,
            disablePadding: false,
            label: 'Дата последнего контакта',
            sortCriteria: ApiSortCriteria.CREATED_AT
        },
        {
            id: ApiSortCriteria.TOTAL_CONTACT_SHIFTS_COUNT,
            disablePadding: false,
            label: 'Всего смен',
            sortCriteria: ApiSortCriteria.TOTAL_CONTACT_SHIFTS_COUNT
        },
        {
            id: ApiSortCriteria.TOTAL_CONTACTS_COUNT,
            disablePadding: false,
            label: 'Всего Контактов',
            sortCriteria: ApiSortCriteria.TOTAL_CONTACTS_COUNT
        },
        {
            id: 'verifiedCount',
            disablePadding: false,
            label: 'Верифицировано',
        },
        {
            id: 'unVerifiedCount',
            disablePadding: false,
            label: 'Не верифицировано',
        },
        {
            id: 'absentCount',
            disablePadding: false,
            label: 'Недозвонов',
        },
        {
            id: 'paymentSum',
            disablePadding: false,
            label: 'Выплачено',
        },
        ...(RoleUtils.hasPaymentsEditPermission(globalStore.getState().externalAdditionalInfo.user.role) ?
                [{
                    id: 'addPayment',
                    disablePadding: false,
                    label: 'Выплата',
                }] : []
        )
    ];
}

interface Props {
    onRowSelected?: (row: TableRowType) => void
    toastManager: ToastConsumerContext
}

interface State {
    addPaymentSelectedUserId?: number
    allContactsSelectedUser?: UserSummaryInfoResponse
}

class ContactsAggregateInfoSearchTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = ({
            addPaymentSelectedUserId: undefined,
            allContactsSelectedUser: undefined
        })
        this.tableRef = React.createRef()
    }

    tableRef: RefObject<any> | undefined = undefined

    loadRows = async (params: ApiElectorCommonTableParameters) => {
        const response = await API.getContactsAggregateInfo(params, globalStore.getState().authToken)
        return response.rows
    }
    _reloadTable = () => this.tableRef?.current?.loadRowsAsync()

    _renderCells = (row: TableRowType) => {
        const hasEditPaymentPermission = RoleUtils.hasPaymentsEditPermission(globalStore.getState().externalAdditionalInfo.user.role)
        const verificationScenarioResults =
            row.callCounts.find(cc => cc.scenarioId === globalStore.getState().externalAdditionalInfo.verificationScenarioId)?.rows || []
        const campaignVerificationSettings = globalStore.getState().externalAdditionalInfo.verificationSettings
        const verifiedContactsCount = verificationScenarioResults
            .filter(sr =>
                ! (campaignVerificationSettings.unverifiedResults.concat(campaignVerificationSettings.absentResults).includes(sr.result))
            )
            .map(v => v.count)
            .reduce((sum, x) => sum + x, 0);
        const notVerifiedContactsCount = verificationScenarioResults
            .filter(sr => campaignVerificationSettings.unverifiedResults.includes(sr.result))
            .map(v => v.count)
            .reduce((sum, x) => sum + x, 0);
        const absentContactsCount = verificationScenarioResults
            .filter(sr => campaignVerificationSettings.absentResults.includes(sr.result))
            .map(v => v.count)
            .reduce((sum, x) => sum + x, 0);
        return (
            [
                <TableCell align='left'>{TypedUtils.renderUserLink(row.user.fullName, row.user.id)}</TableCell>,
                <TableCell align='left'>{commonDateTimeFormat(moment(row.latestContactCreatedAt))}</TableCell>,
                <TableCell align='left'>{row.totalContactShiftsCount}</TableCell>,
                <TableCell align='left'>{row.totalContactsCollectedCount}</TableCell>,
                <TableCell align='left' className={verifiedContactsCount > 0 ? 'text-success' : ''}>{verifiedContactsCount}</TableCell>,
                <TableCell align='left' className={notVerifiedContactsCount > 0 ? 'text-danger' : ''}>{notVerifiedContactsCount}</TableCell>,
                <TableCell align='left' className={absentContactsCount > 0 ? 'text-warning' : ''}>{absentContactsCount}</TableCell>,
                <TableCell align='left'>{`${row.totalPaidSum} ₽`}</TableCell>,
                [...(hasEditPaymentPermission ? [
                    <TableCell align='left'>
                        <Button onClick={() => this.setState({addPaymentSelectedUserId: row.user.id})}
                                variant={'outline-primary'}>+
                        </Button>
                    </TableCell>
                ] : [])],
            ]
        )
    }


    render() {
        const dynamicHeaderCells = getHeaderCells()
        const getAllContactsWithPhoneCallsFilters = () => {
            const getCurrentFilterValues: () => RemoteSearchTableFilterModel[] = () => {
                return this.tableRef?.current.getCurrentlyAppliedFilters() || []
            }
            if (this.state.allContactsSelectedUser) {
                const contactCreatedByUserFilter: RemoteSearchTableCreatedByFilterModel = {
                    type: SearchTableFilterType.CREATED_BY,
                    value: [{
                        key: this.state.allContactsSelectedUser.id,
                        value: this.state.allContactsSelectedUser,
                        label: this.state.allContactsSelectedUser.fullName,
                    }],
                    defaultValue: undefined
                }
                return getCurrentFilterValues().concat([contactCreatedByUserFilter])
            } else { //should never happen in fact
                return []
            }

        }

        return (
            <>
                <RemoteSearchTable
                    toastManager={this.props.toastManager}
                    ref={this.tableRef}
                    onRowSelected={this.props.onRowSelected}
                    searchPlaceholder={'ФИО сборщика'}
                    rowKeyExtractor={(u: TableRowType) => u.user.id.toString()}
                    renderTableCells={this._renderCells}
                    headerCells={dynamicHeaderCells}
                    renderExpandedContent={(row) => [
                        <TableCell/>,
                        <TableCell>
                            <div>
                                {
                                    row.thematicsCounts.length > 0 &&
                                    <div>
                                        <span className='fw-bold'>Проблематики:</span>
                                        {row.thematicsCounts.map((tc, idx) =>
                                            <div key={idx}> - {tc.thematics} - {tc.count}</div>
                                        )}
                                    </div>
                                }
                            </div>
                        </TableCell>,
                        <TableCell>
                            <div className='w-100'>
                                {
                                    row.callCounts.length > 0 &&
                                    <div>
                                        <span className='fw-bold'>Звонки:</span>
                                        {row.callCounts.map((tc, idx) =>
                                            <div key={idx} className='mb-1'>
                                                {tc.scenarioTitle}
                                                {
                                                    tc.rows.map((callRow, callRowIdx) =>
                                                        <div key={callRowIdx}>- {callRow.result} - {callRow.count}</div>
                                                    )
                                                }
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        </TableCell>,
                        <TableCell/>,
                        <TableCell>
                            <a className='cursor-pointer'
                               onClick={() => this.setState({allContactsSelectedUser: row.user})}>
                                Посмотреть все контакты
                            </a>
                        </TableCell>
                    ]}
                    allFilters={ALL_FILTERS}
                    loadRows={this.loadRows}/>
                {
                    this.state.addPaymentSelectedUserId &&
                    <AddPaymentModal userId={this.state.addPaymentSelectedUserId}
                                     toggleVisibility={() => this.setState({addPaymentSelectedUserId: undefined})}
                                     onPaymentCreated={this._reloadTable}
                                     visible={true}/>
                }
                {
                    this.state.allContactsSelectedUser &&
                    <ContactsWithCallsForUserSearchTableModal
                        filters={getAllContactsWithPhoneCallsFilters()}
                        toastManager={this.props.toastManager}
                        onCancel={() => this.setState({allContactsSelectedUser: undefined})}
                        user={this.state.allContactsSelectedUser}
                    />
                }

            </>
        );
    }

}

export default ContactsAggregateInfoSearchTable
