import React from "react";
import {Modal} from "react-bootstrap-v5";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import {ApiScenariosBriefInfoResponseRow} from "../../../api/ResponseModels";
import CallScenariosSearchTable from "./CallScenariosSearchTable";
import {CancellableButton} from "../../components/CancellableButton";
import {APICallShiftInfo} from "../../../api/Models";

interface State {
    scenario?: ApiScenariosBriefInfoResponseRow
}

interface Props {
    onCancel: () => void
    onSaved: () => void
    toastManager: ToastConsumerContext
    currentlyActiveShifts: APICallShiftInfo[]
}

class ShiftEditModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            scenario: undefined,
        }
    }

    onStartShiftPressed = () => {
        const startShiftF = async () => {
            if (this.state.scenario) {
                await API.toggleShift({
                    id: 0,
                    scenarioId: this.state.scenario.id
                }, globalStore.getState().authToken)
                this.props.onSaved()
            }

        }
        startShiftF().catch(err => {
            console.log(err)
            this.props.toastManager.add('Не удалось начать обзвон!', {
                appearance: 'error',
            });
        })


    }

    render() {
        return (
            <Modal show={true} onHide={this.props.onCancel} centered>
                <Modal.Body>
                    <main id="call-script-editor" className="container-fluid">
                        <div className="max-w-md shadow-lg mb-4">
                            <div
                                className="bg-dark text-white text-center fw-500 py-3 d-flex flex-row justify-content-between align-items-center">
                            <span className='ms-5'>
                                Обзвон
                            </span>
                            </div>
                            <div className="bg-white pt-2 pb-4 px-1">
                                {
                                    !this.state.scenario &&
                                    <div className='row col-12 m-0'>
                                        <CallScenariosSearchTable
                                            filterRows={(scenario: ApiScenariosBriefInfoResponseRow) =>
                                                !this.props.currentlyActiveShifts.map(s => s.scenarioId).includes(scenario.id)
                                            }
                                            onRowSelected={(scenario: ApiScenariosBriefInfoResponseRow) => {
                                                this.setState({scenario: scenario})
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    this.state.scenario &&
                                    <div className='row col-12 m-0'>
                                        <CancellableButton index={0}
                                                           title={this.state.scenario.title}
                                                           row={this.state.scenario}
                                                           className='scenario-edit__selected_user'
                                                           onCancelClicked={() => this.setState({scenario: undefined})}/>
                                    </div>
                                }
                                <div className="bg-white text-center sticky-bottom border-top p-4">
                                    <div className="row gx-4">
                                        <div className="col-6">
                                            <button id="cancel"
                                                    onClick={this.props.onCancel}
                                                    className="btn btn-danger w-100 fw-500">Отмена
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button id="save" onClick={this.onStartShiftPressed}
                                                    className="btn btn-success w-100 fw-500">Сохранить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Modal.Body>
            </Modal>
        )
    }

}

export default withToastManager(ShiftEditModal)
