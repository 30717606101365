import * as React from 'react'
import {GlobalState, globalStore} from "../../utils/redux/ReduxUtils";
import {connect} from "react-redux";
import './ProfileScreen.css'
import ContentScreen from "../ContentScreen";
import {useHistory, withRouter} from "react-router-dom";
import {ApiContactBriefInfoResponse} from "../../api/ResponseModels";
import ContactSearchTableNew from "./contacts/ContactSearchTable";
import * as RoleUtils from "../../utils/RoleUtils";
import {Button} from "react-bootstrap-v5";
import {RouteComponentProps} from "react-router";
import ContactsAggregateInfoSearchTable from "./contacts/ContactsAggregateInfoSearchTable";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";

interface Props extends RouteComponentProps {
    toastManager: ToastConsumerContext
}

class ContactsScreen extends React.Component<Props, any> {

    onRowClicked = (contactInfo: ApiContactBriefInfoResponse) => {
        window.open(`/contact/${contactInfo.id}`, '_blank')?.focus();
    }

    render() {
        const hasPermissionToEdit = RoleUtils.hasContactEditPermission(globalStore.getState().externalAdditionalInfo.user.role)
        const isAggregateView = window.location.href.includes('#aggregates')
        const isAllContactsView = !isAggregateView
        const navigateToAnchor = (anchor: string) => {
            window.location.hash = anchor
            this.setState({})
        }

        return (
            <ContentScreen>
                <div className='mx-auto w-100 mt-2 mb-2 d-flex gx-2 flex-column align-items-center'>
                    <div className='row col-12'>
                        <div className="col-auto h4 fw-normal mb-4">
                            <svg className="icon icon-24 text-primary align-text-bottom me-2">
                                <use href="#checklist-24"/>
                            </svg>
                            Собранные контакты
                        </div>
                        {
                            hasPermissionToEdit &&
                            <div className="col-auto ms-auto mt-n1">
                                <button className="btn btn-outline-primary"
                                        onClick={() => this.props.history.push('/contact/0')}>
                                    <svg className="icon icon-16 me-1">
                                        <use href="#plus-16"/>
                                    </svg>
                                    <svg className="icon icon-16 me-2">
                                        <use href="#checklist-16"/>
                                    </svg>
                                    Новый контакт
                                </button>
                            </div>
                        }
                    </div>
                    <div className="mb-1 btn-group d-flex justify-content-center">
                        <Button size={'lg'}
                                variant={isAllContactsView ? 'primary' : 'outline-primary'}
                                onClick={() => navigateToAnchor('#all')}>Все контакты</Button>
                        <Button size={'lg'}
                                variant={isAggregateView ? 'primary' : 'outline-primary'}
                                onClick={() => navigateToAnchor('#aggregates')}>Сводная</Button>
                    </div>
                    <div className='col-12 mt-2'>
                        {
                            isAllContactsView &&
                            <ContactSearchTableNew
                                isForCalls={false}
                                toastManager={this.props.toastManager}
                                onRowClicked={this.onRowClicked}/>
                        }
                        {
                            isAggregateView &&
                            <ContactsAggregateInfoSearchTable
                                toastManager={this.props.toastManager}
                            />
                        }
                    </div>
                </div>
            </ContentScreen>

        )
    }


}

function mapStateToProps(state: GlobalState): any {
    return {}
}


export default withToastManager(withRouter(connect(
    mapStateToProps
)(ContactsScreen)));
