import React from "react";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import * as TypedUtils from '../../../utils/TypedUtils'
import {Paper, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import {commonDateTimeFormat} from "../../../utils/Formatters";
import * as _ from "lodash";
import moment from "moment";
import {
    ApiCallsGroupedByScenarioResponseContact,
    ApiCallsGroupedByScenarioResponseRow
} from "../../../api/ResponseModels";

type Row = ApiCallsGroupedByScenarioResponseContact

interface State {
    rows: Row[]
    shownRows: Row[]
}

interface Props {
    toastManager: ToastConsumerContext
    source: ApiCallsGroupedByScenarioResponseRow
    onRowSelected?: (row: Row) => void
    filterRows?: (row: Row) => boolean
}

class MyCallsSearchTable extends React.Component<Props, State> {
    state = {
        rows: [] as Row[],
        shownRows: [] as Row[],
    }

    constructor(props: Props) {
        super(props);
        this.state = ({
            rows: props.source.contacts,
            shownRows: props.source.contacts,
        })
    }

    onSearchQueryChanged = _.debounce((q: string) => {
        const trimmedQuery = q.trim().toLowerCase()
        if (!trimmedQuery) this.setState({shownRows: this.state.rows})
        else {
            this.setState({
                shownRows: this.state.rows.filter(row =>
                    row.contact.fullName.toLowerCase().includes(trimmedQuery) ||
                    Boolean(row.contact.thematics.map(t => t.toLowerCase()).find(t => t.includes(trimmedQuery))) ||
                    (row.contact.phoneNumber && row.contact.phoneNumber.toLowerCase().includes(trimmedQuery)) ||
                    (row.contact.fullAddress && row.contact.fullAddress.toLowerCase().includes(trimmedQuery))
                )
            })
        }

    }, 500)

    render() {
        return (
            <>
                <div>
                    <div className="col-auto col-sm ms-auto mt-n2">
                        <div className="input-group my-1">
                            <span className="input-group-text">Поиск</span>
                            <input type="text" className="form-control"
                                   onChange={e => this.onSearchQueryChanged(e.target.value)}
                                   placeholder='ФИО, номер, проблематика или адрес'/>
                        </div>
                    </div>
                    <TableContainer component={Paper}>
                        <Table aria-label="callscenarios">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">ФИО</TableCell>
                                    <TableCell align="left">Номер</TableCell>
                                    <TableCell align="left">Проблематика</TableCell>
                                    <TableCell align="left">Реакция</TableCell>
                                    <TableCell align="left">Адрес</TableCell>
                                    <TableCell align="left">Создан</TableCell>
                                    <TableCell align="left">Результат</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.shownRows.map((row) => {
                                    const foundResult = this.props.source.possibleResults.find(pr => pr.title === row.callResult)
                                    const foundResultColor = foundResult? {backgroundColor: TypedUtils.convertHexToRGBA(foundResult.color, 10)}: {}
                                    return <TableRow key={row.contact.id}
                                                     style={{cursor: 'pointer', ...foundResultColor}}
                                                     hover
                                                     onClick={() => this.props.onRowSelected && this.props.onRowSelected(row)}>
                                        <TableCell component="th" scope="row">{row.contact.fullName}</TableCell>
                                        <TableCell component="th" scope="row">{row.contact.phoneNumber}</TableCell>
                                        <TableCell component="th" scope="row">{row.contact.thematics.join(', ')}</TableCell>
                                        <TableCell component="th"
                                                   scope="row">{TypedUtils.getReactionDisplayName(row.contact.reaction)}</TableCell>
                                        <TableCell component="th" scope="row">{''}</TableCell>
                                        <TableCell
                                            align="left">{commonDateTimeFormat(moment(row.contact.createdAt))}</TableCell>
                                        <TableCell component="th" scope="row">{row.callResult || ''}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {
                    this.state.rows.length === 0 &&
                    <div className='text-center mt-2'>
                        Подходящие контакты не найдены!
                    </div>
                }
            </>
        );
    }
}

export default withToastManager(MyCallsSearchTable)
