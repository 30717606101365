import * as React from 'react'
import {GlobalState, globalStore} from "../../utils/redux/ReduxUtils";
import {connect} from "react-redux";
import './ProfileScreen.css'
import ContentScreen from "../ContentScreen";
import {useHistory} from "react-router-dom";

import ContactsAggregateInfoSearchTable from "./contacts/ContactsAggregateInfoSearchTable";
import {withToastManager} from "react-toast-notifications";

const ContactsAggregatesScreen = (props: any) => {
    const history = useHistory();

    return (
        <ContentScreen>
            <div className='mx-auto w-100 mt-2 mb-2 d-flex gx-2 flex-column align-items-center'>
                <div className='row col-12'>
                    <div className="col-auto h4 fw-normal mb-4">
                        <svg className="icon icon-24 text-primary align-text-bottom me-2">
                            <use href="#checklist-24"/>
                        </svg>
                        Информация по сборщикам
                    </div>

                </div>
                <div className='col-12'>
                    <div>Всего вышло сборщиков: 1234</div>
                    <div>Всего собрано контактов: 1234</div>
                    <div>Всего верифицировано контактов: 1234</div>
                    <ContactsAggregateInfoSearchTable
                         toastManager={props.toastManager}/>
                </div>
            </div>
        </ContentScreen>

    )
}

function mapStateToProps(state: GlobalState): any {
    return {}
}


export default withToastManager(connect(
    mapStateToProps
)(ContactsAggregatesScreen));
