import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import './MapScreen.scss'
import ContentScreen from "../ContentScreen";
import MapGL, {FullscreenControl, Layer, Source} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as API from "../../api/API";
import {DrawPolygonMode, EditingMode, Editor,} from 'react-map-gl-draw';
import {globalStore} from "../../utils/redux/ReduxUtils";
import {getEditHandleStyle, getFeatureStyle} from "./map/mapStyleUtils";
import * as turf from "@turf/turf";
import {MapInfoPanel} from "./map/MapInfoPanel";
import {Button, Dropdown} from "react-bootstrap-v5";
import {faBars, faCog, faPrint} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {MapHouseHoverTooltip} from "./map/MapHouseHoverTooltip";
import {MAP_INFO_MODES} from "./map/MapTypes";
import * as _ from "lodash";
import classnames from "classnames";
import {Icon} from '@iconify/react';
import duplicate24 from '@iconify-icons/octicon/duplicate-24';
import check16 from "@iconify/icons-octicon/check-16";
import {useToasts} from "react-toast-notifications";
import {COLORS} from "../../utils/Consts";
import layer20Regular from '@iconify-icons/fluent/layer-20-regular';
import checklist16 from "@iconify/icons-octicon/checklist-16";
import * as TypedUtils from "../../utils/TypedUtils";
import MapGeocoderSearchbar from "./map/MapGeocoderSearchbar";
import {MapHouseFullInfoPanel} from "./map/MapHouseFullInfoPanel";
import {RemoteSearchTableHouseIdsFilterModel} from "../../models/RemoteSearchTableFilterModel";
import {SearchTableFilterType} from "../../models/ApiFilterEnums";
import {momentToLocalDateTimeString} from "../../api/ApiRequestMappers";
import {EMPTY_FILTER_INFO} from "../components/search/filters/DateFromToSearchTableFilter";

export const dataLayer = {
    id: 'data',
    type: 'fill',
    paint: {
        'fill-color': {
            property: 'houseColor',
            stops: [
                [-1, '#818181'],
                [0, '#e01c32'],
                [1, '#d53e4f'],
                [2, '#f46d43'],
                [3, '#fdae61'],
                [4, '#fee08b'],
                [5, '#ffffbf'],
                [6, '#e6f598'],
                [7, '#abdda4'],
                [8, '#2ad73c'],
            ]
        },
        'fill-outline-color': '#3765c7',
        'fill-opacity': 0.7
    }
};

export const customLayerRenderStyle = {
    id: 'customLayer',
    type: 'fill',
    paint: {
        'fill-outline-color': '#ff0000',
        'fill-color': {
            property: 'multiPolygonFillColorIdx',
            stops: [
                [-1, '#00ff03'],
                [0, '#3288bd'],
                [1, '#66c2a5'],
                [2, '#abdda4'],
                [3, '#e6f598'],
                [4, '#ffffbf'],
                [5, '#fee08b'],
                [6, '#fdae61'],
                [7, '#f46d43'],
                [8, '#d53e4f'],
            ]
        },
        'fill-opacity': 0.8,
    }
};

const MapScreen = () => {
    const {addToast} = useToasts()
    const MAP_SETTINGS = globalStore.getState().externalAdditionalInfo.commonSettings.mapSettings
    const [viewport, setViewport] = useState({
        latitude: MAP_SETTINGS.latitude,
        longitude: MAP_SETTINGS.longitude,
        zoom: MAP_SETTINGS.zoom,
        bearing: 0,
        pitch: 0
    });
    const [mode, setMode] = React.useState(null);
    const [infoMode, setInfoMode] = React.useState(MAP_INFO_MODES.CONTACTS)
    const [allData, setAllData] = useState(null);
    const [statsData, setStatsData] = useState(null);
    const [houseFullInfo, setHouseFullInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hoverInfo, setHoverInfo] = useState(null);
    const [selectedPolygons, setSelectedPolygons] = useState([])
    const [aggregationParams, setAggregationParams] = useState({dataTypes: []})
    const [selectedEditorFeatureIndex, setSelectedEditorFeatureIndex] = useState(null);

    const [additionalLayers, setAdditionalLayers] = useState([]);
    const [selectedLayerFeatureIdx, setSelectedLayerFeatureIdx] = useState([]);
    const [currentlyShownLayerIdx, setCurrentlyShownLayerIdx] = useState(null);

    useEffect(() => {
        const loadAdditionalLayersF = async () => {
            const authToken = globalStore.getState().authToken
            const layersFromApi = await API.getAdditionalMapLayers(authToken)
            const mapLayer = (layerModel) => {
                return {
                    ...layerModel,
                    layer: {
                        type: 'FeatureCollection',
                        features: layerModel.layer.features.map((f, idx) => {
                            return {
                                ...f,
                                properties: {
                                    ...f.properties,
                                    multiPolygonFillColorIdx: selectedLayerFeatureIdx === idx ? -1 : idx % 9
                                }
                            }
                        })
                    }
                }
            }
            const resultingLayers = layersFromApi.map(mapLayer);
            setAdditionalLayers(resultingLayers);
        }
        loadAdditionalLayersF().catch(err => {
            console.log(err);
            addToast('Не удалось загрузить дополнительные слои!', {appearance: 'error'})
        })
    }, [selectedLayerFeatureIdx])

    const updateSelectedPolys = (featureCollection, selectedPolys) => {
        const {features} = featureCollection;
        const selectedPolyIds = selectedPolys.map(f => f.properties.houseId)
        const statsDataForSelectedPolys = (statsData?.houses || []).filter(h => selectedPolyIds.includes(h.value))
        const counts = statsDataForSelectedPolys.map(d => d.count)

        const getHouseColor = (f) => {
            if (statsData && selectedPolyIds.includes(f.properties.houseId)) {
                const currentFeatureStats = statsDataForSelectedPolys.find(d => d.value === f.properties.houseId)
                const maxCount = Math.max.apply(0, counts)
                if (currentFeatureStats && infoMode === MAP_INFO_MODES.CONTACTS) {
                    const divTenth = (currentFeatureStats.count / maxCount) * 10
                    const higherPercentTenth = [0, 1, 2, 3, 4, 5, 6, 7, 8].find(n => divTenth < n)
                    if (!higherPercentTenth) return 8
                    else return higherPercentTenth
                } else if (currentFeatureStats && infoMode === MAP_INFO_MODES.AGITATION) {
                    return currentFeatureStats.count === 0 // all bad
                        ? 0 : currentFeatureStats.count === -1 // partially bad
                            ? 3 : 8 //cool
                } else {
                    return -1
                }
            } else {
                return -1
            }
        }

        return {
            type: 'FeatureCollection',
            features: features.map(f => {
                const properties = {
                    ...f.properties,
                    houseColor: getHouseColor(f)
                };
                return {...f, properties};
            })
        };
    }

    useEffect(() => {
        const authToken = globalStore.getState().authToken

        /* global fetch */
        async function fetchAndHandle() {
            const resp = await API.loadGeoInfo(authToken)
            setAllData(resp)
        }

        fetchAndHandle()
    }, []);

    const getHouseIdsForGeoRequest = () => {
        const hasSomeDrawnFeaturesOrSelectedLayerFeature =
            (editorRef?.current?.getFeatures() || []).length > 0
            || typeof selectedEditorFeatureIndex === "number"
            || typeof selectedLayerFeatureIdx === "number"
        let houseIdsForRequest = selectedPolygons.map(f => f.properties.houseId)
        if (!hasSomeDrawnFeaturesOrSelectedLayerFeature && selectedPolygons.length === 0 && allData) {
            houseIdsForRequest = allData.features.map(f => f.properties.houseId)
        }
        return houseIdsForRequest
    }

    useEffect(() => {

        const fetchAndHandle = async () => {
            setIsLoading(true)
            const authToken = globalStore.getState().authToken
            const getDataF = infoMode === MAP_INFO_MODES.CONTACTS ? API.loadGeoContactAggregates : API.loadGeoAgitationAggregates

            const result = await getDataF(
                {houseIds: getHouseIdsForGeoRequest()},
                aggregationParams,
                authToken
            )
            setStatsData(result)
        }
        fetchAndHandle().finally(() => setIsLoading(false))
    }, [selectedPolygons, allData, aggregationParams, infoMode])

    const onEditorPointAdded = (e) => {
        setSelectedEditorFeatureIndex(e && e.selectedFeatureIndex);
        let geom = e.selectedFeature?.geometry
        if (geom?.type === 'LineString' && geom?.coordinates?.length > 2) {
            geom = turf.lineToPolygon(geom)?.geometry;
        }
        if (geom && (geom.type === 'Polygon' || geom.type === 'MultiPolygon')) {
            const intersected = allData.features.filter((feature) =>
                turf.intersect(geom, feature)
            )
            setSelectedPolygons(intersected)
        }
    }
    const editorRef = useRef(null);
    const mapRef = useRef(null);

    const onDelete = (forcedIndex) => {
        const idx = forcedIndex || selectedEditorFeatureIndex
        if (idx !== null) {
            editorRef.current.deleteFeatures(idx);
            setStatsData(null)
            setSelectedPolygons([])
        }
    }

    const keyPressHandler = (e) => {
        if (e.keyCode === 27) {
            onDelete(-1)
            setMode(null)
        }
    }
    useEffect(() => {
        window.addEventListener('keydown', keyPressHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', keyPressHandler);
        };

    }, []); // Empty array ensures that effect is only run on mount and unmount

    const onCopyAddressesToClipboardClicked = async (mode) => {
        let fullAddressesText = selectedHousesZippedWithSDRowDescForContactsNew.map(z =>
            `${z[1].properties.address} — ${z[0]?.count || 0}`
        ).join("\n")
        if (mode === 'agitation') {
            fullAddressesText = selectedHousesZippedWithSDRowDescForAgitationNew.map(z =>
                `${z[1].properties.address}`
            ).join("\n")
        }
        await navigator.clipboard.writeText(fullAddressesText)
        addToast('Список адресов скопирован в буфер обмена', {appearance: "success"})
    }


    const onUpdate = useCallback((e) => {
        if (e.editType === 'addFeature') {
            setMode(new EditingMode());
        }
    }, []);

    const onHover = useCallback(event => {
        const {
            features,
            srcEvent: {offsetX, offsetY}
        } = event;
        const hoveredFeature = features && features[0];

        setHoverInfo(
            hoveredFeature
                ? {
                    feature: hoveredFeature,
                    x: offsetX,
                    y: offsetY
                }
                : null
        );
    }, [])
    const onMapClick = async event => {
        const eventGeomFeatures = event.features.filter(f => !f.properties.houseId);
        const houseFeatures = event.features.filter(f => f.properties.houseId);
        if (houseFeatures.length > 0) {
            const firstHouseFeature = houseFeatures[0];
            await API.loadHouseInfo(firstHouseFeature.properties.houseId, globalStore.getState().authToken)
                .then(r => setHouseFullInfo(r))
                .catch(err => {
                    console.log(err)
                    this.props.toastManager.add('Не удалось загрузить информацию по дому!', {
                        appearance: 'error',
                    })

                })
        } else if (currentlyShownLayerIdx !== null && eventGeomFeatures.length > 0) {
            const firstGeomFeature = eventGeomFeatures[0];
            const foundFeatureIdxByName = additionalLayers[currentlyShownLayerIdx].layer.features.findIndex(f => f.properties.name === firstGeomFeature.properties.name);
            if (typeof foundFeatureIdxByName === 'number') {
                setSelectedLayerFeatureIdx(foundFeatureIdxByName);
                const foundFeature = additionalLayers[currentlyShownLayerIdx].layer.features[foundFeatureIdxByName]
                const intersected = allData.features.filter((feature) =>
                    turf.intersect(foundFeature, feature)
                )
                setSelectedPolygons(intersected)
            }
        } else {
            setSelectedLayerFeatureIdx(null)
        }
    };
    const drawTools = (
        <div className="mapboxgl-ctrl-top-left">
            <div className="mapboxgl-ctrl-group mapboxgl-ctrl">
                <button
                    className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
                    title="Полигон (p)"
                    onClick={() => setMode(new DrawPolygonMode())}
                />
                <button
                    className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash"
                    title="Удалить"
                    onClick={() => onDelete()}
                />
            </div>
        </div>
    );
    const layerTools = additionalLayers.length === 0 ? null : (
        <div className='mapboxgl-ctrl-bottom-right' style={{bottom: 30}}>
            <div className="mapboxgl-ctrl-group mapboxgl-ctrl d-flex justify-content-center">
                <Dropdown>
                    <Dropdown.Toggle variant={'outline-dark'}>
                        <Icon className='mapbox-gl-draw_ctrl-draw-btn' icon={layer20Regular} width={20}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {
                            additionalLayers.map((additionalLayer, idx) =>
                                <Dropdown.Item
                                    onClick={e => {
                                        setCurrentlyShownLayerIdx(idx)
                                    }}
                                    className='d-flex align-items-center'
                                    key={idx}>
                                    {additionalLayer.name}
                                    {
                                        currentlyShownLayerIdx === idx &&
                                        <Icon className='mapbox-gl-draw_ctrl-draw-btn' icon={check16} width={20}/>
                                    }
                                </Dropdown.Item>)
                        }
                        <Dropdown.Item
                            className='d-flex align-items-center'
                            onClick={e => {
                                setSelectedLayerFeatureIdx(null)
                                setCurrentlyShownLayerIdx(null)
                            }}>
                            Общий
                            {currentlyShownLayerIdx === null &&
                            <Icon className='mapbox-gl-draw_ctrl-draw-btn' icon={check16} width={20}/>}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
    const onPreferencesChanged = (dataTypes, filtersObject) => {
        const momentFrom = filtersObject.dateFrom ? moment(filtersObject.dateFrom) : undefined
        const momentTo = filtersObject.dateTo ? moment(filtersObject.dateTo) : undefined
        setAggregationParams({dataTypes: dataTypes, from: momentFrom, to: momentTo})
    }

    const data = useMemo(() => {
        return allData && updateSelectedPolys(allData, selectedPolygons);
    }, [allData, selectedPolygons, statsData]);

    const showTopPanel = Boolean(statsData)

    const selectedHousesZippedWithSDRowDescForContactsNew = infoMode === MAP_INFO_MODES.AGITATION
        ? [] : selectedPolygons.map(p => {
            const foundStatsDataHouseOpt = (statsData?.houses || []).find(sd => sd.value === p.properties.houseId)
            return [foundStatsDataHouseOpt, p]
        }).sort((a, b) => (b[0]?.count || 0) - (a[0]?.count || 0))

    const selectedHousesZippedWithSDRowDescForAgitationNew = infoMode === MAP_INFO_MODES.CONTACTS
        ? [] : selectedPolygons.map(p => {
            const foundStatsDataHouseOpt = (statsData?.entrances || []).find(sd => sd.houseId === p.properties.houseId)
            const groupedByMaterial = Object.entries(_.groupBy((foundStatsDataHouseOpt?.rows || []).flatMap(r => r.materialsAndCounts), (r) => r.materialId))
            const materialTitleAndTotalCounts = groupedByMaterial.map(
                ([item, mAndCRows], idx) => {
                    return ({
                        totalCountForHouse: _.sumBy(mAndCRows, mAndCRow => mAndCRow.count),
                        materialTitle: mAndCRows[0].materialTitle
                    })
                }
            )
            const totalMaterialsCountForHouse = materialTitleAndTotalCounts.reduce((sum, x) => sum + (x.totalCountForHouse || 0), 0)
            return [materialTitleAndTotalCounts, p, totalMaterialsCountForHouse]
        }).sort((a, b) => (b[2]) - (a[2]))

    const flyToHouse = (houseId) => {
        const feature = selectedPolygons.find(p => p.properties.houseId === houseId)
        if (feature) {
            mapRef.current.getMap().panTo(feature.geometry.coordinates[0][0][0], {zoom: 15})
            setTimeout(() => {
                setViewport(
                    {
                        longitude: feature.geometry.coordinates[0][0][0][0],
                        latitude: feature.geometry.coordinates[0][0][0][1],
                        zoom: 15
                    }
                )
            }, 300)

        }
    }

    return (
        <ContentScreen>
            <div className='w-100 d-flex flex-row justify-content-between align-items-start'>
                <div className='row w-100'>
                    <div className='col-12 col-md-9'>
                        <MapGL
                            ref={mapRef}
                            {...viewport}
                            width={'100%'}
                            className='geomap-wrapper'
                            height={600}
                            mapStyle={'mapbox://styles/ilyashevchenko/ckmkaim7030bu17s6n5sr6k04'}
                            onViewportChange={setViewport}
                            mapboxApiAccessToken={globalStore.getState().externalAdditionalInfo.commonSettings.mapSettings.mapToken}
                            interactiveLayerIds={currentlyShownLayerIdx !== null ? ['data', 'customLayer'] : ['data']}
                            onHover={onHover}
                            onClick={onMapClick}
                            preserveDrawingBuffer
                        >
                            <MapGeocoderSearchbar
                                viewport={viewport}
                                onSelected={(i) => {
                                    mapRef.current.getMap().panTo({lat: i.latitude, lon: i.longitude}, {zoom: 15})
                                    setTimeout(() => {
                                        setViewport(
                                            {
                                                longitude: i.longitude,
                                                latitude: i.latitude,
                                                zoom: i.zoom
                                            }
                                        )
                                    }, 300)
                                }}
                                mapRef={mapRef}
                            />
                            <Source type="geojson" data={data}>
                                <Layer {...dataLayer} />
                                {hoverInfo && (hoverInfo.feature.properties.houseId ?
                                    <MapHouseHoverTooltip
                                        hoverInfo={hoverInfo}
                                        infoMode={infoMode}
                                        aggregationParams={aggregationParams}/>
                                    :
                                    <div className='map-hover-tooltip'
                                         style={{left: hoverInfo.x, top: hoverInfo.y}}>
                                        <div># {hoverInfo.feature.properties.name}</div>
                                    </div>)
                                }
                            </Source>
                            {
                                currentlyShownLayerIdx !== null &&
                                <Source type="geojson" data={additionalLayers[currentlyShownLayerIdx].layer}>
                                    <Layer beforeId="data" {...customLayerRenderStyle} />
                                </Source>
                            }
                            <Editor
                                ref={editorRef}
                                style={{width: '100%', height: '100%'}}
                                clickRadius={12}
                                mode={mode}
                                onSelect={onEditorPointAdded}
                                onUpdate={onUpdate}
                                editHandleShape={'circle'}
                                featureStyle={getFeatureStyle}
                                editHandleStyle={getEditHandleStyle}
                            />
                            {drawTools}
                            {layerTools}
                            <FullscreenControl style={{right: 10, top: 10}}/>
                        </MapGL>
                    </div>
                    <div className='col-md-3 ps-md-2 right-panel'>
                        {
                            houseFullInfo
                                ? <MapHouseFullInfoPanel onBackPressed={() => setHouseFullInfo(null)}
                                                         houseFullInfo={houseFullInfo}/>
                                : <MapInfoPanel
                                    onPreferencesChanged={onPreferencesChanged}
                                    show={showTopPanel}
                                    statsData={statsData}
                                    infoMode={infoMode}
                                    setInfoMode={newMode => {
                                        setIsLoading(true);
                                        setInfoMode(newMode);
                                    }}
                                    isLoading={isLoading}/>
                        }
                        {
                            selectedHousesZippedWithSDRowDescForContactsNew && infoMode === MAP_INFO_MODES.CONTACTS &&
                            <>
                                <span className='d-flex justify-content-center'>
                                    <h4 className='text-center'>Контакты по домам</h4>
                                    <Icon className='ms-2 cursor-pointer'
                                          icon={duplicate24}
                                          width={20}
                                          onClick={() => onCopyAddressesToClipboardClicked("contacts")}
                                          color={COLORS.BLUE}
                                    />
                                </span>

                                <div className='list-group right-addresses-panel'>
                                    {selectedHousesZippedWithSDRowDescForContactsNew.map((zipped, idx) => {
                                            const houseContactCount = zipped[0]?.count || 0
                                            return (
                                                <a href="#" key={idx}
                                                   onClick={() => flyToHouse(zipped[1].properties.houseId)}
                                                   className="list-group-item list-group-item-action d-flex justify-content-between">
                                                    {zipped[1].properties.address}
                                                    <span
                                                        className={classnames('fw-bold', houseContactCount > 0 ? 'text-success' : 'text-danger')}>{houseContactCount}</span>
                                                </a>
                                            )
                                        }
                                    )}
                                </div>
                            </>
                        }
                        {
                            selectedHousesZippedWithSDRowDescForAgitationNew && infoMode === MAP_INFO_MODES.AGITATION &&
                            <>
                                                                <span className='d-flex justify-content-center'>
                                    <h4 className='text-center'>Материалы по домам</h4>
                                    <Icon className='ms-2 cursor-pointer'
                                          icon={duplicate24}
                                          width={20}
                                          onClick={() => onCopyAddressesToClipboardClicked("agitation")}
                                          color={COLORS.BLUE}
                                    />
                                </span>
                                <div className='list-group right-addresses-panel'>
                                    {selectedHousesZippedWithSDRowDescForAgitationNew.map((zipped, idx) =>
                                        <a href="#" key={idx}
                                           onClick={() => flyToHouse(zipped[1].properties.houseId)}
                                           className="list-group-item list-group-item-action d-flex flex-column">
                                            <div>
                                                {zipped[1].properties.address}
                                            </div>
                                            <span className='fw-bold'>{zipped[0].map(
                                                (countAndTitle, titleIdx) =>
                                                    <div key={titleIdx}
                                                         className='d-flex align-items-center justify-content-between'>
                                                        <span>{countAndTitle.materialTitle}</span>
                                                        <span>
                                                        <span
                                                            className='fw-bold text-success'>{countAndTitle.totalCountForHouse}</span>
                                                        <span>&nbsp;шт.</span>
                                                    </span>

                                                    </div>
                                            )}</span>
                                        </a>
                                    )}
                                </div>
                            </>
                        }

                    </div>
                </div>
            </div>
            <div className='d-flex flex-row w-100 align-items-start my-2'>
                <Button onClick={() => {
                    const imgUrl = mapRef.current.getMap().getCanvas().toDataURL('image/png')
                    const link = document.createElement('a');
                    link.href = imgUrl;
                    link.download = "MapExport";
                    link.click();
                }}>Печать <FontAwesomeIcon icon={faPrint}/></Button>
                <Dropdown className='mx-2'>
                    <Dropdown.Toggle variant={'outline-dark'}>
                        Отчёты <Icon icon={checklist16}/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item className='d-flex align-items-center' onClick={() =>
                            API.exportGeoReportByFlats(
                                globalStore.getState().authToken,
                                {houseIds: getHouseIdsForGeoRequest()},
                                aggregationParams
                            ).then(blob =>
                                blob ? TypedUtils.saveContentAsCSVFile(blob, 'report.xlsx') : addToast('Не удалось осуществить экспорт!', {
                                    appearance: 'error',
                                })
                            ).catch(e => {
                                console.log(e)
                                addToast('Не удалось осуществить экспорт!', {
                                    appearance: 'error',
                                });
                            })
                        }>
                            Поквартирный
                        </Dropdown.Item>
                        <Dropdown.Item className='d-flex align-items-center' onClick={() => {
                            let filters = [{
                                type: SearchTableFilterType.HOUSE_IDS,
                                value: getHouseIdsForGeoRequest().map(houseId => ({
                                    key: houseId,
                                    value: houseId,
                                    label: houseId
                                })),
                            }]
                            if (aggregationParams.from || aggregationParams.to) {
                                filters = filters.concat([
                                    {
                                        type: SearchTableFilterType.DATE_FROM_TO,
                                        value: {
                                            dateFrom: aggregationParams.from,
                                            dateTo: aggregationParams.to
                                        },
                                        defaultValue: EMPTY_FILTER_INFO
                                    }
                                ]);
                            }
                            API.exportContactsCSV(globalStore.getState().authToken, {
                                limit: 25,
                                offset: 0,
                                filters: filters,
                            }).then(blob =>
                                TypedUtils.saveContentAsCSVFile(blob, 'contacts.csv')
                            ).catch(e => {
                                console.log(e)
                                addToast('Не удалось осуществить экспорт!', {
                                    appearance: 'error',
                                });
                            })
                        }}>
                            Все контакты по домам
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </ContentScreen>
    )
}

export default MapScreen
