import {
    AddressModel,
    ExternalAdditionalInfo,
    ExternalAdditionalInfoUserModel
} from "../../models/ExternalAdditionalInfo";
import {
    UPDATE_AUTH_TOKEN,
    UPDATE_LOADED_DATA,
    UPDATE_ROUTE_SETTINGS,
    UPDATE_USER_INFO,
    UpdateAuthTokenAction,
    UpdateLoadedDataAction,
    UpdateRouteSettingsAction,
    UpdateUserInfoAction
} from "./types";
import {RouteSettings} from "./models";

export function updateLoadedDataAction(info: ExternalAdditionalInfo): UpdateLoadedDataAction {
    return {
        type: UPDATE_LOADED_DATA,
        payload: {
            info: info
        }
    }
}

export function updateUserInfoAction(data: ExternalAdditionalInfoUserModel): UpdateUserInfoAction {
    return {
        type: UPDATE_USER_INFO,
        payload: data
    }
}

export function updateRouteSettings(settings: RouteSettings): UpdateRouteSettingsAction {
    return {
        type: UPDATE_ROUTE_SETTINGS,
        payload: settings
    }
}
export function updateAuthToken(token: string): UpdateAuthTokenAction {
    return {
        type: UPDATE_AUTH_TOKEN,
        payload: token
    }
}