import {Age, Reaction, UserRole} from "../models/enums";
import React from "react";
import Icon from "@iconify/react";
import archive24 from "@iconify/icons-octicon/archive-24";
import {ApiContactGeo} from "../api/RequestModels";

export function getUserRoleDisplayName(role: UserRole): string {
    if (role === UserRole.CHIEF) {
        return "Начальник штаба";
    } else if (role === UserRole.ANALYST) {
        return "Аналитик"
    } else if (role === UserRole.FIELD) {
        return "Заведующий полем"
    } else if (role === UserRole.SCOUT) {
        return "Скаут"
    } else if (role === UserRole.SIGNATURE_SCOUT) {
        return "Сборщик подписей"
    } else if (role === UserRole.CALLS_EMPLOYEE) {
        return "Сотрудник КЦ"
    } else if (role === UserRole.CALLS_CHIEF) {
        return "Начальник КЦ"
    } else if (role === UserRole.HR) {
        return "HR"
    } else {
        return "????"
    }
}

export function getReactionDisplayName(r: Reaction): string {
    if (r === Reaction.VOLUNTEER) {
        return "Волонтёр";
    } else if (r === Reaction.BEST) {
        return "Сторонник";
    } else if (r === Reaction.GOOD) {
        return "Выслушал"
    } else if (r === Reaction.BAD) {
        return "Негатив"
    } else if (r === Reaction.WORST) {
        return "Неадекват"
    } else {
        return "????"
    }
}
export function getReactionFromName(r: string): Reaction | undefined {
    if (r === "Сторонник") {
        return Reaction.BEST;
    } else if (r === "Волонтёр") {
        return Reaction.VOLUNTEER;
    } else if (r === "Выслушал") {
        return Reaction.GOOD
    } else if (r === "Негатив") {
        return Reaction.BAD
    } else if (r === "Неадекват") {
        return Reaction.WORST
    } else {
        return undefined
    }
}

export function getAgeDisplayName(r: Age, showFull: boolean = true): string {
    if (r === Age.YOUNG) {
        return "18 - 24";
    } else if (r === Age.PREADULT) {
        return "25 - 39"
    } else if (r === Age.ADULT) {
        return "40 - 54"
    } else if (r === Age.ELDER) {
        return "55 +"
    } else if (r === Age.UNKNOWN && showFull) {
        return 'Неизвестен'
    } else {
        return "???"
    }
}

export const renderArchived = (isArchived: boolean) => {
    return (
        <div className='d-flex flex-row align-items-center'>
            {isArchived &&
            <Icon icon={archive24} color={'grey'} className='me-2'/>
            }
            {isArchived ? 'Архив' : 'Активный'}
        </div>
    )
}
export const makeFullAddressWithGeo = (fullAddress: String, geoOpt?: ApiContactGeo) => {
    if (!geoOpt) return fullAddress
    else return fullAddress + (geoOpt?.flatNo ? `, кв. ${geoOpt.flatNo}`: '')
}

export function generateUUIDV4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const convertHexToRGBA = (hexCode: string, opacity: number): string => {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
};

export const renderTelegramLink = (username: string) => {
    return (
        <a
            onClick={e => e.stopPropagation()}
            target={'_blank'}
            href={`https://telegram.me/${username}`}>{username}</a>
    )
}
export const renderWhatsAppLink = (phoneNumber: string) => {
    return (
        <a
            onClick={e => e.stopPropagation()}
            target={'_blank'}
            href={`https://api.whatsapp.com/send?phone=${phoneNumber}`}>{phoneNumber}</a>
    )
}

export const renderUserLink = (displayTitle: string, id: number, className?: string) => {
    return (
        <a
            className={className}
            onClick={e => e.stopPropagation()}
            target={'_blank'}
            href={`/user/${id}`}>{displayTitle}</a>
    )
}

export function saveContentAsCSVFile(blob: Blob, filename: string) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
}
