import * as ApiResponseMappers from './ApiResponseMappers'
import {ExternalAdditionalInfo, ExternalAdditionalInfoUserModel} from "../models/ExternalAdditionalInfo";
import {API_HOST} from "../utils/Consts";
import {APIAuthResponse, APICampaignSettings, APIExternalAdditionalInfo, ApiUser, APIUserInfo} from "./Models";
import {
    ApiCallScenarioUpsertModel,
    ApiCallScriptRequestModel,
    UpsertContactRequest,
    ApiUpsertAgitationMaterialRequest,
    ApiElectorCommonTableParameters,
    ApiElectorGeoMapsCommonParameters,
    ApiGeoForHousesAndDatesModel,
    ApiToggleShiftRequestModel,
    ApiUpsertPhoneCallRequest,
    ApiUpsertUserPaymentRequest,
    UpdateUserInfoRequest,
    ApiUpsertAgitationDistributionRequest,
    ApiUpdateMaterialCountForUserRequest,
    ApiGeoMapAdditionalLayerModel
} from "./RequestModels";
import {
    ApiActiveScenariosWithResultsSearchResponse,
    ApiAddressSearchResponse,
    ApiAgitationDistributionsResponse,
    ApiAgitationMaterialsByUsersResponse,
    ApiAgitationMaterialsResponse,
    APICallScriptResponse,
    ApiCallsGroupedByScenarioResponse,
    APICallShiftsResponse,
    ApiContactCreatedBySearchResponse,
    ApiContactInfoResponseRow,
    APIContactsBriefInfoResponse,
    APIContactsBriefInfosWithCallsResponse, ApiDistinctCallCountsByContactsResponse,
    ApiHqAuditLogsResponse,
    ApiHqContactAggregatesResponse,
    ApiHqContactsGeoAggregatesResponse, ApiHqContactsImportResponse,
    ApiHqGeoAgitationResponse, ApiHqGeoSublayerResponse, ApiHqPhoneCallsImportResponse, ApiHqUpsertAddressesResponse,
    ApiPaymentsResponse,
    ApiPaymentSuggestionResponse,
    ApiPhoneCallByIdResponse,
    ApiPhoneCallsResponse, ApiPossibleThematicsResponse,
    APIScenarioResponse,
    APIScenariosBriefInfoResponse,
    DashboardAnalyticsResponse,
    GeoReactionStatsResponse,
    ScenarioAndScriptForShiftResponse,
    UsersInfoResponse,
} from "./ResponseModels";
import {globalStore} from "../utils/redux/ReduxUtils";
import {updateAuthToken} from "../utils/redux/Actions";
import {UserModel} from "../models/UserModel";
import {CallScriptModel} from "../models/CallScriptModel";
import {momentToLocalDateTimeString} from "./ApiRequestMappers";
import {SearchTableFilterType} from "../models/ApiFilterEnums";

export const authorize = (login: string, password: string) => {
    return requestPostAPI<APIAuthResponse>(API_HOST + "/hq/authorize", JSON.stringify({
        login: login,
        password: password
    }))
}

export async function loadAdditionalInfo(authToken: string): Promise<ExternalAdditionalInfo> {
    const apiInfo = await requestGetAPI<APIExternalAdditionalInfo>(API_HOST + "/common/loadGeneralInfo", authToken)
    return ApiResponseMappers.mapToExternalAdditionalInfo(apiInfo)
}

export async function checkHealth(authToken: string): Promise<any> {
    return await requestGetAPI<APIExternalAdditionalInfo>(API_HOST + "/common/health", authToken)
}

export async function updateUserInfo(req: UpdateUserInfoRequest, authToken: string): Promise<ExternalAdditionalInfoUserModel> {
    const apiUser = await requestPostAPI<APIUserInfo>(API_HOST + "/common/updateUserInfo", JSON.stringify(req), authToken)
    return ApiResponseMappers.mapToExternalAdditionalInfoUserModel(apiUser)
}

export async function loadGeoInfo(authToken: string): Promise<any> {
    return await requestGetAPI<object>(API_HOST + "/hq/getGeoInfo", authToken)
}
export async function loadHouseInfo(houseId: number, authToken: string): Promise<any> {
    return await requestGetAPI<object>(API_HOST + `/hq/geo/houseInfo/${houseId}`, authToken)
}
export async function getAdditionalMapLayers(authToken: string): Promise<ApiGeoMapAdditionalLayerModel[]> {
    return await requestGetAPI(API_HOST + "/hq/getAdditionalMapLayers", authToken)
}

export async function loadGeoContactAggregates(req: ApiGeoForHousesAndDatesModel, params: ApiElectorGeoMapsCommonParameters, authToken: string): Promise<ApiHqContactsGeoAggregatesResponse> {
    return await requestPostAPI<ApiHqContactsGeoAggregatesResponse>(API_HOST + "/hq/geo/map/contacts?" + geoMapsCommonParamsToQueryParams(params), JSON.stringify(req), authToken)
}

export async function loadGeoAgitationAggregates(req: ApiGeoForHousesAndDatesModel, params: ApiElectorGeoMapsCommonParameters, authToken: string): Promise<ApiHqGeoAgitationResponse> {
    return await requestPostAPI<ApiHqGeoAgitationResponse>(API_HOST + "/hq/geo/map/agitation?" + geoMapsCommonParamsToQueryParams(params), JSON.stringify(req), authToken)
}

export async function loadGeoInfoForReactions(req: ApiGeoForHousesAndDatesModel, authToken: string): Promise<any> {
    return await requestPostAPI<GeoReactionStatsResponse>(API_HOST + "/hq/getGeoReactions", JSON.stringify(req), authToken)
}

export async function getContacts(params: ApiElectorCommonTableParameters, authToken: string): Promise<APIContactsBriefInfoResponse> {
    return await requestGetAPI<APIContactsBriefInfoResponse>(API_HOST + "/hq/contacts?" + commonElectorParamsToQueryParams(params), authToken)
}

export async function getContactsWithCalls(params: ApiElectorCommonTableParameters, authToken: string): Promise<APIContactsBriefInfosWithCallsResponse> {
    return await requestGetAPI(API_HOST + "/hq/contacts/withCalls?" + commonElectorParamsToQueryParams(params), authToken)
}

export async function getPayments(params: ApiElectorCommonTableParameters, authToken: string): Promise<ApiPaymentsResponse> {
    return await requestGetAPI<ApiPaymentsResponse>(API_HOST + "/hq/payments?" + commonElectorParamsToQueryParams(params), authToken)
}

export async function getContactsAggregateInfo(params: ApiElectorCommonTableParameters, authToken: string): Promise<ApiHqContactAggregatesResponse> {
    return await requestGetAPI(API_HOST + "/hq/contacts/aggregate-info?" + commonElectorParamsToQueryParams(params), authToken)
}

export async function getAuditLogs(params: ApiElectorCommonTableParameters, authToken: string): Promise<ApiHqAuditLogsResponse> {
    return await requestGetAPI<ApiHqAuditLogsResponse>(API_HOST + "/hq/audit/logs?" + commonElectorParamsToQueryParams(params), authToken)
}

export async function getUsers(params: ApiElectorCommonTableParameters, authToken: string): Promise<UsersInfoResponse> {
    return await requestGetAPI<UsersInfoResponse>(API_HOST + "/hq/users?" + commonElectorParamsToQueryParams(params), authToken)
}

export async function getCampaignSettings(authToken: string): Promise<APICampaignSettings> {
    return await requestGetAPI<APICampaignSettings>(API_HOST + "/hq/campaign/settings", authToken)
}

export async function getAllCallScripts(authToken: string): Promise<CallScriptModel[]> {
    const res = await requestGetAPI<APICallScriptResponse[]>(API_HOST + "/hq/calls/scripts", authToken)
    return res.map(ApiResponseMappers.mapApiCallScript)
}

export async function saveCallScript(req: ApiCallScriptRequestModel, authToken: string): Promise<any> {
    return requestPostAPI<any>(API_HOST + "/hq/calls/script", JSON.stringify(req), authToken)
}

export async function saveCallScenario(req: ApiCallScenarioUpsertModel, authToken: string): Promise<any> {
    return requestPostAPI<any>(API_HOST + "/hq/calls/scenario", JSON.stringify(req), authToken)
}

export async function getAllScenarios(authToken: string): Promise<APIScenariosBriefInfoResponse> {
    return await requestGetAPI<APIScenariosBriefInfoResponse>(API_HOST + "/hq/calls/scenarios", authToken)
}

export async function getScenarioById(id: number, authToken: string): Promise<APIScenarioResponse> {
    return await requestGetAPI<APIScenarioResponse>(API_HOST + `/hq/calls/scenarios/${id}`, authToken)
}

export async function getScriptById(id: number, authToken: string): Promise<CallScriptModel> {
    return await requestGetAPI<CallScriptModel>(API_HOST + `/hq/calls/scripts/${id}`, authToken)
}

export async function getShifts(authToken: string): Promise<APICallShiftsResponse> {
    return await requestGetAPI<APICallShiftsResponse>(API_HOST + `/hq/calls/shifts`, authToken)
}

export async function toggleShift(req: ApiToggleShiftRequestModel, authToken: string): Promise<any> {
    return await requestPostAPI<any>(API_HOST + `/hq/calls/shifts`, JSON.stringify(req), authToken)
}

export async function getExamplePaymentSuggestionForSettings(req: APICampaignSettings, authToken: string): Promise<ApiPaymentSuggestionResponse> {
    return await requestPostAPI<ApiPaymentSuggestionResponse>(API_HOST + "/hq/payments/suggest/example", JSON.stringify(req), authToken)
}

export async function getPaymentSuggestionForUser(userId: number, authToken: string): Promise<ApiPaymentSuggestionResponse> {
    return await requestGetAPI<ApiPaymentSuggestionResponse>(API_HOST + `/hq/payments/suggest/${userId}`, authToken)
}

export async function updateCampaignSettings(req: APICampaignSettings, authToken: string): Promise<APICampaignSettings> {
    return await requestPostAPI<APICampaignSettings>(API_HOST + "/hq/campaign/settings", JSON.stringify(req), authToken)
}

export async function getContactById(id: number, authToken: string): Promise<ApiContactInfoResponseRow> {
    return await requestGetAPI<ApiContactInfoResponseRow>(API_HOST + `/hq/contact/${id}`, authToken)
}

export async function getPhoneCallContactById(id: number, authToken: string): Promise<ApiContactInfoResponseRow> {
    return await requestGetAPI<ApiContactInfoResponseRow>(API_HOST + `/hq/calls/contact/${id}`, authToken)
}

export async function getUserById(id: number, authToken: string): Promise<UserModel> {
    const userResponse = await requestGetAPI<ApiUser>(API_HOST + `/hq/user/${id}`, authToken)
    return ApiResponseMappers.mapApiUserToUser(userResponse)
}

export async function deleteContact(contactId: number, authToken: string): Promise<any> {
    return await requestDeleteAPI(API_HOST + `/hq/contact/${contactId}`, authToken)
}

export async function deletePayment(paymentId: number, authToken: string): Promise<any> {
    return await requestDeleteAPI(API_HOST + `/hq/payment/${paymentId}`, authToken)
}

export async function deleteUser(userId: number, authToken: string): Promise<any> {
    return await requestDeleteAPI(API_HOST + `/hq/user/${userId}`, authToken)
}

export async function createPayment(req: ApiUpsertUserPaymentRequest, authToken: string): Promise<any> {
    return await requestPostAPI(API_HOST + `/hq/payment`, JSON.stringify(req), authToken)
}

export async function upsertContact(contact: UpsertContactRequest, authToken: string): Promise<any> {
    return await requestPostAPI(API_HOST + `/hq/contact`, JSON.stringify(contact), authToken)
}

export async function upsertUser(user: ApiUser, authToken: string): Promise<UserModel> {
    const userResponse = await requestPostAPI<ApiUser>(API_HOST + `/hq/user`, JSON.stringify(user), authToken)
    return ApiResponseMappers.mapApiUserToUser(userResponse)
}

export async function saveMaterial(materialId: number, req: ApiUpsertAgitationMaterialRequest, authToken: string): Promise<any> {
    return requestPostAPI<any>(API_HOST + `/hq/field/agitation-materials/${materialId}`, JSON.stringify(req), authToken)
}
export async function updateMaterialGivenCountForUser(userId: number, req: ApiUpdateMaterialCountForUserRequest, authToken: string): Promise<any> {
    return requestPostAPI<any>(API_HOST + `/hq/field/agitation-materials/update-for-user/${userId}`, JSON.stringify(req), authToken)
}

export async function getMyCalls(authToken: string): Promise<ApiCallsGroupedByScenarioResponse> {
    return requestGetAPI<ApiCallsGroupedByScenarioResponse>(API_HOST + `/hq/calls/me`, authToken)
}

export async function getPhoneCallByIdForEmployee(id: number, authToken: string): Promise<ApiPhoneCallByIdResponse> {
    return requestGetAPI<ApiPhoneCallByIdResponse>(API_HOST + `/hq/calls/me/${id}`, authToken)
}

export async function getPhoneCallById(id: number, authToken: string): Promise<ApiPhoneCallByIdResponse> {
    return requestGetAPI<ApiPhoneCallByIdResponse>(API_HOST + `/hq/calls/${id}`, authToken)
}

export async function getScenarioAndScriptForShift(id: number, authToken: string): Promise<ScenarioAndScriptForShiftResponse> {
    return requestGetAPI<ScenarioAndScriptForShiftResponse>(API_HOST + `/hq/calls/scenario-script?shiftId=${id}`, authToken)
}

export async function savePhoneCallForEmployee(req: ApiUpsertPhoneCallRequest, authToken: string): Promise<any> {
    return requestPostAPI<any>(API_HOST + `/hq/calls/me`, JSON.stringify(req), authToken)
}

export async function savePhoneCall(req: ApiUpsertPhoneCallRequest, authToken: string): Promise<any> {
    return requestPostAPI<any>(API_HOST + `/hq/calls`, JSON.stringify(req), authToken)
}

export async function getAllPhoneCalls(params: ApiElectorCommonTableParameters, authToken: string): Promise<ApiPhoneCallsResponse> {
    return await requestGetAPI<ApiPhoneCallsResponse>(API_HOST + "/hq/calls?" + commonElectorParamsToQueryParams(params), authToken)
}

export async function getAllMaterials(authToken: string, params: ApiElectorCommonTableParameters): Promise<ApiAgitationMaterialsResponse> {
    return requestGetAPI<ApiAgitationMaterialsResponse>(API_HOST + `/hq/field/agitation-materials?` + commonElectorParamsToQueryParams(params), authToken)
}

export async function getAllMaterialsDistributions(params: ApiElectorCommonTableParameters, authToken: string): Promise<ApiAgitationDistributionsResponse> {
    return requestGetAPI<ApiAgitationDistributionsResponse>(API_HOST + `/hq/field/agitation-distributions?` + commonElectorParamsToQueryParams(params), authToken)
}

export async function getAllMaterialDistributionsByUsers(authToken: string, params: ApiElectorCommonTableParameters): Promise<ApiAgitationMaterialsByUsersResponse> {
    return requestGetAPI<ApiAgitationMaterialsByUsersResponse>(API_HOST + `/hq/field/agitation-distributions/by-user?` + commonElectorParamsToQueryParams(params), authToken)
}

export async function saveAgitationDistribution(req: ApiUpsertAgitationDistributionRequest, authToken: string): Promise<any> {
    return requestPostAPI<any>(API_HOST + `/hq/field/agitation-distribution`, JSON.stringify(req), authToken)
}
export async function deleteAgitationDistribution(distributionId: number, authToken: string): Promise<any> {
    return requestDeleteAPI<any>(API_HOST + `/hq/field/agitation-distribution/${distributionId}`, authToken)
}

export async function loadDashboardStats(authToken: string): Promise<DashboardAnalyticsResponse> {
    return await requestGetAPI<DashboardAnalyticsResponse>(API_HOST + '/hq/dashboard', authToken)
}

export async function searchAddresses(searchQuery: string | undefined, authToken: string): Promise<ApiAddressSearchResponse> {
    return await requestGetAPI(API_HOST + `/hq/search/address?search=${searchQuery || ''}`, authToken)
}
export async function getAllActiveScenariosWithResults(authToken: string): Promise<ApiActiveScenariosWithResultsSearchResponse> {
    return await requestGetAPI(API_HOST + `/hq/search/calls/scenarioResults`, authToken)
}
export async function getAllPossibleContactThematics(authToken: string): Promise<ApiPossibleThematicsResponse> {
    return await requestGetAPI(API_HOST + `/hq/search/contacts/thematics`, authToken)
}
export async function getAllCampaignMapSubLayers(authToken: string): Promise<ApiHqGeoSublayerResponse> {
    return await requestGetAPI(API_HOST + `/hq/search/geo/map/sublayers`, authToken)
}
export async function getAllDistinctContactCallCounts(authToken: string): Promise<ApiDistinctCallCountsByContactsResponse> {
    return await requestGetAPI(API_HOST + `/hq/search/contacts/callsCount`, authToken)
}
export async function importAddressesGeoJSON(rawJson: string, authToken: string): Promise<ApiHqUpsertAddressesResponse> {
    return await requestPostAPI(
        API_HOST + `/hq/campaign/geo/address`,
        rawJson,
        authToken,
    )
}

export async function searchContactCreatedBy(searchQuery: string | undefined, authToken: string): Promise<ApiContactCreatedBySearchResponse> {
    return await requestGetAPI(API_HOST + `/hq/search/contacts/createdBy?search=${searchQuery || ''}`, authToken)
}

export async function searchCallCreatedBy(searchQuery: string | undefined, authToken: string): Promise<ApiContactCreatedBySearchResponse> {
    return await requestGetAPI(API_HOST + `/hq/search/calls/createdBy?search=${searchQuery || ''}`, authToken)
}

export async function exportContactsCSV(authToken: string, params: ApiElectorCommonTableParameters): Promise<any> {
    return await requestGetAPI(
        API_HOST + `/hq/contacts/export?` + commonElectorParamsToQueryParams(params),
        authToken,
        commonCSVResponseHandler,
    )
}
export async function exportPhoneCallsCSV(authToken: string, params: ApiElectorCommonTableParameters): Promise<any> {
    return await requestGetAPI(
        API_HOST + `/hq/calls/export?` + commonElectorParamsToQueryParams(params),
        authToken,
        commonCSVResponseHandler,
    )
}

export async function exportGeoReportByFlats(authToken: string, req: ApiGeoForHousesAndDatesModel, params: ApiElectorGeoMapsCommonParameters): Promise<any> {
    return await requestPostAPI<any>(
        API_HOST + `/hq/geo/map/report/byFlats?` + geoMapsCommonParamsToQueryParams(params),
        JSON.stringify(req),
        authToken,
        undefined,
        commonCSVResponseHandler
    )
}
export async function exportAllAddressesCSV(authToken: string): Promise<any> {
    return await requestGetAPI<any>(
        API_HOST + `/hq/geo/allAddresses`,
        authToken,
        commonCSVResponseHandler
    )
}

export async function importContactsCSV(authToken: string, rawCsv: string, createNonExistingScouts: boolean): Promise<ApiHqContactsImportResponse> {
    const additionalHeaders: Record<string, string> = {'Content-Type': 'text/csv'}
    return await requestPostAPI(
        API_HOST + `/hq/contacts/import?createNonExistingScouts=${createNonExistingScouts}`,
        rawCsv,
        authToken,
        additionalHeaders
    )
}
export async function importPhoneCallsCSV(authToken: string, rawCsv: string): Promise<ApiHqPhoneCallsImportResponse> {
    const additionalHeaders: Record<string, string> = {'Content-Type': 'text/csv'}
    return await requestPostAPI(
        API_HOST + `/hq/calls/import`,
        rawCsv,
        authToken,
        additionalHeaders
    )
}

// Implementation code where T is the returned data shape
function requestGetAPI<T>(url: string, authToken: string, customResponseHandler?: (response: Response) => Promise<T>): Promise<T> {
    console.log("making GET req to " + url);
    return fetch(url, {headers: new Headers({'Authorization': authToken})})
        .then(response => customResponseHandler ? customResponseHandler(response) : commonResponseHandler(response))
}

function requestDeleteAPI<T>(url: string, authToken: string): Promise<T> {
    console.log("making DELETE req to " + url);
    return fetch(url, {
        headers: new Headers({'Authorization': authToken}),
        method: 'DELETE'
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response.json() as Promise<T>
        })
}

function requestPostAPI<T>(url: string,
                           body: string,
                           authTokenOpt?: string,
                           additionalHeaders?: Record<string, string>,
                           customResponseHandler?: (response: Response) => Promise<T>): Promise<T> {
    console.log(`making POST req to ${url} body ${body}`);
    return fetch(url, {
        method: 'POST',
        headers: {
            ...{
                'Content-Type': 'application/json',
                'Authorization': authTokenOpt || '',
            },
            ...(additionalHeaders ? additionalHeaders : {})
        },
        body: body
    }).then(response => customResponseHandler ? customResponseHandler(response) : commonResponseHandler(response));
}

function commonResponseHandler<T>(response: Response): Promise<T> {
    if (response.status === 401) {
        globalStore.dispatch(updateAuthToken(''))
    } else if (!response.ok) {
        return Promise.reject(new Error(response.statusText))
    }
    return response.json() as Promise<T>;
}

async function commonCSVResponseHandler<T>(response: Response): Promise<Blob> {
    if (response.status === 401) {
        globalStore.dispatch(updateAuthToken(''))
    } else if (!response.ok) {
        return Promise.reject(new Error(response.statusText))
    }
    return response.blob()
}

export function commonElectorParamsToQueryParams(p: ApiElectorCommonTableParameters): string {
    let res = `limit=${p.limit}&offset=${p.offset}`
    if (p.search) res += `&search=${p.search}`
    if (p.sort) {
        res += `&sort=${p.sort.criteria}~${p.sort.direction}`
    }

    if (p.filters && p.filters.length > 0) {
        p.filters.forEach(f => {
            if (f.type === SearchTableFilterType.DATE_FROM_TO) {
                if (f.value.dateFrom) res += `&from=${momentToLocalDateTimeString(f.value.dateFrom)}`
                if (f.value.dateTo) res += `&to=${momentToLocalDateTimeString(f.value.dateTo)}`
            } else if (f.type === SearchTableFilterType.HOUSE_ID && f.value) {
                res += `&filter=${f.type}~${f.value.key}`
            } else if (f.type === SearchTableFilterType.HOUSE_IDS && f.value) {
                const valuesArr = (f.value || []).map(s => s.key)
                res += `&filter=${f.type}~${valuesArr.join("///")}`
            } else if (f.type === SearchTableFilterType.THEMATICS && f.value) {
                const valuesArr = (f.value || []).map(s => s.key)
                res += `&filter=${f.type}~${valuesArr.join("///")}`
            } else if (f.type === SearchTableFilterType.CONTACT_CALLS_COUNT && f.value) {
                res += `&filter=${f.type}~${f.value.key}`
            } else if (f.type === SearchTableFilterType.CALL_SCENARIO_RESULT && f.value) {
                res += `&filter=${f.type}~${f.value.value.scenarioId}/${f.value.value.result}`
            } else if (f.type === SearchTableFilterType.CALL_SCENARIO_LATEST_RESULT && f.value) {
                res += `&filter=${f.type}~${f.value.value.scenarioId}/${f.value.value.result}`
            } else if (f.type === SearchTableFilterType.CALL_SCENARIO_HAS_NO_RESULT_EXCEPT && f.value) {
                const filterStrings = f.value.map((v) =>
                    `${v.value.scenarioId}/${v.value.result}`
                )
                res += `&filter=${f.type}~${filterStrings.join('///')}`
            } else if (f.type === SearchTableFilterType.LAYER_ID_NAME && f.value) {
                const filterStrings = f.value.map((v) =>
                    `${v.value.layerId}/${v.value.name}`
                )
                res += `&filter=${f.type}~${filterStrings.join('///')}`
            } else if (f.type === SearchTableFilterType.CREATED_BY && f.value) {
                const valuesArr = (f.value || []).map(s => s.key)
                res += `&filter=${f.type}~${valuesArr.join("///")}`
            } else if (f.type === SearchTableFilterType.CALL_CREATED_BY && f.value) {
                res += `&filter=${f.type}~${f.value.key}`
            } else {
                res += `&filter=${f.type}~${f.value}`
            }
        })
    }

    return res
}

function geoMapsCommonParamsToQueryParams(p: ApiElectorGeoMapsCommonParameters): string {
    let res = ``
    if (p.from) res += `&from=${momentToLocalDateTimeString(p.from)}`
    if (p.to) res += `&to=${momentToLocalDateTimeString(p.to)}`
    p.dataTypes.forEach(dt =>
        res += `&dataTypes=${dt}`
    )

    return res
}
