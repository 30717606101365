import {Badge, IconButton, MenuItem, Toolbar, Tooltip, Typography} from "@material-ui/core";
import Icon from "@iconify/react";
import check16 from "@iconify/icons-octicon/check-16";
import FilterListIcon from "@material-ui/icons/FilterList";
import SettingsIcon from "@material-ui/icons/Settings";
import React, {ReactNode, useState} from "react";
import classnames from "classnames";
import {Button} from "react-bootstrap-v5";
import {RemoteSearchTableFilterModel} from "../../../models/RemoteSearchTableFilterModel";
import * as TableFiltersDefaultRenderer from "./filters/TableFiltersDefaultRenderer";
import { Menu } from "@material-ui/core";

interface EnhancedTableToolbarProps {
    allFilters: RemoteSearchTableFilterModel[]
    numSelected: number;
    onCheckPressed: () => void
    title?: string
    onFilterValueChanged?: (value: any, idx: number) => void
    onApplyFiltersPressed: () => void
    onHideFiltersPanelPressed: () => void
    additionalToolbarElements?: ReactNode
    appliedFiltersCount: number
}

export const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const {numSelected} = props;
    const [filtersPanelOpened, setFiltersPanelOpened] = useState(false)
    const hasSelectedRows = numSelected > 0

    const onApplyFiltersPressed = () => {
        setFiltersPanelOpened(false)
        props.onApplyFiltersPressed && props.onApplyFiltersPressed()
    }
    return (
        <Toolbar variant='dense'
                 style={{minHeight: 32}}
                 className={classnames('flex-column', hasSelectedRows ? 'table-toolbar-selected-rows' : '')}>
            <div className='col-12 d-flex flex-row align-items-center'>
                {hasSelectedRows ? (
                    <Typography style={{flex: '1 1 100%'}} color="inherit" variant="subtitle1" component="div">
                        {numSelected} выбрано
                    </Typography>
                ) : (
                    <Typography style={{flex: '1 1 100%'}} variant="h6" id="tableTitle" component="div">
                        {`${props.title || ''}`}
                    </Typography>
                )}
                {
                    hasSelectedRows &&
                    <Tooltip title="Готово" onClick={props.onCheckPressed}>
                        <IconButton aria-label="Готово">
                            <Icon icon={check16} width={24} color={'black'}/>
                        </IconButton>
                    </Tooltip>
                }
                {
                    props.allFilters.length > 0 &&
                    <Tooltip title="Фильтры" onClick={() => {
                        if (filtersPanelOpened) {
                            setFiltersPanelOpened(false)
                            props.onHideFiltersPanelPressed()
                        } else setFiltersPanelOpened(!filtersPanelOpened)
                    }}>
                        <IconButton aria-label="Фильтры">
                            <Badge invisible={props.appliedFiltersCount === 0} badgeContent={props.appliedFiltersCount}
                                   anchorOrigin={{
                                       vertical: 'bottom',
                                       horizontal: 'right',
                                   }}
                                   color='primary'>
                                <FilterListIcon/>
                            </Badge>
                        </IconButton>
                    </Tooltip>
                }
                {
                    props.additionalToolbarElements ? props.additionalToolbarElements : null
                }
            </div>
            {
                props.allFilters.length > 0 &&
                <div
                    className={classnames(
                        'w-100 row flex-wrap gy-3 search-table__filters-panel d-flex flex-row pt-2', filtersPanelOpened ? 'visible' : ''
                    )}>
                    {
                        props.allFilters.map((f, idx) =>
                            TableFiltersDefaultRenderer.renderFilter(
                                f,
                                idx,
                                (v) => props.onFilterValueChanged && props.onFilterValueChanged(v, idx)))
                    }
                    <div className={'col-sm-3 col-2 ms-auto align-self-end d-flex flex-row justify-content-end'}>
                        <Button variant={'primary'} onClick={onApplyFiltersPressed}>Применить фильтры</Button>
                    </div>
                </div>
            }

        </Toolbar>
    );
}
