import React from "react";
import * as API from "../../api/API";
import {ApiHqAuditLogsResponse, ApiHqAuditLogsResponseRow} from "../../api/ResponseModels";
import {globalStore} from "../../utils/redux/ReduxUtils";
import RemoteSearchTable, {SearchTableHeaderCell} from "../components/search/RemoteSearchTable";
import {ApiSortCriteria} from "../../models/enums";
import {ApiElectorCommonTableParameters} from "../../api/RequestModels";
import {ApiUser} from "../../api/Models";
import {TableCell} from "@material-ui/core";
import * as TypedUtils from "../../utils/TypedUtils";
import {commonDateTimeFormat} from "../../utils/Formatters";
import moment from "moment";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";


const HEADER_CELLS: SearchTableHeaderCell[] = [
    {
        id: 'content',
        disablePadding: false,
        label: 'Текст события',
    },
    {
        id: 'author',
        disablePadding: false,
        label: 'Автор',
    },
    {
        id: 'role',
        disablePadding: false,
        label: 'Должность',
    },
    {id: ApiSortCriteria.CREATED_AT, disablePadding: false, label: 'Создан', sortCriteria: ApiSortCriteria.CREATED_AT},
];


interface Props {
    onRowSelected?: (row: ApiHqAuditLogsResponseRow) => void
    toastManager: ToastConsumerContext
}

class AuditSearchTable extends React.Component<Props, {}> {
    loadRows = async (params: ApiElectorCommonTableParameters) => {
        const users = await API.getAuditLogs(params, globalStore.getState().authToken)
        return users.rows
    }

    _renderCells = (row: ApiHqAuditLogsResponseRow) => {
        return ([
            <TableCell style={{whiteSpace: "pre-line"}} align='left'>{`${row.content}`}</TableCell>,
            <TableCell align='left'>{TypedUtils.renderUserLink(row.createdBy.fullName, row.createdBy.id)}</TableCell>,
            <TableCell align='left'>{TypedUtils.getUserRoleDisplayName(row.createdBy.role)}</TableCell>,
            <TableCell align="left">{commonDateTimeFormat(moment(row.createdAt))}</TableCell>,
        ])
    }


    render() {
        return (
            <RemoteSearchTable
                searchPlaceholder={'Текст события или автор'}
                rowKeyExtractor={(u: ApiHqAuditLogsResponseRow) => u.id.toString()}
                renderTableCells={(row: ApiHqAuditLogsResponseRow) => this._renderCells(row)}
                headerCells={HEADER_CELLS}
                loadRows={this.loadRows}
                toastManager={this.props.toastManager}/>
        );
    }
}

export default withToastManager(AuditSearchTable)
