import React, {useEffect, useState} from "react";
import {withRouter} from 'react-router-dom';
import {globalStore} from "../../utils/redux/ReduxUtils";
import ContentScreen from "../ContentScreen";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator_simple.css'; // required styles
import ReactEcharts from "echarts-for-react";
import * as API from "../../api/API";
import {Age, ContactType, Gender} from "../../models/enums";
import {ReactTabulator} from "react-tabulator";
import {RouteComponentProps} from "react-router";
import {DashboardAnalyticsResponse} from "../../api/ResponseModels";
import moment from "moment";
import {getAgeDisplayName} from "../../utils/TypedUtils";
import {useToasts} from "react-toast-notifications";

interface Props extends RouteComponentProps {
}

const HomeScreen = (props: Props) => {
    const [data, setData] = useState(undefined as DashboardAnalyticsResponse | undefined)
    const {addToast} = useToasts()

    const loadStats = async () => {
        const authToken = globalStore.getState().authToken
        const result = await API.loadDashboardStats(authToken)
        setData(result)
    }
    useEffect(() => {
        loadStats().catch(err => {
            addToast('Не удалось загрузить информацию аналитики!', {
                appearance: 'error',
            });
            console.log(err)
        })
    }, [])

    const DEFAULT_OPTIONS = {
        legend: {
            data: ['Всего', 'Квартиры', 'Куб', 'Промо'],
            top: 18,
            textStyle: {
                fontSize: 12,
                color: "#1a202c",
                fontFamily: "Ubuntu"
            }
        },
        grid: {
            height: 340,
            left: 50,
            right: 50,
            textStyle: {fontFamily: "Ubuntu"}
        },
        dataZoom: [{
            left: 48,
            textStyle: {fontFamily: "Ubuntu"}
        }],
        tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(45, 55, 72, .85)',
            axisPointer: {
                type: 'line'
            }
        },
        yAxis: {
            type: 'value',
        }
    }
    const getRowsByContactType = (type: ContactType) => {
        return data?.contactTypes.flatMap(ct => ct.rows.filter(r => r.value === type)) || []
    }

    const oddRows = getRowsByContactType(ContactType.ODD)
    const cubeRows = getRowsByContactType(ContactType.STREET_CUBE)
    const promoRows = getRowsByContactType(ContactType.STREET_PROMO)

    const showOddRows = oddRows.length > 0 && oddRows[oddRows.length - 1].totalForThisDay > 0
    const showCubeRows = cubeRows.length > 0 && cubeRows[cubeRows.length - 1].totalForThisDay > 0
    const showPromoRows = promoRows.length > 0 && promoRows[promoRows.length - 1].totalForThisDay > 0

    const contactTypeAlltimeOption = {
        ...DEFAULT_OPTIONS,
        ...{
            legend: {
                data: [
                    'Всего',
                    showOddRows ? 'Квартиры' : null,
                    showCubeRows ? 'Куб' : null,
                    showPromoRows ? 'Промо' : null,
                ].filter(v => v)
            },
            xAxis: {
                type: 'category',
                data: data?.contactTypes.map(ct => moment(ct.date).format('DD') + '\n' + moment(ct.date).format('MMM')) || [],
            },
            series: [{
                name: 'Всего',
                data: data?.contactTypes.map(ct => ct.totalForThisDay),
                type: 'line',
                areaStyle: {},
                color: '#206bc4',
            },
                ...(showOddRows ? [{
                    name: 'Квартиры',
                    data: oddRows.map(r => r.totalForThisDay),
                    type: 'line',
                    areaStyle: {},
                    color: '#7a5195',
                }] : []),
                ...(showCubeRows ? [{
                    name: 'Куб',
                    data: cubeRows.map(r => r.totalForThisDay),
                    type: 'line',
                    areaStyle: {},
                    color: '#ef5675',
                }] : []),
                ...(showPromoRows ? [{
                    name: 'Промо',
                    data: promoRows.map(r => r.totalForThisDay),
                    type: 'line',
                    areaStyle: {},
                    color: '#ffa600',
                }] : [])
            ]
        }
    }
    const contactTypeByDaysOption = {
        ...contactTypeAlltimeOption,
        ...{
            tooltip: {
                trigger: 'axis',
                backgroundColor: 'rgba(45, 55, 72, .95)',
                axisPointer: {
                    type: 'shadow'
                }
            },
            series: [
                ...(showOddRows ? [{
                    name: 'Квартиры',
                    data: oddRows.map(r => r.count),
                    type: 'bar',
                    stack: 'По дням',
                    label: {
                        show: false
                    },
                    color: '#7a5195'
                }] : []),
                ...(showCubeRows ? [{
                    name: 'Куб',
                    data: cubeRows.map(r => r.count),
                    type: 'bar',
                    stack: 'По дням',
                    label: {
                        show: false
                    },
                    color: '#ef5675',
                }] : []),
                ...(showPromoRows ? [{
                    name: 'Промо',
                    data: promoRows.map(r => r.count),
                    type: 'bar',
                    stack: 'По дням',
                    label: {
                        show: false
                    },
                    color: '#ffa600',
                }] : []),

            ]

        }
    }

    const femaleRows = data?.genders.flatMap(r => r.rows.filter(r => r.value === Gender.FEMALE)) || []
    const maleRows = data?.genders.flatMap(r => r.rows.filter(r => r.value === Gender.MALE)) || []

    const genderTypeOption = {
        ...DEFAULT_OPTIONS,
        ...{
            legend: {
                data: [
                    'Мужчин', 'Женщин'
                ]
            },
            tooltip: {
                trigger: 'axis',
                backgroundColor: 'rgba(45, 55, 72, .95)',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: {
                type: 'category',
                data: data?.genders.map(ct => moment(ct.date).format('DD') + '\n' + moment(ct.date).format('MMM')) || [],
            },
            series: [
                {
                    name: 'Мужчин',
                    data: maleRows.map(fr => fr.count),
                    type: 'line',
                    stack: 'По дням',
                    label: {
                        show: false
                    },
                    color: '#7a5195',
                },
                {
                    name: 'Женщин',
                    data: femaleRows.map(fr => fr.count),
                    type: 'line',
                    stack: 'По дням',
                    label: {
                        show: false
                    },
                    color: '#ef5675',
                },
            ]
        }
    }

    const youngRows = data?.ages.flatMap(r => r.rows.filter(r => r.value === Age.YOUNG)) || []
    const preadultRows = data?.ages.flatMap(r => r.rows.filter(r => r.value === Age.PREADULT)) || []
    const adultRows = data?.ages.flatMap(r => r.rows.filter(r => r.value === Age.ADULT)) || []
    const elderRows = data?.ages.flatMap(r => r.rows.filter(r => r.value === Age.ELDER)) || []
    const unknownRows = data?.ages.flatMap(r => r.rows.filter(r => r.value === Age.UNKNOWN)) || []

    const ageTypeOption = {
        ...DEFAULT_OPTIONS,
        ...{
            legend: {
                data: [
                    getAgeDisplayName(Age.UNKNOWN),
                    getAgeDisplayName(Age.YOUNG),
                    getAgeDisplayName(Age.PREADULT),
                    getAgeDisplayName(Age.ADULT),
                    getAgeDisplayName(Age.ELDER),
                ]
            },
            tooltip: {
                trigger: 'axis',
                backgroundColor: 'rgba(45, 55, 72, .95)',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: {
                type: 'category',
                data: data?.genders.map(ct => moment(ct.date).format('DD') + '\n' + moment(ct.date).format('MMM')) || [],
            },
            series: [
                {
                    name: getAgeDisplayName(Age.UNKNOWN),
                    data: unknownRows.map(fr => fr.count),
                    type: 'line',
                    stack: 'По дням',
                    label: {
                        show: false
                    },
                    color: '#240d32',
                },
                {
                    name: getAgeDisplayName(Age.YOUNG),
                    data: youngRows.map(fr => fr.count),
                    type: 'line',
                    stack: 'По дням',
                    label: {
                        show: false
                    },
                    color: '#7a5195',
                },
                {
                    name: getAgeDisplayName(Age.PREADULT),
                    data: preadultRows.map(fr => fr.count),
                    type: 'line',
                    stack: 'По дням',
                    label: {
                        show: false
                    },
                    color: '#5d98bf',
                },
                {
                    name: getAgeDisplayName(Age.ADULT),
                    data: adultRows.map(fr => fr.count),
                    type: 'line',
                    stack: 'По дням',
                    label: {
                        show: false
                    },
                    color: '#ef5675',
                },
                {
                    name: getAgeDisplayName(Age.ELDER),
                    data: elderRows.map(fr => fr.count),
                    type: 'line',
                    stack: 'По дням',
                    label: {
                        show: false
                    },
                    color: '#3a578a',
                },
            ]
        }
    }

    const renderOption = (opt: any) => <ReactEcharts
        className='widget-chart bg-white rounded shadow-sm pb-3'
        style={{height: '500px'}}
        option={opt}
        notMerge={true}
        lazyUpdate={true}
    />

    // @ts-ignore
    return (
        <ContentScreen>
            <div className='row gx-4 w-100'>
                <div className='col-12 col-md-6 mt-2'>
                    <h4 className="fw-normal mb-4">
                        <svg className="icon icon-24 text-primary align-text-bottom me-2">
                            <use href="#graph-24"/>
                        </svg>
                        Контакты <span className="text-primary">по возрасту</span>
                    </h4>
                    {renderOption(ageTypeOption)}
                </div>
                <div className='col-12 col-md-6 mt-2'>
                    <h4 className="fw-normal mb-4">
                        <svg className="icon icon-24 text-primary align-text-bottom me-2">
                            <use href="#graph-24"/>
                        </svg>
                        Контакты <span className="text-primary">по полу</span>
                    </h4>
                    {renderOption(genderTypeOption)}
                </div>
                <div className='col-12 mt-2'>
                    <h4 className="fw-normal mb-4">
                        <svg className="icon icon-24 text-primary align-text-bottom me-2">
                            <use href="#graph-24"/>
                        </svg>
                        Контакты <span className="text-primary">за всё время</span>
                    </h4>
                    {renderOption(contactTypeAlltimeOption)}
                </div>
                <div className='col-12 mt-2'>
                    <h4 className="fw-normal mb-4">
                        <svg className="icon icon-24 text-primary align-text-bottom me-2">
                            <use href="#insights-24"/>
                        </svg>
                        Контакты <span className="text-primary">по дням</span>
                    </h4>
                    {renderOption(contactTypeByDaysOption)}
                </div>
                <div className="h4 fw-normal my-4">
                    <svg className="icon icon-24 text-yellow me-2 align-text-bottom">
                        <use href="#zap-24"/>
                    </svg>
                    Лучшие <span className="text-yellow">сборщики</span>
                </div>
                <div className="mb-5 shadow-sm">
                    <ReactTabulator
                        layout='fitColumns'
                        options={{
/*                            pagination: 'local',
                            paginationSize: 10,*/
                            initialSort: [{column: "count", dir: "desc"}],
                        }}
                        initialSort={true}
                        columns={[
                            {
                                title: '#',
                                field: 'scoutIdAndName',
                                formatter: (cell: any) => cell.getValue()[0],
                                sorter: (a: any, b: any) => a > b
                            },
                            {
                                title: 'ФИО',
                                field: 'scoutIdAndName',
                                formatter: (cell: any) => cell.getValue()[1],
                                sorter: (a: any, b: any) => a > b
                            },
                            {title: 'Сумма', field: 'count', formatter: 'number'}
                        ]}
                        data={data?.scouts || []}/>
                </div>

            </div>

        </ContentScreen>
    )
}

export default withRouter(HomeScreen)
