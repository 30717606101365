import {globalStore} from "../../../utils/redux/ReduxUtils";
import * as React from 'react'
import {PropsWithChildren, useRef, useState} from 'react'
import {UserRole} from "../../../models/enums";
import {UserModel} from "../../../models/UserModel";
import {commonDateTimeFormat} from "../../../utils/Formatters";
import {useHistory} from "react-router-dom";
import * as TypedUtils from "../../../utils/TypedUtils";
import moment from "moment";
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import generator from 'generate-password'
import AddPaymentModal from "../../components/AddPaymentModal";
import PhoneInput from 'react-phone-input-2'
import {isValidPhoneNumber} from "libphonenumber-js";
import * as RoleUtils from '../../../utils/RoleUtils'
import Icon from "@iconify/react";
import archive24 from "@iconify/icons-octicon/archive-24";

const translitConverter = new CyrillicToTranslit();
const PASSWORD_MIN_LENGTH = 16

interface Props {
    onSaveUser: () => Promise<boolean>,
    reloadUser: () => any,
    onCancelPressed: () => any,
    onUserDelete: () => any,
    onUserEdited: (contact: UserModel) => any,
    user: UserModel,
}

const UserInput = (props: PropsWithChildren<Props>) => {
    const history = useHistory();
    const pageTopRef = useRef(null)
    const isNewUser = props.user.id === 0

    function changeUserProp<T>(propName: string, value: T) {
        console.log('changeuserProp', propName, value)
        const userCopy = {...props.user}
        // @ts-ignore
        userCopy[propName] = value
        props.onUserEdited(userCopy)
    }

    const setPassword = (n: string) => {
        if (n) {
            changeUserProp('password', n)
        } else changeUserProp('password', null)
    }
    const setLogin = (n: string) => changeUserProp('login', n)
    const changeNameAndAuthData = (newName: string, newSurname: string) => {
        const userCopy = {...props.user}
        userCopy.name = newName
        userCopy.surname = newSurname
        if (isNewUser) {
            userCopy.login = newSurname ?
                translitConverter.transform(`${newSurname}${newName.slice(0, 1)}`)?.toLowerCase() :
                translitConverter.transform(`${newName}`)?.toLowerCase()
        }
        props.onUserEdited(userCopy)
    }
    const setName = (n: string) => {
        changeNameAndAuthData(n, props.user.surname)
    }
    const setSurname = (n: string) => {
        changeNameAndAuthData(props.user.name, n)
    }
    const setRole = (n: UserRole) => changeUserProp('role', n)
    const setTelegramNickname = (n: string) => changeUserProp('telegramNickname', n)
    const setBirthday = (n: moment.Moment) => changeUserProp('birthday', n)
    const setActive = (v: boolean) => changeUserProp('isActive', v)

    const setPhoneNumber = (n: string) => changeUserProp('phoneNumber', n)

    const [error, setError] = useState('')
    const [addPaymentModalVisible, setAddPaymentModalVisible] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [isDisabled, setDisabled] = useState(!isNewUser)

    const resetStateToDefault = () => {
        setError('')
        setDisabled(true)
        setPasswordVisible(false)
    }
    const isDeletable = props.user.id && globalStore.getState().externalAdditionalInfo.user.id !== props.user.id
    const onSaveUserPressed = async () => {
        const u = props.user
        if (isNewUser && (u.password?.length || 0) < PASSWORD_MIN_LENGTH) setError(`Пароль должен содержать минимум ${PASSWORD_MIN_LENGTH} символов`)
        else if (!isValidPhoneNumber('+' + u.phoneNumber)) {
            setError('Пожалуйста, укажите правильный телефонный номер')
        } else {
            if (u.name && u.surname && u.login && u.phoneNumber) {
                const hasError = await props.onSaveUser()
                if (!hasError) resetStateToDefault()
            } else setError('Пожалуйста, заполните обязательные поля')
        }
        // @ts-ignore
        pageTopRef?.current?.scrollIntoView(true)
    }
    const generatePasswordAndSet = () => {
        const res = generator.generate({
            length: PASSWORD_MIN_LENGTH,
            strict: true
        });
        setPasswordVisible(true)
        changeUserProp('password', res)

    }
    const hasCreatorOrUpdaterUser = Boolean(props.user.updatedBy) || Boolean(props.user.createdBy)
    let shownRoles: UserRole[] = []
    switch(globalStore.getState().externalAdditionalInfo.user.role) {
        case UserRole.HR:
            shownRoles = [UserRole.SCOUT, UserRole.SIGNATURE_SCOUT, UserRole.CALLS_EMPLOYEE]
            break;
        case UserRole.CALLS_CHIEF:
            shownRoles = [UserRole.CALLS_EMPLOYEE]
            break;
        case UserRole.FIELD:
            shownRoles = [UserRole.SCOUT, UserRole.SIGNATURE_SCOUT]
            break;
        case UserRole.CHIEF:
            shownRoles = [UserRole.SCOUT, UserRole.SIGNATURE_SCOUT, UserRole.FIELD, UserRole.ANALYST, UserRole.CALLS_EMPLOYEE, UserRole.CALLS_CHIEF, UserRole.HR, UserRole.CHIEF]
            break;
        default:
            break;
    }
    const canEditUser = isNewUser || shownRoles.includes(props.user.role)

    return (
        <main ref={pageTopRef} id="user" className="container-fluid">
            <div className="max-w-md shadow-lg mx-auto mb-4">
                <div className="bg-dark text-white text-center fw-500 py-3">
                    Карточка сотрудника
                    {
                        props.user.id !== 0 && <span className="fw-bold text-red"> № {props.user.id}</span>
                    }
                </div>
                <div>
                    <fieldset id="user-fieldset" disabled={isDisabled}>
                        <div className="bg-white p-4">
                            {
                                error && <div className='mb-2 font-monospace fw-bold text-red text-center'>{error}</div>
                            }
                            {/* -------------------------------------------------- */}
                            {
                                hasCreatorOrUpdaterUser &&
                                <div className="row small mb-2">
                                    {
                                        Boolean(props.user.createdBy) &&
                                        <>
                                            <div className="col-3 fw-500 mb-1">Создано</div>
                                            <div
                                                className="col-auto fw-500 font-monospace">{commonDateTimeFormat(props.user.createdAt)}</div>
                                            <div className="col text-truncate">
                                                {TypedUtils.renderUserLink(props.user.createdBy?.fullName || '', props.user.createdBy?.id || 0)}
                                            </div>
                                            <div className="w-100"/>
                                        </>
                                    }
                                    {
                                        Boolean(props.user.updatedBy) &&
                                        <>
                                            <div className="col-3 fw-500">Изменено</div>
                                            <div
                                                className="col-auto font-monospace">{commonDateTimeFormat(props.user.updatedAt)}</div>
                                            <div className="col text-truncate">
                                                {TypedUtils.renderUserLink(props.user.updatedBy?.fullName || '', props.user.updatedBy?.id || 0)}
                                            </div>
                                        </>
                                    }

                                </div>
                            }

                            {/* -------------------------------------------------- */}
                            <div className="row">
                                {/* -------------------------------------------------- */}
                                <div>
                                    <hr className="mx-n4"/>
                                </div>
                                <label htmlFor="name" className="col-form-label col-3 form-label-required">Имя</label>
                                <div className="col-9 mb-3">
                                    <input
                                        value={props.user.name}
                                        onChange={e => setName(e.target.value)}
                                        id="name" className="form-control" placeholder="-----" type="name"/>
                                </div>
                                <label htmlFor="surname"
                                       className="col-form-label col-3 form-label-required">Фамилия</label>
                                <div className="col-9 mb-3">
                                    <input
                                        value={props.user.surname}
                                        onChange={e => setSurname(e.target.value)}
                                        id="surname" className="form-control" placeholder="-----" type="name"/>
                                </div>
                                <label htmlFor="user-login"
                                       className="col-form-label col-3 form-label-required">Логин</label>
                                <div className="col-9 mb-3">
                                    <input
                                        value={props.user.login}
                                        autoComplete={'off'}
                                        onChange={e => setLogin(e.target.value)}
                                        id="user-login" className="form-control" placeholder="-----" type="text"/>
                                </div>
                                <label htmlFor="user-password" className="col-form-label col-3">
                                    <span className='form-label-required'>Пароль</span>
                                    {!isDisabled &&
                                    <FontAwesomeIcon size='xs' color='black' icon={faSync} className='ms-2'
                                                     onClick={() => generatePasswordAndSet()}
                                                     style={{cursor: 'pointer'}}/>
                                    }
                                </label>
                                <div className="col-9 mb-3 position-relative">
                                    <input
                                        value={props.user.password}
                                        autoComplete={'off'}
                                        onChange={e => setPassword(e.target.value)}
                                        id="user-password"
                                        className="form-control"
                                        placeholder={passwordVisible ? "-------------" : "*************"}
                                        type={passwordVisible ? "text" : "password"}/>
                                    <div className='position-absolute d-flex flex-row align-items-center'
                                         style={{top: 0, right: '1.5em', height: '100%'}}>
                                        <FontAwesomeIcon size='1x' color={passwordVisible ? 'black' : 'grey'}
                                                         style={{cursor: 'pointer'}}
                                                         onClick={() => setPasswordVisible(!passwordVisible)}
                                                         icon={faEye}/>
                                    </div>

                                </div>
                                {/* -------------------------------------------------- */}
                                <label className="col-form-label col-3">Дата рождения</label>
                                <div className="col-9">
                                    <input id="age" className="form-control" style={{letterSpacing: '.05em'}}
                                           onChange={(e) => setBirthday(moment(e.target.value))}
                                           type="date"/>
                                </div>
                                {/* -------------------------------------------------- */}
                                <div>
                                    <hr className="mx-n4"/>
                                </div>
                                <label htmlFor="phone"
                                       className="col-form-label col-3 form-label-required">Телефон</label>
                                <div className="col-9 mb-3">
                                    <PhoneInput
                                        placeholder="—"
                                        value={props.user.phoneNumber || ''}
                                        onChange={setPhoneNumber}/>
                                </div>
                                <label htmlFor="telegramNickname"
                                       className="col-form-label col-3">Telegram</label>
                                <div className="col-9 mb-3">
                                    <input id="telegramNickname" className="form-control font-monospace--"
                                           value={props.user.telegramNickname || ''}
                                           onChange={e => setTelegramNickname(e.target.value)}
                                           style={{letterSpacing: '.05em'}}
                                           placeholder="@username" type="text"/>
                                </div>
                                {/* -------------------------------------------------- */}
                                <div>
                                    <hr className="mx-n4"/>
                                </div>
                                {/* -------------------------------------------------- */}
                                <label htmlFor="position" className="col-form-label col-3">Должность</label>
                                <div className="col-9 mb-3">
                                    <select id="position" className="form-select"
                                            onChange={e => setRole(e.target.value as UserRole)}>
                                        {
                                            [UserRole.SCOUT, UserRole.SIGNATURE_SCOUT, UserRole.FIELD, UserRole.ANALYST, UserRole.CALLS_EMPLOYEE, UserRole.CALLS_CHIEF, UserRole.HR, UserRole.CHIEF].map(
                                                (r, idx) =>
                                                    <option key={idx} selected={props.user.role === r}
                                                            onClick={() => setRole(r)}
                                                            disabled={!shownRoles.includes(r)}
                                                            value={r}>{TypedUtils.getUserRoleDisplayName(r)}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                {/* -------------------------------------------------- */}
                                {
                                    !isNewUser &&
                                    <>
                                        <label className="col-form-label col-3">Статус</label>
                                        <div className="col-9">
                                            <div className="btn-group w-100">
                                                <input id="active" className="btn-check" type="radio" name="status"
                                                       checked={props.user.isActive}
                                                       onClick={() => setActive(true)}/>
                                                <label htmlFor="active"
                                                       className="btn btn-outline-green">Активен</label>
                                                <input id="inactive" className="btn-check" type="radio" name="status"
                                                       checked={!props.user.isActive}
                                                       onClick={() => setActive(false)}/>
                                                <label htmlFor="inactive"
                                                       className="btn btn-outline-secondary">
                                                    <Icon icon={archive24}
                                                          color={props.user.isActive ? 'grey' : 'white'}
                                                          className='me-2'/>
                                                    Архив
                                                </label>
                                            </div>
                                        </div>
                                    </>
                                }
                                {/* -------------------------------------------------- */}
                                <div>
                                    <hr className="mx-n4"/>
                                </div>
                                {/* -------------------------------------------------- */}
                                {
                                    !isNewUser && <>
                                        <label htmlFor="payments" className="col-form-label col-3">Выплачено</label>
                                        <div className="col-9 mb-2">
                                            <div className="input-group">
                                                <input id="payments" className="form-control pe-0"
                                                       style={{letterSpacing: '.05em'}}
                                                       placeholder={`${props.user.totalPaid}`}
                                                       type="num"
                                                       readOnly/>
                                                {
                                                    RoleUtils.hasPaymentsEditPermission(globalStore.getState().externalAdditionalInfo.user.role) &&
                                                    <button onClick={() => setAddPaymentModalVisible(true)}
                                                            className="btn btn-outline-primary px-3"
                                                            type="button" id="addpay" style={{cursor: 'pointer'}}>+
                                                    </button>
                                                }

                                            </div>
                                        </div>
                                        {
                                            props.user.latestPayments.length > 0 &&
                                            <>
                                                <div className="mb-2 mt-1">Последние выплаты сотруднику:</div>
                                                {/* -------------------------------------------------- */}
                                                <div className="row small">
                                                    {
                                                        props.user.latestPayments.map((p, idx) =>
                                                            <div key={idx} className={'col-12 d-flex flex-row'}>
                                                                <div className="col-5 font-monospace">
                                                                    {commonDateTimeFormat(moment(p.createdAt))}
                                                                </div>
                                                                <div className="col-7">+{p.value}</div>
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                            </>
                                        }

                                        {/*/!* -------------------------------------------------- *!/*/}
                                        {/*<div>*/}
                                        {/*    <hr className="mx-n4"/>*/}
                                        {/*</div>*/}
                                        {/*/!* -------------------------------------------------- *!/*/}
                                        {/*<div className="mb-2 mt-n1">Последние действия сотрудника</div>*/}
                                        {/*<div className="row small">*/}
                                        {/*    <div className="col-5 font-monospace">12 янв. 2021г. 12:34:56</div>*/}
                                        {/*    <div className="col-7">Lorem ipsum dolor sit amet</div>*/}
                                        {/*    <div className="col-5 font-monospace">12 янв. 2021г. 12:34:56</div>*/}
                                        {/*    <div className="col-7">Lorem ipsum dolor sit amet</div>*/}
                                        {/*    <div className="col-5 font-monospace">12 янв. 2021г. 12:34:56</div>*/}
                                        {/*    <div className="col-7">Lorem ipsum dolor sit amet</div>*/}
                                        {/*    <div className="col-5 font-monospace">12 янв. 2021г. 12:34:56</div>*/}
                                        {/*    <div className="col-7">Lorem ipsum dolor sit amet</div>*/}
                                        {/*    <div className="col-5 font-monospace">12 янв. 2021г. 12:34:56</div>*/}
                                        {/*    <div className="col-7 mb-2">Lorem ipsum dolor sit amet</div>*/}
                                        {/*    <div className="col-12 mb-n2"><a href="#">Посмотреть все действия...</a>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/* -------------------------------------------------- */}
                                    </>
                                }
                            </div>
                        </div>
                    </fieldset>
                    {/* -------------------------------------------------- */}
                    <div className="bg-light text-center sticky-bottom border-top p-4">
                        <div className="row gx-4">
                            <div className="col-6">
                                <button
                                    onClick={props.onCancelPressed}
                                    className="btn btn-danger w-100 fw-500" type="reset">Отмена
                                </button>
                            </div>

                            {
                                canEditUser && (isDisabled
                                    ? <div className="col-6">
                                        <button onClick={() => setDisabled(false)}
                                                className="btn btn-primary w-100 fw-500" type="button">Редактировать
                                        </button>
                                    </div>
                                    : <div className="col-6">
                                        <button
                                            onClick={() => onSaveUserPressed()}
                                            className="btn btn-success w-100 fw-500" type="submit">Сохранить
                                        </button>
                                    </div>)
                            }


                        </div>
                    </div>
                </div>
            </div>
            {/*{*/}
            {/*    isDeletable &&*/}
            {/*    <div className="text-center mb-4">*/}
            {/*        <a href="#">Удалить сотрудника</a>*/}
            {/*    </div>*/}
            {/*}*/}
            {
                !isNewUser && RoleUtils.hasPaymentsEditPermission(globalStore.getState().externalAdditionalInfo.user.role) &&
                <AddPaymentModal userId={props.user.id}
                                 toggleVisibility={() => setAddPaymentModalVisible(!addPaymentModalVisible)}
                                 onPaymentCreated={props.reloadUser}
                                 visible={addPaymentModalVisible}/>
            }


        </main>
    )
}

export default UserInput
