import React from "react";
import {Modal} from "react-bootstrap-v5";
import {ToastConsumerContext, withToastManager} from "react-toast-notifications";
import UserSearchTable from "../users/UserSearchTable";
import AgitationMaterialsSearchTable from "./AgitationMaterialsSearchTable";
import NumericInput from "react-numeric-input";
import {ApiAgitationMaterialsResponseRow} from "../../../api/ResponseModels";
import {CancellableButton} from "../../components/CancellableButton";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {ApiUser} from "../../../api/Models";

interface State {
    givenCount: number
    error: string
    selectedMaterial?: SelectedMaterialInfo
    selectedUsers: ApiUser[]
}

interface Props {
    onCancel: () => void
    onSaved: () => void
    toastManager: ToastConsumerContext
}

interface SelectedMaterialInfo {
    title: string
    id: number
}

class AgitationDistributionEditModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            givenCount: 0,
            error: '',
            selectedMaterial: undefined,
            selectedUsers: []
        }
    }

    onMaterialSelected = (row: ApiAgitationMaterialsResponseRow) => {
        this.setState({selectedMaterial: row})
    }
    onSavePressed = () => {
        const saveDistributionF = async () => {
            await API.saveAgitationDistribution({
                materialId: this.state.selectedMaterial?.id || 0, // TODO rethink
                userIds: this.state.selectedUsers.map(u => u.id),
                givenCount: this.state.givenCount
            }, globalStore.getState().authToken)

            this.props.toastManager.add('Разноска успешно сохранена', {
                appearance: 'success',
            });
            this.props.onSaved()
        }

        if (!this.state.selectedMaterial) {
            this.setState({error: 'Необходимо выбрать материал!'})
        } else if (this.state.givenCount <= 0) {
            this.setState({error: 'Необходимо указать количество материала!'})
        } else if (this.state.selectedUsers.length < 1) {
            this.setState({error: 'Необходимо выбрать хотя бы одного сотрудника!'})
        } else {
            saveDistributionF().catch(err => {
                console.log(err)
                this.props.toastManager.add('Не удалось сохранить разноску!', {
                    appearance: 'error',
                });
            })
        }
    }


    render() {
        const hasSelectedMaterial = Boolean(this.state.selectedMaterial)
        return (
            <Modal show={true} onHide={this.props.onCancel} centered className={'modal-desktop-wide'}>
                <Modal.Body>
                    <main id="call-script-editor" className="container-fluid px-0">
                        <div className="col-12 shadow-lg mb-4">
                            <div
                                className="bg-dark text-white text-center fw-500 py-3 d-flex flex-row justify-content-between align-items-center">
                                <span className='ms-5'>Разноска материала</span>
                            </div>
                            <div className="bg-white pt-2 pb-4 px-4">
                                <div className='row mb-3 app-spinner-inner'>
                                    <label className="col-form-label form-label-required">{
                                        hasSelectedMaterial ? '1. Материал' : '1. Выберите материал'
                                    }</label>
                                    {
                                        !hasSelectedMaterial &&
                                        <>
                                            <AgitationMaterialsSearchTable
                                                filterRows={(row: ApiAgitationMaterialsResponseRow) => !row.isArchived}
                                                onRowClicked={this.onMaterialSelected}
                                            />
                                        </>
                                    }
                                    {
                                        this.state.selectedMaterial &&
                                        <div className='col-4'>
                                            <CancellableButton
                                                row={this.state.selectedMaterial}
                                                className='scenario-edit__selected_user'
                                                onCancelClicked={() => this.setState({selectedMaterial: undefined})}
                                                title={this.state.selectedMaterial.title}/>
                                        </div>
                                    }
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-form-label col-12 col-md-8 form-label-required">2. Укажите
                                        количество материала на человека</label>
                                    <div className='col-5'>
                                        <NumericInput
                                            className="form-control"
                                            min={0}
                                            placeholder="штук"
                                            onChange={n => this.setState({givenCount: n || 0})}
                                            value={this.state.givenCount}/>
                                    </div>

                                </div>

                                <div className='row mb-2 app-spinner-inner'>
                                    <label className="col-form-label form-label-required">3. Выберите
                                        сотрудников</label>
                                    {
                                        this.state.selectedMaterial && !(this.state.selectedUsers.length > 0) &&
                                        <UserSearchTable
                                            size={'small'}
                                            filterRows={(row: ApiUser) => row.isActive}
                                            onMultipleRowsSelected={(rows: ApiUser[]) => this.setState({selectedUsers: rows})}
                                        />
                                    }
                                    {
                                        this.state.selectedMaterial && this.state.selectedUsers.length > 0 &&
                                        <div className='col-4'>
                                            <CancellableButton
                                                row={''}
                                                className='scenario-edit__selected_user'
                                                onCancelClicked={() => this.setState({selectedUsers: []})}
                                                title={`Всего: ${this.state.selectedUsers.length}`}/>
                                        </div>
                                    }
                                </div>
                                <div className="bg-white text-center sticky-bottom border-top p-4">
                                    {
                                        this.state.error && <div
                                            className='col-12 border-top font-monospace my-1 fw-bold text-red text-center'>{this.state.error}</div>
                                    }
                                    <div className="row gx-4">
                                        <div className="col-6">
                                            <button id="cancel"
                                                    onClick={this.props.onCancel}
                                                    className="btn btn-danger w-100 fw-500">Отмена
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button id="save" onClick={this.onSavePressed}
                                                    className="btn btn-success w-100 fw-500">Сохранить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Modal.Body>
            </Modal>
        )
    }

}

export default withToastManager(AgitationDistributionEditModal)
