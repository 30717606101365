import {globalStore} from "../../utils/redux/ReduxUtils";
import * as React from 'react'
import {PropsWithChildren, useState} from 'react'
import './ContactInput.scss'
import {Age, Gender, Reaction} from "../../models/enums";
import {Button} from "react-bootstrap-v5";
import {useToasts} from "react-toast-notifications";
import * as TypedUtils from '../../utils/TypedUtils'
import {ContactGeo, ContactModel} from "../../models/ContactModel";
import PhoneInput from "react-phone-input-2";
import {isValidPhoneNumber} from "libphonenumber-js";
import Icon from "@iconify/react";
import classnames from "classnames";
import archive24 from "@iconify/icons-octicon/archive-24";
import check16 from "@iconify/icons-octicon/check-16";
import pencil24 from "@iconify/icons-octicon/pencil-24";
import {commonDateTimeFormat} from "../../utils/Formatters";
import {ApiContactInfoResponseRow, UserSummaryInfoResponse} from "../../api/ResponseModels";
import moment from "moment";
import * as RoleUtils from "../../utils/RoleUtils";
import {COLORS} from "../../utils/Consts";
import * as TableFiltersDefaultRenderer from '../components/search/filters/TableFiltersDefaultRenderer'
import {SearchTableFilterType} from "../../models/ApiFilterEnums";
import {
    RemoteSearchTableCreatedByFilterModel,
    RemoteSearchTableFilterModel
} from "../../models/RemoteSearchTableFilterModel";
import {FilterOption} from "../components/search/filters/TableSearchFilterView";
import AddressEditModal from "./contacts/AddressEditModal";
import {ContactGeoType} from "../../api/Models";

interface Props {
    onSaveContact?: () => any,
    onCancelPressed?: () => any,
    onContactDelete?: () => any,
    onContactEdited?: (contact: ContactModel) => any,
    contact: ContactModel,
    rawApiContact?: ApiContactInfoResponseRow,
    contactsCount: number,
    contactNo: number
}

type CurrentlyShownSelect = 'createdBy' | 'address' | undefined
const ContactInput = (props: PropsWithChildren<Props>) => {
    const {addToast} = useToasts()

    const THEMATICS = globalStore.getState().externalAdditionalInfo.thematics
    const globalCommonSettings = globalStore.getState().externalAdditionalInfo.commonSettings

    function changeContactProp<T>(propName: string, value: T) {
        // @ts-ignore
        const contactCopy = {...props.contact}
        // @ts-ignore
        contactCopy[propName] = value
        props.onContactEdited && props.onContactEdited(contactCopy)
    }

    const ALL_THEMATICS = Array.from(new Set(THEMATICS.concat(props.contact.thematics)))

    const setName = (n: string) => changeContactProp('name', n)
    const setSurname = (n: string) => changeContactProp('surname', n)
    const setPatronymic = (n: string) => changeContactProp('patronymic', n)
    const setGender = (n: Gender) => changeContactProp('gender', n)
    const setReaction = (n: Reaction) => changeContactProp('reaction', n)
    const toggleThematics = (n: string) => {

        if (globalCommonSettings.allowMultiThematicsSelect) {
            const newThematics = props.contact.thematics.includes(n)
                ? props.contact.thematics.filter(t => t != n)
                : ALL_THEMATICS.filter(t => props.contact.thematics.concat([n]).includes(t)) // preserve order
            if (newThematics.length > 0) changeContactProp('thematics', newThematics)
        } else changeContactProp('thematics', [n])
        // changeContactProp('thematics', n)
    }
    const setEmail = (n: string) => changeContactProp('email', n)
    const setPhoneNumber = (n: string) => changeContactProp('phoneNumber', n)
    const setComment = (n: string) => changeContactProp('comment', n)
    const setAge = (n: Age) => changeContactProp('age', n)
    const setArchived = (n: boolean) => changeContactProp('isArchived', n)

    const [error, setError] = useState('')

    const onSaveContactsPressed = () => {
        const trimmedPhoneNumber = props.contact.phoneNumber?.trim()
        if ((trimmedPhoneNumber && !isValidPhoneNumber('+' + trimmedPhoneNumber)) || (!trimmedPhoneNumber && !globalCommonSettings.allowContactsCreationWithoutPhoneNumber)) {
            setError('Пожалуйста, укажите правильный телефонный номер')
        } else if (props.contact.name && props.onSaveContact) props.onSaveContact()
        else setError('Пожалуйста, заполните обязательные поля')
    }
    const isReadOnly = !Boolean(props.onContactEdited)
    // to handle old thematics which were removed from campaign
    const hasAnalyticsPermission = RoleUtils.hasAnalyticsPermission(globalStore.getState().externalAdditionalInfo.user.role)

    const callsForThisContact = props.rawApiContact ? props.rawApiContact.allCalls.filter(c => c.contactId === props.rawApiContact?.id) : []
    const otherCallsForFlat = props.rawApiContact ? props.rawApiContact.allCalls.filter(c => c.contactId !== props.rawApiContact?.id) : []

    const [currentlyShownSelect, setCurrentlyShownSelect] = useState(undefined as CurrentlyShownSelect)
    const userSearchFilterModel: RemoteSearchTableCreatedByFilterModel | undefined = props.contact?.createdBy ? {
        type: SearchTableFilterType.CREATED_BY,
        value: [{
            key: props.contact?.createdBy.id,
            value: props.contact?.createdBy,
            label: props.contact?.createdBy?.fullName
        }],
    } : undefined;
    const onContactCreatedByFilterChange = (v: FilterOption<number, UserSummaryInfoResponse>[] | undefined) => {
        if (v) changeContactProp('createdBy', v[0].value)
    }
    return (
        <main id="contact" className="container-fluid">
            <div className="max-w-md shadow-lg mx-auto mb-4">
                <div
                    className="bg-dark text-white text-center fw-500 py-3 d-flex flex-row justify-content-between align-items-center">
                            <span className='ms-5'>
                                Карточка контакта
                                {
                                    props.contact.id &&
                                    <span className="font-monospace fw-bold text-red ms-1">{props.contact.id}</span>
                                }
                            </span>
                </div>
                <div id="contact-form">
                    <fieldset id="contact-fieldset" disabled={isReadOnly}>
                        <div className="bg-white p-4 pt-2">
                            {
                                error && <div className='mb-2 font-monospace fw-bold text-red text-center'>{error}</div>
                            }
                            {
                                props.contact.createdBy &&
                                <div className="row small">
                                    <div className="col-2 fw-500 mb-1">Создан</div>
                                    <div
                                        className={classnames("fw-500 font-monospace", (currentlyShownSelect === 'createdBy') ? "col-4" : "col-9")}>
                                        {commonDateTimeFormat(moment(props.rawApiContact?.createdAt))}
                                        {
                                            !(currentlyShownSelect === 'createdBy') &&
                                            <span className='ms-2'>
                                                {
                                                    TypedUtils.renderUserLink(props.contact.createdBy?.fullName, props.contact.createdBy?.id)
                                                }
                                            </span>
                                        }
                                    </div>
                                    {
                                        userSearchFilterModel && hasAnalyticsPermission && currentlyShownSelect === 'createdBy' &&
                                        <div className="col-6">
                                            {TableFiltersDefaultRenderer.renderFilter(userSearchFilterModel, 1, onContactCreatedByFilterChange, true, {singleOption: true})}
                                        </div>
                                    }
                                    {
                                        !isReadOnly && !(currentlyShownSelect === 'createdBy') &&
                                        <div onClick={() => setCurrentlyShownSelect('createdBy')}
                                             className='col-1 ps-2 d-flex justify-content-end align-items-center'>
                                            <Icon className='cursor-pointer' icon={pencil24} width={14}
                                                  color={COLORS.BLUE} style={{marginTop: -3}}/>
                                        </div>
                                    }
                                </div>
                            }
                            {

                                <div className="row small">
                                    {
                                        props.contact?.fullAddress
                                            ? <>
                                                <div className="col-2 fw-500 mb-1">Адрес</div>
                                                <div
                                                    className="col-9 fw-500 font-monospace">{
                                                    TypedUtils.makeFullAddressWithGeo(props.contact.fullAddress, props.contact.geo)
                                                }</div>
                                            </>
                                            : <div className="col-4 fw-500 mb-1">Нет адреса</div>
                                    }
                                    {
                                        !isReadOnly && !(currentlyShownSelect === 'address') &&
                                        <div onClick={() => setCurrentlyShownSelect('address')}
                                             className='col-1 ps-2 ms-auto d-flex justify-content-end align-items-center'>
                                            <Icon className='cursor-pointer' icon={pencil24} width={14}
                                                  color={COLORS.BLUE} style={{marginTop: -3}}/>
                                        </div>
                                    }
                                    <AddressEditModal visible={currentlyShownSelect === 'address'}
                                                      onSave={s => {
                                                          const nextGeo: ContactGeo | undefined = s ? {
                                                              addressId: s.addressId,
                                                              flatNo: s.flatNo,
                                                              entranceNo: s.entranceNo,
                                                              type: ContactGeoType.HOUSE_REQUEST_GEO
                                                          } : undefined
                                                          const contactCopy: ContactModel = {...props.contact}
                                                          contactCopy.geo = nextGeo
                                                          contactCopy.fullAddress = s?.fullAddress
                                                          props.onContactEdited && props.onContactEdited(contactCopy)
                                                          setCurrentlyShownSelect(undefined)
                                                      }}
                                                      initialValue={
                                                          (props.contact.geo && props.contact?.fullAddress) ?
                                                              {
                                                                  geo: props.contact.geo,
                                                                  fullAddress: props.contact?.fullAddress
                                                              } : undefined
                                                      }
                                                      toggleVisibility={() => setCurrentlyShownSelect(undefined)}/>


                                </div>
                            }
                            <div className="row border-top pt-2 mt-2">
                                <label htmlFor="name" className="col-form-label col-3 form-label-required">Имя</label>
                                <div className="col-9 mb-3">
                                    <input id="name" className="form-control"
                                           value={props.contact.name}
                                           placeholder="Мария"
                                           type="name" onChange={e => setName(e.target.value)}/>
                                </div>
                                {/* -------------------------------------------------- */}
                                <label htmlFor="fathername" className="col-form-label col-3">Отчество</label>
                                <div className="col-9 mb-3">
                                    <input id="fathername" className="form-control"
                                           value={props.contact.patronymic || ''}
                                           placeholder="—" type="name"
                                           onChange={e => setPatronymic(e.target.value)}/>
                                </div>
                                {/* -------------------------------------------------- */}
                                <label htmlFor="surname" className="col-form-label col-3">Фамилия</label>
                                <div className="col-9 mb-3">
                                    <input id="surname" className="form-control pr-0"
                                           value={props.contact.surname || ''}
                                           placeholder="—" type="name"
                                           onChange={e => setSurname(e.target.value)}/>
                                </div>
                                {/* -------------------------------------------------- */}
                                <label className="col-form-label col-3">Пол</label>
                                <div className="col-9 mb-3">
                                    <div className="btn-group w-100">
                                        <input id="male" className="btn-check" type="radio" name="gender"
                                               checked={props.contact.gender === Gender.MALE}
                                               onClick={() => setGender(Gender.MALE)}/>
                                        <label htmlFor="male" className="btn btn-outline-azure">Мужчина</label>
                                        <input id="female" className="btn-check" type="radio" name="gender"
                                               checked={props.contact.gender === Gender.FEMALE}
                                               onClick={() => setGender(Gender.FEMALE)}/>
                                        <label htmlFor="female" className="btn btn-outline-pink">Женщина</label>
                                    </div>
                                </div>
                                {/* -------------------------------------------------- */}
                                <label className="col-form-label col-3">Возраст</label>
                                <div className="col-9 mt-1">
                                    <div className="btn-group w-100">
                                        <input className="btn-check" type="radio" name="age" id="young"
                                               checked={props.contact.age === Age.YOUNG}
                                               onClick={() => setAge(Age.YOUNG)}/>
                                        <label htmlFor="young"
                                               className="col-4 btn btn-outline-purple btn-sm">{TypedUtils.getAgeDisplayName(Age.YOUNG)}</label>
                                        <input className="btn-check" type="radio" name="age" id="preadult"
                                               checked={props.contact.age === Age.PREADULT}
                                               onClick={() => setAge(Age.PREADULT)}/>
                                        <label htmlFor="preadult"
                                               className="col-4 btn btn-outline-purple btn-sm">{TypedUtils.getAgeDisplayName(Age.PREADULT)}</label>
                                        <input className="btn-check" type="radio" name="age" id="adult"
                                               checked={props.contact.age === Age.ADULT}
                                               onClick={() => setAge(Age.ADULT)}/>
                                        <label htmlFor="adult"
                                               className="col-4 btn btn-outline-purple btn-sm">{TypedUtils.getAgeDisplayName(Age.ADULT)}</label>
                                        <input className="btn-check" type="radio" name="age" id="elder"
                                               checked={props.contact.age === Age.ELDER}
                                               onClick={() => setAge(Age.ELDER)}/>
                                        <label htmlFor="elder"
                                               className="col-4 btn btn-outline-purple btn-sm">{TypedUtils.getAgeDisplayName(Age.ELDER)}</label>
                                        <input className="btn-check" type="radio" name="age" id='unknown'
                                               checked={props.contact.age === Age.UNKNOWN}
                                               onClick={() => setAge(Age.UNKNOWN)}/>
                                        <label htmlFor="unknown"
                                               className="col-4 btn btn-outline-purple btn-sm">{TypedUtils.getAgeDisplayName(Age.UNKNOWN, false)}</label>
                                    </div>
                                </div>
                                {/* -------------------------------------------------- */}
                                {
                                    props.contact.id && !isReadOnly &&
                                    <div className="col-12 mt-3">
                                        <div className="btn-group w-100">
                                            <input id="active" className="btn-check" type="radio" name="status"
                                                   checked={!props.contact.isArchived}
                                                   onClick={() => setArchived(false)}/>
                                            <label htmlFor="active"
                                                   className="btn btn-outline-green">Активен</label>
                                            <input id="inactive" className="btn-check" type="radio" name="status"
                                                   checked={props.contact.isArchived}
                                                   onClick={() => setArchived(true)}/>
                                            <label htmlFor="inactive"
                                                   className="btn btn-outline-secondary">
                                                <Icon icon={archive24}
                                                      color={!props.contact.isArchived ? 'grey' : 'white'}
                                                      className='me-2'/>
                                                Архив
                                            </label>
                                        </div>
                                    </div>
                                }

                                <div>
                                    <hr className="mx-n4"/>
                                </div>
                                <label htmlFor="phone"
                                       className={classnames("col-form-label col-3", globalCommonSettings.allowContactsCreationWithoutPhoneNumber ? undefined : 'form-label-required')}>Телефон</label>
                                <div className="col-9 mb-3">
                                    <PhoneInput
                                        placeholder="—"
                                        value={props.contact.phoneNumber || ''}
                                        onChange={setPhoneNumber}/>
                                </div>
                                {/* -------------------------------------------------- */}
                                <label htmlFor="email" className="col-form-label col-3">Почта</label>
                                <div className="col-9 mb-3">
                                    <input id="email" className="form-control font-monospace pr-0"
                                           placeholder="—"
                                           type="email"
                                           value={props.contact.email || ''}
                                           onChange={e => setEmail(e.target.value)}/>
                                </div>

                                <div>
                                    <hr className="mx-n4"/>
                                </div>
                                {/* -------------------------------------------------- */}
                                <label className="form-label col-12 mb-3">Реакция и проблематика</label>
                                <div className="col-6 mb-3">
                                    <div className="btn-group-vertical w-100">
                                        <input id="volunteer" className="btn-check" type="radio" name="category"
                                               checked={props.contact.reaction === Reaction.VOLUNTEER}
                                               onClick={() => setReaction(Reaction.VOLUNTEER)}/>
                                        <label htmlFor="volunteer" className="btn btn-outline-green rounded-top">
                                            {TypedUtils.getReactionDisplayName(Reaction.VOLUNTEER)}
                                        </label>
                                        <input id="best" className="btn-check" type="radio" name="category"
                                               checked={props.contact.reaction === Reaction.BEST}
                                               onClick={() => setReaction(Reaction.BEST)}/>
                                        <label htmlFor="best" className="btn btn-outline-azure rounded-top">
                                            {TypedUtils.getReactionDisplayName(Reaction.BEST)}
                                        </label>
                                        <input id="good" className="btn-check" type="radio" name="category"
                                               checked={props.contact.reaction === Reaction.GOOD}
                                               onClick={() => setReaction(Reaction.GOOD)}/>
                                        <label htmlFor="good" className="btn btn-outline-teal">
                                            {TypedUtils.getReactionDisplayName(Reaction.GOOD)}
                                        </label>
                                        <input id="bad" className="btn-check" type="radio" name="category"
                                               checked={props.contact.reaction === Reaction.BAD}
                                               onClick={() => setReaction(Reaction.BAD)}/>
                                        <label htmlFor="bad" className="btn btn-outline-orange">
                                            {TypedUtils.getReactionDisplayName(Reaction.BAD)}
                                        </label>
                                        <input id="worst" className="btn-check" type="radio" name="category"
                                               checked={props.contact.reaction === Reaction.WORST}
                                               onClick={() => setReaction(Reaction.WORST)}/>
                                        <label htmlFor="worst" className="btn btn-outline-red">
                                            {TypedUtils.getReactionDisplayName(Reaction.WORST)}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="btn-group-vertical w-100">
                                        {
                                            ALL_THEMATICS.map((t: string, idx: number) => {
                                                const isChecked = props.contact.thematics.includes(t)
                                                return (
                                                    <Button key={idx} size={'lg'}
                                                            className='d-flex align-items-center justify-content-center'
                                                            variant={isChecked ? 'indigo' : 'outline-indigo'}
                                                            onClick={() => toggleThematics(t)}>
                                                        {
                                                            isChecked &&
                                                            <Icon className='me-1' icon={check16} width={24}
                                                                  color={'white'}/>
                                                        }
                                                        {t}
                                                    </Button>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                {/* -------------------------------------------------- */}
                                <div>
                                    <hr className="mx-n4 d-none"/>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="сomment" className="form-label mb-3">Комментарий</label>
                                    <label htmlFor="сomment" className="float-end text-muted"><small><span
                                        id="comment-count">{props.contact.comment?.length || 0}</span> /
                                        1000</small></label>
                                    <textarea id="сomment" className="form-control mb-"
                                              style={{minHeight: '10em', maxHeight: '30em'}} placeholder="—"
                                              value={props.contact.comment || ''}
                                              onChange={e => setComment(e.currentTarget.value)}
                                              maxLength={1000}/>
                                </div>
                                {
                                    (props.rawApiContact?.allContacts.length || 0) > 0 &&
                                    <>
                                        <div className="mb-2 mt-1">Другие контакты по квартире:</div>
                                        {/* -------------------------------------------------- */}
                                        <div className="row small">
                                            {
                                                props.rawApiContact?.allContacts.map((otherContact, idx) =>
                                                    <div key={idx} className={'col-12 d-flex flex-row'}>
                                                        <div className="col-4 font-monospace">
                                                            {commonDateTimeFormat(moment(otherContact.createdAt))}
                                                        </div>
                                                        <a
                                                            className={'col-auto'}
                                                            onClick={e => e.stopPropagation()}
                                                            target={'_blank'}
                                                            href={`/contact/${otherContact.id}`}>{otherContact.name + ' ' + (otherContact.surname || '')}</a>
                                                        <div className='col-auto ms-auto'>
                                                            {otherContact.createdBy ? TypedUtils.renderUserLink(otherContact.createdBy.fullName, otherContact.createdBy.id) : ''}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </>
                                }
                                {
                                    callsForThisContact.length > 0 &&
                                    <>
                                        <div className="mb-2 mt-1">Звонки по контакту:</div>
                                        {/* -------------------------------------------------- */}
                                        <div className="row small">
                                            {
                                                callsForThisContact.map((call, idx) =>
                                                    <div key={idx} className={'col-12 d-flex flex-row'}>
                                                        <div className="col-4 font-monospace">
                                                            {commonDateTimeFormat(moment(call.createdAt))}
                                                        </div>
                                                        <a
                                                            className={'col-auto'}
                                                            onClick={e => e.stopPropagation()}
                                                            target={'_blank'}
                                                            href={`/call/${call.id}`}>{call.result}</a>
                                                        <div className='col-auto ms-auto'>
                                                            {TypedUtils.renderUserLink(call.createdBy.fullName, call.createdBy.id)}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </>
                                }
                                {
                                    otherCallsForFlat.length > 0 &&
                                    <>
                                        <div className="mb-2 mt-1">Другие звонки по квартире:</div>
                                        {/* -------------------------------------------------- */}
                                        <div className="row small">
                                            {
                                                otherCallsForFlat.map((call, idx) =>
                                                    <div key={idx} className={'col-12 d-flex flex-row'}>
                                                        <div className="col-4 font-monospace">
                                                            {commonDateTimeFormat(moment(call.createdAt))}
                                                        </div>
                                                        <a
                                                            className={'col-auto'}
                                                            onClick={e => e.stopPropagation()}
                                                            target={'_blank'}
                                                            href={`/call/${call.id}`}>{call.result}</a>
                                                        <div className='col-auto ms-auto'>
                                                            {TypedUtils.renderUserLink(call.createdBy.fullName, call.createdBy.id)}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </fieldset>

                    <div className="bg-light text-center sticky-bottom border-top p-4">
                        <div className="row gx-4">
                            {
                                !isReadOnly &&
                                <>
                                    <div className="col-6">
                                        <button id="cancel"
                                                onClick={() => props.onCancelPressed && props.onCancelPressed()}
                                                className="btn btn-danger w-100 fw-500">Отмена
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <button onClick={onSaveContactsPressed} id="save"
                                                className="btn btn-success w-100 fw-500">Сохранить
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                isReadOnly && props.onCancelPressed &&
                                <div className="col-12">
                                    <button id="cancel" onClick={() => props.onCancelPressed && props.onCancelPressed()}
                                            className="btn btn-danger w-100 fw-500">Назад
                                    </button>
                                </div>
                            }

                        </div>
                    </div>

                </div>
            </div>
            {
                props.contact.id && props.onContactDelete && !isReadOnly &&
                <div className="text-center mb-4" onClick={props.onContactDelete}>
                    <a href="#">Удалить контакт</a>
                </div>
            }

        </main>
    )
}

export default ContactInput
