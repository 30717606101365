import React from "react";
import {Button, Spinner} from "react-bootstrap-v5";
import {ToastConsumerContext} from "react-toast-notifications";
import {DropzoneArea} from "material-ui-dropzone";
import {globalStore} from "../../utils/redux/ReduxUtils";
import {COLORS} from "../../utils/Consts";
import {ApiHqCsvImportResponse} from "../../api/ResponseModels";

interface State {
    file?: File
    isLoading: boolean
    error: string
}

interface Props<T extends HasError> {
    uploadContent: (content: string) => Promise<T>
    handleResponse: (response: T) => void
    toastManager: ToastConsumerContext
    labelText?: string
    fileEncoding?: string
    fileExtensions?: string[]
    inputHintText: string
}

interface HasError {
    error?: string
}

class FileDragNDropArea<T extends HasError> extends React.Component<Props<T>, State> {
    constructor(props: Props<T>) {
        super(props);
        this.state = {
            isLoading: false,
            error: ''
        }
    }

    onUploadPressed = () => {
        if (!this.state.isLoading) {
            this.setState({isLoading: true, error: ''}, () => {
                const r = new FileReader();
                r.onload = async () => {
                    const fileReadResult = r.result
                    if (fileReadResult && typeof fileReadResult === 'string') {
                        const uploadFileF = async () => {
                            const response = await this.props.uploadContent(
                                fileReadResult
                            )
                            if (response.error) throw new Error(response.error)
                            this.props.handleResponse(response)
                            this.setState({file: undefined})
                        }
                        uploadFileF().catch(err => {
                            console.log(err)
                            this.props.toastManager.add(`Импорт завершён с ошибкой. ${err}`, {
                                appearance: 'error',
                                autoDismiss: false
                            });
                        }).finally(() => this.setState({isLoading: false}))

                    } else this.props.toastManager.add('Не удалось прочитать файл', {
                        appearance: 'error',
                    });
                };
                if (this.state.file) {
                    r.readAsText(this.state.file, this.props.fileEncoding)
                }
            })
        }
    }

    render() {
        return (
            <div className="bg-white pt-2 pb-4 px-4" style={{wordWrap: 'break-word'}}>
                {
                    Boolean(this.state.error) && <span
                        className='col-12 border-top font-monospace mt-1 fw-bold text-danger text-center'>{this.state.error}</span>
                }
                <div className='row'>
                    <label htmlFor="materialTitle"
                           className="col-form-label col-12 form-label-required">{
                        this.props.labelText || 'Выберите файл для импортирования'}
                    </label>
                    <div className="col-12">
                        <DropzoneArea
                            dropzoneText={'Перенесите файл в это окно или нажмите, чтобы выбрать'}
                            filesLimit={1}
                            getFileAddedMessage={fileName => `Файл ${fileName} выбран`}
                            getFileRemovedMessage={fileName => `Файл ${fileName} удалён`}
                            acceptedFiles={this.props.fileExtensions || [".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}
                            onChange={files => {
                                if (files.length > 0) this.setState({file: files[0]})
                                else this.setState({file: undefined})
                            }}
                        />
                    </div>
                    {
                        !this.state.isLoading &&
                        <div className='row mt-1 text-grey'>
                            <div className='col-12' style={{fontSize: 12, color: COLORS.GREY600}}>
                                {this.props.inputHintText}
                            </div>
                        </div>
                    }

                </div>

                <div className='row mt-2'>
                    {
                        Boolean(this.state.file) &&
                        <Button variant='primary' onClick={this.onUploadPressed}>
                            {!this.state.isLoading &&
                            `Загрузить ${this.state.file?.name || ''}`
                            }
                            {
                                this.state.isLoading &&
                                <span>
                                                        <Spinner className='mx-1' size='sm' animation="border"
                                                                 variant='white' role="status"/>
                                    {`Идёт загрузка...`}
                                                    </span>
                            }
                        </Button>
                    }
                    {
                        this.state.isLoading &&
                        <div className='mt-2 text-danger text-center fw-bolder'>
                            Процесс импорта может занять несколько минут, пожалуйста, не закрывайте это
                            окно до завершения.
                        </div>
                    }
                </div>
            </div>
        )
    }

}

export default FileDragNDropArea
