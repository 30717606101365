import React, {useState} from "react";
import classnames from "classnames";
import Icon from "@iconify/react";
import {default as angleDownIcon} from "@iconify/icons-uim/angle-down";
import {TwitterPicker} from "react-color";


interface ColorPickerElementProps {
    className?: string
    currentColor: string
    onColorChanged: (color: string) => void
}

export const ColorPickerElement = (props: ColorPickerElementProps) => {
    const [isPickerOpened, setIsPickerOpened] = useState(false)
    return (
        <>
            <div
                onClick={() => setIsPickerOpened(!isPickerOpened)}
                className={classnames('result-color-picker', props.className)}>
                <div className='result-color-picker__preview'
                     style={{backgroundColor: props.currentColor}}/>
                <Icon icon={angleDownIcon} width={16}/>
                {
                    isPickerOpened &&
                        <div onClick={e => e.stopPropagation()}>
                    <TwitterPicker width='220px'
                                   triangle={'hide'}
                                   onChange={(color, evt) =>
                                   {
                                       console.log('onChange', evt)
                                       props.onColorChanged(color.hex)
                                   }
                                   }
                                   className='twitter-color-picker_opened'/>
                        </div>
                }
            </div>
        </>
    )
}
